var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ColorFunc from 'color';
import styled from 'styled-components';
import { AppIcon } from '@app/components/atm.app-icon';
import { BodySecondary, Ellipsed } from '@atomic/atm.typography';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Hbox } from '@atomic/obj.box';
import { Border, Color, Spacing } from '@atomic/obj.constants';
export var EditButton = function (_a) {
    var values = _a.values, onClick = _a.onClick;
    return (React.createElement(EditButtonStyled, { onClick: onClick },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(AppIcon.Paw, null)),
            React.createElement(Hbox.Separator, null),
            React.createElement(Hbox.Item, null,
                React.createElement(EditBodyStyled, null,
                    React.createElement(Ellipsed, null, values))),
            React.createElement(Hbox.Separator, null),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(AppIcon.Edit, { size: 'medium' })))));
};
var EditButtonStyled = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n  border-width: 0;\n  padding: ", ";\n  text-align: left;\n  cursor: pointer;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n  }\n\n  &:focus-visible {\n    ", "\n  }\n"], ["\n  width: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n  border-width: 0;\n  padding: ", ";\n  text-align: left;\n  cursor: pointer;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n  }\n\n  &:focus-visible {\n    ", "\n  }\n"])), Color.Accessory, Border.Radius, Spacing.Small, ColorFunc(Color.Accessory).darken(0.1).hsl().string(), ColorFunc(Color.Accessory).darken(0.2).hsl().string(), clickableFocusCss);
var EditBodyStyled = styled(BodySecondary)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n"], ["\n  color: ", ";\n  cursor: pointer;\n"])), Color.GrayXDark);
var templateObject_1, templateObject_2;
