var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { CardCss } from '@atomic/atm.card/card.component.style';
import { HeaderHeight, MediaQuery, Spacing } from '@atomic/obj.constants';
export var StickyCard = function (_a) {
    var children = _a.children;
    return (React.createElement(StickyCardStyled, null,
        React.createElement(Wrapper, { doubleGutter: true }, children)));
};
var StickyCardStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: sticky;\n  top: calc(", " + ", ");\n"], ["\n  position: sticky;\n  top: calc(", " + ", ");\n"])), HeaderHeight.Desk, Spacing.Medium);
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", " {\n    ", "\n  }\n"], ["\n  ", " {\n    ", "\n  }\n"])), MediaQuery.md, CardCss);
var templateObject_1, templateObject_2;
