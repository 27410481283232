import { useCallback, useEffect } from 'react';
export var useKeyboardListener = function (eventHandler) {
    var handleKeyPress = useCallback(function (event) {
        Object.entries(eventHandler).forEach(function (_a) {
            var key = _a[0], handler = _a[1];
            if (key.toLowerCase() === event.key.toLowerCase()) {
                handler();
            }
        });
    }, [eventHandler]);
    useEffect(function () {
        document.addEventListener('keydown', handleKeyPress);
        return function () { return document.removeEventListener('keydown', handleKeyPress); };
    }, [handleKeyPress]);
};
