var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color, IconSize, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';
var IMAGE_HEIGHT = 180;
export var ImageCarouselStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: ", "px;\n"], ["\n  position: relative;\n  width: 100%;\n  height: ", "px;\n"])), IMAGE_HEIGHT + 2 * parseInt(Border.WidthLarge));
export var ImageCarouselScrollStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  overflow-y: hidden;\n  overflow-x: auto;\n  position: absolute;\n  top: 0;\n  left: 0;\n  max-width: 100%;\n  gap: ", ";\n  padding: 0 ", ";\n"], ["\n  display: flex;\n  overflow-y: hidden;\n  overflow-x: auto;\n  position: absolute;\n  top: 0;\n  left: 0;\n  max-width: 100%;\n  gap: ", ";\n  padding: 0 ", ";\n"])), Spacing.Medium, Spacing.XLarge);
export var ImageCarouselImageWrapperStyled = styled.div.attrs({ role: 'button' })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  cursor: pointer;\n  border-radius: ", ";\n  border-width: ", ";\n  border-style: solid;\n  border-color: ", ";\n  box-sizing: content-box;\n  height: ", "px;\n"], ["\n  position: relative;\n  cursor: pointer;\n  border-radius: ", ";\n  border-width: ", ";\n  border-style: solid;\n  border-color: ", ";\n  box-sizing: content-box;\n  height: ", "px;\n"])), Border.RadiusLarge, Border.WidthLarge, function (props) { return (props.selected ? Color.Primary : Border.Color); }, IMAGE_HEIGHT);
export var ImageCarouselImageStyled = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: ", "px;\n  border-radius: ", ";\n\n  &:hover {\n    opacity: 0.8;\n  }\n\n  &:active {\n    opacity: 0.6;\n  }\n"], ["\n  height: ", "px;\n  border-radius: ", ";\n\n  &:hover {\n    opacity: 0.8;\n  }\n\n  &:active {\n    opacity: 0.6;\n  }\n"])), IMAGE_HEIGHT, Border.RadiusLarge);
export var SelectedImageIconStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-width: ", ";\n  border-style: solid;\n  border-radius: 50%;\n  overflow: hidden;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-width: ", ";\n  border-style: solid;\n  border-radius: 50%;\n  overflow: hidden;\n"])), IconSize.Large, IconSize.Large, function (props) { return (props.selected ? Color.Primary : Color.White); }, function (props) { return (props.selected ? Color.Primary : Color.GrayLight); }, Border.Width);
export var ImageFloatingButtonStyled = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: none;\n  margin: 0;\n  width: ", ";\n  height: ", ";\n  box-sizing: content-box;\n  padding: ", ";\n  border-radius: 50%;\n  background-color: ", ";\n  cursor: pointer;\n  ", "\n\n  pointer-events: all;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  border: none;\n  margin: 0;\n  width: ", ";\n  height: ", ";\n  box-sizing: content-box;\n  padding: ", ";\n  border-radius: 50%;\n  background-color: ", ";\n  cursor: pointer;\n  ", "\n\n  pointer-events: all;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n  }\n"])), IconSize.Large, IconSize.Large, Spacing.XSmall, function (props) { return (props.hovered ? Color.White : 'transparent'); }, function (props) { return props.hovered && "box-shadow: ".concat(Shadow.Small, ";"); }, Color.GrayXLight, Color.GrayLight);
export var ImageFloatingButtonsWrapperStyled = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  border: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: ", ";\n  z-index: ", ";\n  pointer-events: none;\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  border: none;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: ", ";\n  z-index: ", ";\n  pointer-events: none;\n"])), Spacing.Small, Spacing.Small, Spacing.XSmall, ZIndex.first);
export var ImageCarouselButtonStyled = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  ", "\n  padding: 0 ", ";\n  margin: 0;\n  z-index: ", ";\n  background-color: transparent;\n  width: 15%;\n  height: 100%;\n  border: none;\n  cursor: pointer;\n  background-color: transparent;\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n\n  &:hover {\n    & > * {\n      opacity: 0.8;\n    }\n  }\n\n  &:active {\n    & > * {\n      opacity: 0.6;\n    }\n  }\n\n  background-image: ", ";\n"], ["\n  position: absolute;\n  ", "\n  padding: 0 ", ";\n  margin: 0;\n  z-index: ", ";\n  background-color: transparent;\n  width: 15%;\n  height: 100%;\n  border: none;\n  cursor: pointer;\n  background-color: transparent;\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n\n  &:hover {\n    & > * {\n      opacity: 0.8;\n    }\n  }\n\n  &:active {\n    & > * {\n      opacity: 0.6;\n    }\n  }\n\n  background-image: ", ";\n"])), function (props) { return (props.left ? "left: 0;" : "right: 0;"); }, Spacing.XLarge, ZIndex.second, function (props) { return (props.left ? 'flex-start' : 'flex-end'); }, function (props) {
    return "linear-gradient(".concat(props.left ? 'to left' : 'to right', ", ").concat(Color.GrayLight, "26, ").concat(Color.White, "80, ").concat(Color.White, "CC, ").concat(Color.White, " 60%)");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
