var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Color } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
export var HamburgerButtonStyled = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  display: inline-block;\n  text-transform: none;\n  transition: opacity 0.15s linear, filter 0.15s linear;\n  border: 0;\n  background-color: transparent;\n  margin: 0;\n  padding: 12px;\n\n  &:focus-visible {\n    ", "\n  }\n"], ["\n  ", "\n  display: inline-block;\n  text-transform: none;\n  transition: opacity 0.15s linear, filter 0.15s linear;\n  border: 0;\n  background-color: transparent;\n  margin: 0;\n  padding: 12px;\n\n  &:focus-visible {\n    ", "\n  }\n"])), highlightStyle, clickableFocusCss);
export var HamburgerBoxStyled = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  width: 25px;\n  height: 14px;\n"], ["\n  position: relative;\n  display: inline-block;\n  width: 25px;\n  height: 14px;\n"])));
export var HamburgerInnerStyled = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &,\n  &:before,\n  &:after {\n    display: block;\n    position: absolute;\n    width: 25px;\n    height: 2px;\n    background-color: ", ";\n    border-radius: 4px;\n  }\n\n  top: 50%;\n  margin-top: -1px;\n  transition-delay: ", ";\n  transition-timing-function: ", ";\n  transform: ", ";\n  transition-duration: 0.22s;\n  transition: transform 0.15s ease;\n\n  &:before {\n    content: '';\n    top: ", ";\n    opacity: ", ";\n    transition: ", ";\n  }\n\n  &:after {\n    content: '';\n    bottom: ", ";\n    transition: ", ";\n    transform: ", ";\n  }\n"], ["\n  &,\n  &:before,\n  &:after {\n    display: block;\n    position: absolute;\n    width: 25px;\n    height: 2px;\n    background-color: ", ";\n    border-radius: 4px;\n  }\n\n  top: 50%;\n  margin-top: -1px;\n  transition-delay: ", ";\n  transition-timing-function: ", ";\n  transform: ", ";\n  transition-duration: 0.22s;\n  transition: transform 0.15s ease;\n\n  &:before {\n    content: '';\n    top: ", ";\n    opacity: ", ";\n    transition: ", ";\n  }\n\n  &:after {\n    content: '';\n    bottom: ", ";\n    transition: ", ";\n    transform: ", ";\n  }\n"])), Color.Primary, function (props) { return (props.active ? '0.12s' : '0'); }, function (props) {
    return props.active ? 'cubic-bezier(0.215, 0.61, 0.355, 1)' : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';
}, function (props) { return (props.active ? 'rotate(225deg)' : 'none'); }, function (props) { return (props.active ? '0' : '-6px'); }, function (props) { return (props.active ? '0' : '1'); }, function (props) {
    return props.active
        ? 'top 0.1s ease-out, opacity 0.1s 0.12s ease-out'
        : 'transform .15s ease, top 0.1s 0.25s ease-in, opacity 0.1s ease-in;';
}, function (props) { return (props.active ? '0' : '-6px'); }, function (props) {
    return props.active
        ? 'bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)'
        : 'transform .15s ease, bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)';
}, function (props) { return (props.active ? 'rotate(-90deg)' : 'none'); });
var templateObject_1, templateObject_2, templateObject_3;
