import { trpc } from '@app/core/trpc';
export var useCreateProductList = function (_a) {
    var _b = _a === void 0 ? {} : _a, onSuccess = _b.onSuccess, onError = _b.onError;
    var response = trpc.productList.create.useMutation({ onSuccess: onSuccess, onError: onError });
    return {
        mutate: response.mutate,
        data: response.data,
        isLoading: response.isLoading,
        error: response.error,
    };
};
