import { EventType } from '@app/data/analytics/analytics.model';
import { mapProductSearchItemToAnalytics } from './item.mapper';
export var RemoveFromCartAnalytics = function (params) {
    var removedQuantity = params.previousQuantity - params.newQuantity;
    var product = params.product;
    return {
        type: EventType.EVENT,
        eventName: 'remove_from_cart',
        params: {
            currency: 'BRL',
            value: product.price !== null ? (product.price / 100) * removedQuantity : undefined,
            items: [mapProductSearchItemToAnalytics(product, removedQuantity)],
        },
    };
};
