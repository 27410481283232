import * as React from 'react';
import { createPortal } from 'react-dom';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { ModalHeading } from './modal-heading.component';
import { ModalBoxStyled, ModalOpacityStyled, ModalStyled } from './modal.component.style';
export var Modal = function (props) {
    return createPortal(React.createElement(ModalStyled, { opened: props.opened },
        React.createElement(ModalOpacityStyled, { opened: props.opened, onClick: props.onClose }),
        props.opened && (React.createElement(ModalBoxStyled, { small: props.small },
            React.createElement(ModalHeading, { title: props.title, onClick: props.onClose }),
            React.createElement(Separator, { size: Spacing.Medium }),
            props.children,
            React.createElement(Separator, { size: Spacing.Medium })))), document.getElementById('modal'));
};
