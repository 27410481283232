import { EventType } from '@app/data/analytics/analytics.model';
export var LoginAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'login',
        params: {
            method: params.method,
        },
    };
};
