var _a, _b, _c;
import React from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { NavbarItems } from '@app/model';
import { HomeRoutes } from '@app/modules/home/home.routes';
export var NavbarLinkMapper = (_a = {},
    _a[NavbarItems.Home] = HomeRoutes.Base,
    _a);
export var isExternalLink = function (url) { return url.startsWith('http'); };
var strings = AppStrings.components.navbar.pages;
export var NavbarStringMapper = (_b = {},
    _b[NavbarItems.Home] = strings.home,
    _b);
export var NavbarIconMapper = (_c = {},
    _c[NavbarItems.Home] = React.createElement(AppIcon.Home, null),
    _c);
