export var ProductsListBase = 'listas';
export var ProductsListPageParam = 'listId';
export var ProductsListRoutesPathEnd;
(function (ProductsListRoutesPathEnd) {
    ProductsListRoutesPathEnd["Details"] = "detalhes";
    ProductsListRoutesPathEnd["Edit"] = "editar";
    ProductsListRoutesPathEnd["Merchants"] = "clientes";
    ProductsListRoutesPathEnd["Distributors"] = "distribuidores";
    ProductsListRoutesPathEnd["PricePreview"] = "preview";
    ProductsListRoutesPathEnd["Share"] = "compartilhar";
    ProductsListRoutesPathEnd["MassShare"] = "disparo-massivo";
})(ProductsListRoutesPathEnd || (ProductsListRoutesPathEnd = {}));
export var ProductsListRoutes = {
    Details: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.Details); },
    DetailsWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.Details),
    Edit: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.Edit); },
    EditWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.Edit),
    Merchants: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.Merchants); },
    MerchantsWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.Merchants),
    Distributors: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.Distributors); },
    DistributorsWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.Distributors),
    PricePreview: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.PricePreview); },
    PricePreviewWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.PricePreview),
    Share: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.Share); },
    ShareWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.Share),
    MassShare: function (id) { return "/".concat(ProductsListBase, "/").concat(id, "/").concat(ProductsListRoutesPathEnd.MassShare); },
    MassShareWithParam: "/".concat(ProductsListBase, "/:").concat(ProductsListPageParam, "/").concat(ProductsListRoutesPathEnd.MassShare),
};
export var NEW_LIST_ID = 'nova-lista';
