var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from 'react';
import { trpc } from '@app/core/trpc';
var LIMIT = 20;
export var useProductSearch = function (_a) {
    var _b;
    var searchTerm = _a.searchTerm, page = _a.page, merchants = _a.merchants, distributorId = _a.distributorId;
    var _c = useState(), data = _c[0], setData = _c[1];
    var pagination = {
        limit: LIMIT,
        offset: (page - 1) * LIMIT,
    };
    var firstMerchantId = (_b = merchants[0]) === null || _b === void 0 ? void 0 : _b.id;
    var response = trpc.product.list.useQuery({ searchTerm: searchTerm, pagination: pagination, merchantId: firstMerchantId, distributorId: distributorId }, {
        onSuccess: function (products) {
            if (page === 1) {
                setData(products);
                return;
            }
            setData(function (prev) { var _a; return (__assign(__assign({}, products), { nodes: __spreadArray(__spreadArray([], ((_a = prev === null || prev === void 0 ? void 0 : prev.nodes) !== null && _a !== void 0 ? _a : []), true), products.nodes, true) })); });
        },
        enabled: !!searchTerm,
    });
    return {
        data: data,
        isLoading: response.isFetching,
        error: response.error,
        refetch: response.refetch,
    };
};
