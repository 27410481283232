import * as React from 'react';
import { AppStrings } from '@app/app-strings';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { InputLabel } from '@atomic/atm.typography';
import { TextShimmerBoxStyled, ThumbShimmerBoxStyled } from '@atomic/mol.shimmer';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Row } from '@atomic/obj.grid';
import { MassShareMerchantsWrapperStyled, MassShareMessageWrapperStyled } from './mass-share-card.component.style';
var strings = AppStrings.productsList.massSharePage;
export var MassShareCardShimmer = function () { return (React.createElement(Card, { doubleGutter: true },
    React.createElement(InputLabel, null, strings.merchantsLabel),
    React.createElement(MassShareMerchantsWrapperStyled, null,
        React.createElement(TextShimmerBoxStyled, { width: '100px', height: '20px' }),
        React.createElement(TextShimmerBoxStyled, { width: '100px', height: '20px' }),
        React.createElement(TextShimmerBoxStyled, { width: '100px', height: '20px' })),
    React.createElement(Separator, { size: Spacing.Medium }),
    React.createElement(InputLabel, null, strings.messageLabel),
    React.createElement(MassShareMessageWrapperStyled, null,
        React.createElement(TextShimmerBoxStyled, { width: '400px', height: '12px' })),
    React.createElement(Separator, { size: Spacing.Medium }),
    React.createElement(InputLabel, null, strings.imagesLabel),
    React.createElement(ThumbShimmerBoxStyled, { height: '180px', width: '180px', margin: '0' }),
    React.createElement(Separator, null),
    React.createElement(Row, { justifyContent: 'flex-end', noGutter: true },
        React.createElement(Button.Primary, { loading: true }, strings.sendButton)))); };
