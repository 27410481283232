var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ColorFunc from 'color';
import styled from 'styled-components';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight, TransitionDuration, Shadow, } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
var flashMessageTypes = {
    warning: Color.Warning,
    success: Color.Success,
    info: Color.Neutral,
    alert: Color.Error,
};
export var FlashMessageContentStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n  margin: 0 ", ";\n"], ["\n  flex-grow: 1;\n  margin: 0 ", ";\n"])), Spacing.Small);
export var FlashMessageCloseStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n\n  &:hover > span {\n    color: ", ";\n  }\n"], ["\n  ", "\n\n  &:hover > span {\n    color: ", ";\n  }\n"])), highlightStyle, function (props) { return ColorFunc(flashMessageTypes[props.type]).darken(0.5).string(); });
export var FlashMessageStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  transition: opacity ", " ease-in-out;\n  box-shadow: ", ";\n\n  p,\n  svg {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  transition: opacity ", " ease-in-out;\n  box-shadow: ", ";\n\n  p,\n  svg {\n    color: ", ";\n  }\n"])), function (props) { return ColorFunc(flashMessageTypes[props.type]).lighten(1).string(); }, Border.Radius, Spacing.Medium, FontFamily.Primary, FontWeight.Normal, FontSize.Medium, TransitionDuration, Shadow.Medium, function (props) { return ColorFunc(flashMessageTypes[props.type]).darken(0.3).string(); });
var templateObject_1, templateObject_2, templateObject_3;
