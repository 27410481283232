import React from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { BodySecondary } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row } from '@atomic/obj.grid';
export var ListQuantity = function (_a) {
    var quantity = _a.quantity;
    return (React.createElement(Row, { noGutter: true, alignItems: 'center' },
        React.createElement(Column, null,
            React.createElement(AppIcon.InfoOutlined, null)),
        React.createElement(HorizontalSpacer, { "$width": Spacing.Small }),
        React.createElement(Column, null,
            React.createElement(BodySecondary, { bold: true }, AppStrings.productsList.listQuantity.text(quantity)))));
};
