var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { ProductCardShimmer } from '@app/components/mol.product-card';
import { Divider } from '@atomic/atm.divider';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer';
import { VerticalSpacer } from '@atomic/obj.grid';
export var ProductsListingShimmer = function (_a) {
    var withStepper = _a.withStepper;
    return (React.createElement(React.Fragment, null,
        React.createElement(TextShimmerBoxStyled, { height: '22px', width: '30%', margin: '0' }),
        React.createElement(VerticalSpacer, null),
        React.createElement(Divider, null),
        __spreadArray([], Array(5), true).map(function (_, index) { return (React.createElement(React.Fragment, { key: index },
            React.createElement(ProductCardShimmer, { withStepper: withStepper }),
            React.createElement(Divider, null))); })));
};
