import React from 'react';
import { AppStrings } from '@app/app-strings';
import { formatPrice, getProductListProperties } from '@app/utils';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { Divider } from '@atomic/atm.divider';
import { Body, BodySecondary, Ellipsed, H3 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row, VerticalSpacer } from '@atomic/obj.grid';
var strings = AppStrings.components.productsListCard;
export var ProductsListCard = function (props) {
    var productList = props.productList;
    var _a = getProductListProperties(productList), merchantNames = _a.merchantNames, totalPrice = _a.totalPrice;
    return (React.createElement(Card, { noGutter: true },
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement(VerticalSpacer, null),
                !!props.badgeText && React.createElement(Badge, { text: props.badgeText, color: 'primary' }),
                React.createElement(H3, null, productList.title),
                React.createElement(VerticalSpacer, { "$height": Spacing.Small }))),
        React.createElement(Divider, null),
        React.createElement(Row, null,
            React.createElement(Column, { flex: 1 },
                React.createElement(VerticalSpacer, null),
                totalPrice > 0 && React.createElement(Body, { bold: true }, formatPrice(totalPrice)),
                !!merchantNames.length && (React.createElement(BodySecondary, null,
                    React.createElement(Ellipsed, { maxLines: 2 }, strings.sentTo(merchantNames)))),
                (props.onPrimaryButtonClick || props.onSecondaryButtonClick) && (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, null),
                    React.createElement(Row, { noGutter: true, justifyContent: { xs: 'center', md: 'flex-end' } },
                        props.onSecondaryButtonClick && (React.createElement(React.Fragment, null,
                            React.createElement(Column, null,
                                React.createElement(Button.Secondary, { onClick: props.onSecondaryButtonClick }, strings.seeDetailsButton)),
                            React.createElement(HorizontalSpacer, null))),
                        props.onPrimaryButtonClick && (React.createElement(Column, null,
                            React.createElement(Button.Primary, { onClick: props.onPrimaryButtonClick, loading: props.loading }, strings.useListButton)))))))),
        React.createElement(VerticalSpacer, null)));
};
