var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { IcSvgStyled } from '@app/components/atm.app-icon/app-icon.component.style';
export var IcCartPlus = function (props) { return (React.createElement(IcSvgStyled, __assign({ viewBox: '0 0 24 24' }, props),
    React.createElement("path", { d: 'M11.92 9.5H13.92V6.5H16.92V4.5H13.92V1.5H11.92V4.5H8.91998V6.5H11.92V9.5ZM7.91998 18.5C6.81998 18.5 5.92998 19.4 5.92998 20.5C5.92998 21.6 6.81998 22.5 7.91998 22.5C9.01998 22.5 9.91998 21.6 9.91998 20.5C9.91998 19.4 9.01998 18.5 7.91998 18.5ZM17.92 18.5C16.82 18.5 15.93 19.4 15.93 20.5C15.93 21.6 16.82 22.5 17.92 22.5C19.02 22.5 19.92 21.6 19.92 20.5C19.92 19.4 19.02 18.5 17.92 18.5ZM9.01998 13.5H16.47C17.22 13.5 17.88 13.09 18.22 12.47L22.08 5.46L20.34 4.5L16.47 11.5H9.44998L5.18998 2.5H1.91998V4.5H3.91998L7.51998 12.09L6.16998 14.53C5.43998 15.87 6.39998 17.5 7.91998 17.5H19.92V15.5H7.91998L9.01998 13.5Z', fill: '#222222' }))); };
