import React from 'react';
import { FlashMessage } from '@app/components/mol.flash-message';
import { FlashMessageProvider } from '@app/components/mol.provider';
import { TrpcProvider } from '@app/core/trpc';
import { useScrollToTop } from '@app/domain/scroll-to-top';
import { usePageViewAnalytics } from '@app/modules/common/page-view-analytics.use-case';
import { GlobalStyled } from '@atomic/obj.globals';
import { RootRouter } from './root.router';
function App() {
    usePageViewAnalytics();
    useScrollToTop();
    return (React.createElement(AppProviders, null,
        React.createElement(GlobalStyled, null),
        React.createElement(RootRouter, null),
        React.createElement(FlashMessage, null)));
}
var AppProviders = function (_a) {
    var children = _a.children;
    return (React.createElement(TrpcProvider, null,
        React.createElement(FlashMessageProvider, null, children)));
};
export default App;
