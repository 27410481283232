var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Container } from 'react-grid-system';
import styled, { css } from 'styled-components';
import { Breakpoint, MediaQuery, Spacing } from '@atomic/obj.constants';
export var Grid = Container;
var flexCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (props) { return buildPropWhenDefined('align-items', props.alignItems); }, function (props) { return buildPropWhenDefined('align-self', props.alignSelf); }, function (props) { return buildPropWhenDefined('justify-content', props.justifyContent); }, function (props) { return buildPropWhenDefined('gap', props.gap); }, function (props) { return buildPropWhenDefined('flex', props.flex); }, function (props) { return buildPropWhenDefined('flex-wrap', props.wrap); });
export var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  width: 100%;\n  display: flex;\n  flex-direction: ", ";\n\n  ", "\n\n  ", " {\n    flex-direction: row;\n  }\n"], ["\n  ", "\n  width: 100%;\n  display: flex;\n  flex-direction: ", ";\n\n  ", "\n\n  ", " {\n    flex-direction: row;\n  }\n"])), flexCss, function (props) { return (props.responsive ? (props.reverse ? 'column-reverse' : 'column') : 'row'); }, function (_a) {
    var noGutter = _a.noGutter;
    return !noGutter && "padding: 0 ".concat(Spacing.Medium, ";");
}, MediaQuery.md);
export var Column = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  display: flex;\n  flex-direction: ", ";\n\n  ", " {\n    flex-direction: column;\n  }\n"], ["\n  ", "\n  display: flex;\n  flex-direction: ", ";\n\n  ", " {\n    flex-direction: column;\n  }\n"])), flexCss, function (props) { return (props.responsive ? (props.reverse ? 'row-reverse' : 'row') : 'column'); }, MediaQuery.md);
export var Spacer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-shrink: 0;\n  ", "\n  ", "\n  ", "\n"], ["\n  flex-shrink: 0;\n  ", "\n  ", "\n  ", "\n"])), function (props) { var _a, _b; return buildPropWhenDefined('flex-basis', (_b = (_a = props.$size) !== null && _a !== void 0 ? _a : props.$width) !== null && _b !== void 0 ? _b : props.$height); }, function (props) { var _a, _b; return buildPropWhenDefined('width', (_b = (_a = props.$size) !== null && _a !== void 0 ? _a : props.$width) !== null && _b !== void 0 ? _b : 0); }, function (props) { var _a, _b; return buildPropWhenDefined('height', (_b = (_a = props.$size) !== null && _a !== void 0 ? _a : props.$height) !== null && _b !== void 0 ? _b : 0); });
export var VerticalSpacer = function (props) {
    var _a;
    return (React.createElement(Spacer, { "$height": (_a = props.$height) !== null && _a !== void 0 ? _a : Spacing.Medium }));
};
export var HorizontalSpacer = function (props) {
    var _a;
    return (React.createElement(Spacer, { "$width": (_a = props.$width) !== null && _a !== void 0 ? _a : Spacing.Medium }));
};
function buildPropWhenDefined(property, value) {
    if (!isResponsive(value)) {
        return buildPlainProperty(property, value);
    }
    return Object.entries(value).map(function (_a) {
        var key = _a[0], responsiveValue = _a[1];
        return responsiveValue !== undefined
            ? "".concat(MediaQuery[key], " { ").concat(property, ": ").concat(responsiveValue, "; }")
            : '';
    });
}
function buildPlainProperty(property, value) {
    return value !== undefined ? "".concat(property, ": ").concat(value, ";") : '';
}
function isResponsive(value) {
    return (value === null || value === void 0 ? void 0 : value.xs) !== undefined;
}
export var GridSettings = {
    gridColumns: 12,
    // Defaults
    gutterWidth: 30,
    breakpoints: [Breakpoint.sm, Breakpoint.md, Breakpoint.lg, Breakpoint.xl, Breakpoint.xxl],
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
