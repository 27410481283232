var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { Placeholder } from '@app/components/mol.placeholder';
import { useFlashMessage } from '@app/components/mol.provider';
import { AnalyticsService, MassShareAnalytics, ShareAnalytics } from '@app/data/analytics';
import { useDetailProductList } from '@app/domain/product-list';
import { useMassShare } from '@app/domain/share';
import { getListId } from '@app/utils';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { FaIcon } from '@atomic/atm.fa-icon';
import { useMatchMedia } from '@atomic/atm.match-media';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Grid, VerticalSpacer, Column, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { MassShareCard, MassShareCardShimmer, MassShareSuccessAccordion, ShareMerchantCard } from './components';
var strings = AppStrings.productsList.massSharePage;
var MassSharePage = function () {
    var _a, _b;
    var _c = React.useState(false), showIndividualShare = _c[0], setShowIndividualShare = _c[1];
    var _d = React.useState(false), merchantsAccordionExpanded = _d[0], setMerchantsAccordionExpanded = _d[1];
    var listId = useParams().listId;
    var productListId = getListId(listId);
    var isMobile = useMatchMedia('md').matchBreakpoint;
    var _e = useFlashMessage(), showAlert = _e.showAlert, showWarning = _e.showWarning;
    var _f = useDetailProductList({ id: productListId }), productListDetails = _f.data, productListLoading = _f.isLoading, error = _f.error, refetch = _f.refetch;
    var merchants = (_a = productListDetails === null || productListDetails === void 0 ? void 0 : productListDetails.merchants) !== null && _a !== void 0 ? _a : [];
    var merchantsWithPhone = merchants.filter(function (merchant) { return !!merchant.phone; });
    var merchantsWithoutPhone = merchants.filter(function (merchant) { return !merchant.phone; });
    var sendAnalytics = function (data) {
        var totalMerchants = merchants.length;
        AnalyticsService.send(MassShareAnalytics({
            messageFailRatio: data.notSentMerchants.length / totalMerchants,
            cartFailRatio: data.cartFailedMerchants.length / totalMerchants,
            noPhoneRatio: merchantsWithoutPhone.length / totalMerchants,
            successRatio: data.sentMerchants.length / totalMerchants,
        }));
    };
    var _g = useMassShare({
        onSuccess: function (data) {
            if (data.notSentMerchants.length || data.cartFailedMerchants.length || merchantsWithoutPhone.length) {
                setShowIndividualShare(true);
            }
            sendAnalytics(data);
        },
        onError: function () {
            showAlert(strings.massShareError);
            setShowIndividualShare(true);
        },
    }), massShareData = _g.data, massShare = _g.mutate, massShareLoading = _g.isLoading;
    var handleMassShareClick = function (imageUrl) {
        if (productListId && merchants.length) {
            AnalyticsService.send(ShareAnalytics({
                contentType: 'mass_share',
                method: 'api',
                itemId: productListId === null || productListId === void 0 ? void 0 : productListId.toString(),
                clients: merchantsWithPhone.map(function (merchant) { return merchant.id.toString(); }),
                isMobile: !!isMobile,
            }));
            showWarning(strings.massShareWarning);
            massShare({
                productListId: productListId,
                imageUrl: imageUrl,
                merchantIds: merchantsWithPhone.map(function (merchant) { return merchant.id; }),
            });
        }
    };
    var placeholderProps = massShareData && !(massShareData === null || massShareData === void 0 ? void 0 : massShareData.sentMerchants.length)
        ? __assign({ icon: 'Error' }, strings.errorPlaceholder) : showIndividualShare
        ? __assign({ icon: 'Warning' }, strings.warningPlaceholder) : __assign({ icon: 'Success' }, strings.successPlaceholder);
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true, alignItems: 'flex-start' },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(Placeholder, __assign({}, placeholderProps)))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    (!massShareData || !!(massShareData === null || massShareData === void 0 ? void 0 : massShareData.sentMerchants.length)) && (React.createElement(React.Fragment, null,
                        React.createElement(Hbox, null,
                            !!(massShareData === null || massShareData === void 0 ? void 0 : massShareData.sentMerchants.length) && (React.createElement(React.Fragment, null,
                                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                                    React.createElement(FaIcon.Check, { size: '2x', color: Color.SuccessDark })),
                                React.createElement(Hbox.Separator, null))),
                            React.createElement(Hbox.Item, { noGrow: true },
                                React.createElement(H2, { variant: !!(massShareData === null || massShareData === void 0 ? void 0 : massShareData.sentMerchants.length) ? 'success' : 'primary', onClick: function () { return setMerchantsAccordionExpanded(!merchantsAccordionExpanded); } }, strings.massShareTitle)),
                            React.createElement(Hbox.Separator, { size: Spacing.Small }),
                            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                                React.createElement(Badge, { color: 'black', radiusLarge: true, text: strings.beta }))),
                        React.createElement(Separator, { size: Spacing.Small }))),
                    massShareData ? (!!massShareData.sentMerchants.length && (React.createElement(MassShareSuccessAccordion, { sentMerchantNames: massShareData.sentMerchants.map(function (merchant) { return merchant.name; }), totalMerchants: merchants.length }))) : (React.createElement(LoadingState, { data: !!merchants.length, loading: productListLoading, error: !!error },
                        React.createElement(LoadingState.Error, null,
                            React.createElement(Card, { doubleGutter: true },
                                React.createElement(Placeholder, { title: strings.errorMessage, icon: 'GenericError' },
                                    React.createElement(Row, { noGutter: true, justifyContent: 'center' },
                                        React.createElement(Button.Secondary, { onClick: refetch }, strings.errorButton))))),
                        React.createElement(LoadingState.Shimmer, null,
                            React.createElement(MassShareCardShimmer, null)),
                        React.createElement(Separator, { size: Spacing.XSmall }),
                        React.createElement(MassShareCard, { merchantNames: merchants.map(function (merchant) { return merchant.name; }), productListImages: (_b = productListDetails === null || productListDetails === void 0 ? void 0 : productListDetails.images) !== null && _b !== void 0 ? _b : [], onShareClick: handleMassShareClick, loading: massShareLoading }))),
                    showIndividualShare && (React.createElement(React.Fragment, null,
                        !!(massShareData === null || massShareData === void 0 ? void 0 : massShareData.sentMerchants.length) && React.createElement(Separator, { size: Spacing.XLarge }),
                        React.createElement(H2, { noMargin: true }, strings.individualShareTitle),
                        React.createElement(Body, null, strings.individualShareSubtitle),
                        React.createElement(Separator, { size: Spacing.Medium }),
                        ((massShareData === null || massShareData === void 0 ? void 0 : massShareData.cartFailedMerchants.length) || (massShareData === null || massShareData === void 0 ? void 0 : massShareData.notSentMerchants.length)) && (React.createElement(React.Fragment, null,
                            React.createElement(Body, { bold: true }, strings.failedMerchantsTitle(massShareData.cartFailedMerchants.length + massShareData.notSentMerchants.length)),
                            React.createElement(Separator, { size: Spacing.Small }),
                            massShareData.cartFailedMerchants.map(function (merchant) { return (React.createElement(React.Fragment, { key: merchant.id },
                                React.createElement(ShareMerchantCard, { merchant: merchant, productListId: productListId }),
                                React.createElement(Separator, { size: Spacing.Medium }))); }),
                            massShareData.notSentMerchants.map(function (merchant) { return (React.createElement(ShareMerchantCard, { merchant: merchant, productListId: productListId, link: merchant.cartUrl, key: merchant.id })); }))),
                        !!merchantsWithoutPhone.length && (React.createElement(React.Fragment, null,
                            React.createElement(Body, { bold: true }, strings.noPhoneMerchantsTitle(merchantsWithoutPhone.length)),
                            React.createElement(Separator, { size: Spacing.Small }),
                            merchantsWithoutPhone.map(function (merchant) { return (React.createElement(ShareMerchantCard, { key: merchant.id, merchant: merchant, productListId: productListId })); }))))))),
            React.createElement(VerticalSpacer, null))));
};
export default MassSharePage;
