import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { RadioCheckedStyled, RadioFieldInputStyled, RadioFieldLabelStyled, RadioFieldStyled, RadioUncheckedStyled, } from './radio-field.component.style';
var DefaultRadioBullet = function (props) {
    return props.checked ? React.createElement(RadioCheckedStyled, { "aria-hidden": 'true' }) : React.createElement(RadioUncheckedStyled, { "aria-hidden": 'true' });
};
export var RadioField = function (props) {
    var _a, _b;
    var formFieldConsumer = React.useContext(FormFieldContext);
    if (!formFieldConsumer) {
        throw new Error('<RadioField /> must be wrapped with a <Form.Field> tag');
    }
    var handlePress = function () {
        var _a;
        (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, props.id);
        formFieldConsumer.onValueChange(props.id, null);
    };
    var radioFieldId = formFieldConsumer.name + '_' + props.id;
    return (React.createElement(RadioFieldStyled, { mb: props.mb, expanded: (_a = props.expanded) !== null && _a !== void 0 ? _a : false, onClick: handlePress },
        React.createElement(RadioFieldInputStyled, { type: 'radio', name: formFieldConsumer.name, id: radioFieldId, value: props.id }),
        React.createElement(DefaultRadioBullet, { checked: formFieldConsumer.value === props.id }),
        React.createElement(RadioFieldLabelStyled, { htmlFor: radioFieldId, expanded: (_b = props.expanded) !== null && _b !== void 0 ? _b : false }, props.children)));
};
