import { initializeAnalytics, logEvent as fbLogEvent, setUserId as fbSetUserId, setUserProperties as fbSetUserProperties, } from 'firebase/analytics';
// Length limits reference: https://support.google.com/firebase/answer/9237506
var EVENT_NAME_MAX_LENGTH = 40;
var EVENT_PARAM_MAX_LENGTH = { name: 40, value: 100 };
var USER_PROPERTIES_MAX_LENGTH = { name: 24, value: 36 };
var FirebaseAnalyticsService = /** @class */ (function () {
    function FirebaseAnalyticsService(app) {
        var config = {
            config: {
                send_page_view: false,
            },
        };
        this._analytics = initializeAnalytics(app, config);
    }
    FirebaseAnalyticsService.prototype.logEvent = function (eventName, eventParams) {
        var name = formatKey(eventName, EVENT_NAME_MAX_LENGTH);
        var params = eventParams && formatParams(eventParams, EVENT_PARAM_MAX_LENGTH);
        fbLogEvent(this._analytics, name, params);
    };
    FirebaseAnalyticsService.prototype.logPageView = function (eventParams) {
        var params = formatParams(eventParams, EVENT_PARAM_MAX_LENGTH);
        window.gtag('set', { page_title: params.firebase_screen });
        fbLogEvent(this._analytics, 'screen_view', params);
    };
    FirebaseAnalyticsService.prototype.setUserId = function (id) {
        fbSetUserId(this._analytics, id);
    };
    FirebaseAnalyticsService.prototype.setUserProperties = function (eventProperties) {
        var properties = formatParams(eventProperties, USER_PROPERTIES_MAX_LENGTH);
        fbSetUserProperties(this._analytics, properties);
    };
    return FirebaseAnalyticsService;
}());
export { FirebaseAnalyticsService };
var formatKey = function (text, maxLength) {
    return text
        .replace(/-/g, '_')
        .replace(/\s/g, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .slice(0, maxLength);
};
var formatParams = function (eventParams, maxLength) {
    var params = {};
    Object.entries(eventParams).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        var newKey = formatKey(key, maxLength.name);
        var newValue = typeof value === 'string' ? value.slice(0, maxLength.value) : value;
        params[newKey] = newValue;
    });
    return params;
};
