export var BrandColor;
(function (BrandColor) {
    BrandColor["OrangeLight"] = "#FF9D66";
    BrandColor["Orange"] = "#FF703E";
    BrandColor["OrangeDark"] = "#D74614";
    BrandColor["BlueLight"] = "#377A95";
    BrandColor["Blue"] = "#1F4554";
    BrandColor["BlueDark"] = "#0E1F25";
})(BrandColor || (BrandColor = {}));
// We used class rather than enums since enums don't support computed values
var Color = /** @class */ (function () {
    function Color() {
    }
    Color.Black = '#222222';
    Color.White = '#FFFFFF';
    Color.GrayXLight = '#F2F4F4';
    Color.GrayLight = '#E5E9EA';
    Color.Gray = '#99A6AB';
    Color.GrayDark = '#646C6F';
    Color.GrayXDark = '#2E3233';
    Color.Neutral = Color.GrayDark;
    Color.PrimaryLight = BrandColor.BlueLight;
    Color.Primary = BrandColor.Blue;
    Color.PrimaryDark = BrandColor.BlueDark;
    Color.CallToActionLight = BrandColor.OrangeLight;
    Color.CallToAction = BrandColor.Orange;
    Color.CallToActionDark = BrandColor.OrangeDark;
    Color.Background = '#F6F6F6';
    Color.ErrorLight = '#FADBDF';
    Color.Error = '#DF2034';
    Color.ErrorDark = '#892E38';
    Color.WarningLight = '#FEEDB4';
    Color.Warning = '#B2911E';
    Color.WarningDark = '#65541B';
    Color.SuccessLight = '#DCF6ED';
    Color.Success = '#4FD3A5';
    Color.SuccessDark = '#2F795F';
    Color.Accessory = '#DAEBF1';
    return Color;
}());
export { Color };
export var FontFamily;
(function (FontFamily) {
    FontFamily["Primary"] = "Work Sans";
})(FontFamily || (FontFamily = {}));
export var FontWeight;
(function (FontWeight) {
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    FontWeight["Bold"] = "bold";
    FontWeight["Normal"] = "normal";
})(FontWeight || (FontWeight = {}));
export var FontSize;
(function (FontSize) {
    FontSize["XXSmall"] = "11px";
    FontSize["XSmall"] = "12px";
    FontSize["Small"] = "14px";
    FontSize["Medium"] = "16px";
    FontSize["Large"] = "18px";
    FontSize["XLarge"] = "20px";
    FontSize["XXLarge"] = "22px";
    FontSize["XXXLarge"] = "24px";
    FontSize["XXXXLarge"] = "32px";
})(FontSize || (FontSize = {}));
export var Spacing;
(function (Spacing) {
    Spacing["XSmall"] = "4px";
    Spacing["Small"] = "8px";
    Spacing["Medium"] = "16px";
    Spacing["Large"] = "24px";
    Spacing["XLarge"] = "32px";
})(Spacing || (Spacing = {}));
export var HeaderHeight = {
    Mobile: '64px',
    Desk: '80px',
};
export var IconSize;
(function (IconSize) {
    IconSize["Small"] = "12px";
    IconSize["Medium"] = "16px";
    IconSize["Large"] = "24px";
})(IconSize || (IconSize = {}));
export var FaIconSize;
(function (FaIconSize) {
    FaIconSize["XSmall"] = "xs";
    FaIconSize["Large"] = "lg";
    FaIconSize["Small"] = "sm";
    FaIconSize["X1"] = "1x";
    FaIconSize["X2"] = "2x";
    FaIconSize["X3"] = "3x";
    FaIconSize["X4"] = "4x";
    FaIconSize["X5"] = "5x";
    FaIconSize["X6"] = "6x";
    FaIconSize["X7"] = "7x";
    FaIconSize["X8"] = "8x";
    FaIconSize["X9"] = "9x";
    FaIconSize["X10"] = "10x";
})(FaIconSize || (FaIconSize = {}));
export var DrawerWidth = '256px';
export var Navbar = {
    a: '1',
};
export var FieldHeight = '48px';
export var TransitionDuration = '.3s';
export var Border = {
    Color: Color.GrayLight,
    Width: '1px',
    WidthLarge: '2px',
    Radius: '8px',
    RadiusLarge: '24px',
};
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export var PasswordLength;
(function (PasswordLength) {
    PasswordLength[PasswordLength["Max"] = 18] = "Max";
    PasswordLength[PasswordLength["Min"] = 7] = "Min";
})(PasswordLength || (PasswordLength = {}));
export var AspectRatio;
(function (AspectRatio) {
    AspectRatio[AspectRatio["Square"] = 1] = "Square";
})(AspectRatio || (AspectRatio = {}));
export var Breakpoint = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};
export var MediaQuery = {
    xs: '@media all and (min-width: 0)',
    sm: "@media all and (min-width: ".concat(Breakpoint.sm - 0.2, "px)"),
    md: "@media all and (min-width: ".concat(Breakpoint.md - 0.2, "px)"),
    lg: "@media all and (min-width: ".concat(Breakpoint.lg - 0.2, "px)"),
    xl: "@media all and (min-width: ".concat(Breakpoint.xl - 0.2, "px)"),
    xxl: "@media all and (min-width: ".concat(Breakpoint.xxl - 0.2, "px)"),
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["ground"] = 0] = "ground";
    ZIndex[ZIndex["first"] = 1] = "first";
    ZIndex[ZIndex["second"] = 2] = "second";
    ZIndex[ZIndex["third"] = 3] = "third";
    ZIndex[ZIndex["top"] = 4] = "top";
})(ZIndex || (ZIndex = {}));
export var Shadow = {
    Small: '0px 2px 2px 0px rgba(0,0,0,0.2)',
    Medium: '1px 1px 8px 0px rgba(0,0,0,0.2)',
};
export var ColumnMaxWidth = '600px';
