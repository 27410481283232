import * as React from 'react';
import { AppStrings } from '@app/app-strings';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { Card } from '@atomic/atm.card';
import { InputLabel } from '@atomic/atm.typography';
import { ImageCarousel } from '@atomic/mol.image-carousel';
import { Spacing } from '@atomic/obj.constants';
import { Row, VerticalSpacer } from '@atomic/obj.grid';
import { MassShareCardHiddenMerchantsButtonStyled, MassShareCardGutterStyled, MassShareMerchantsWrapperStyled, MassShareMessageWrapperStyled, MassShareCardMessageText, } from './mass-share-card.component.style';
var strings = AppStrings.productsList.massSharePage;
var MAX_INITIAL_MERCHANTS_SHOWN = 5;
export var MassShareCard = function (props) {
    var _a, _b;
    var _c = React.useState(props.merchantNames.slice(0, MAX_INITIAL_MERCHANTS_SHOWN)), shownMerchantNames = _c[0], setShownMerchantNames = _c[1];
    var _d = React.useState(), selectedImageIndex = _d[0], setSelectedImageIndex = _d[1];
    var hiddenMerchantNamesLength = props.merchantNames.length - shownMerchantNames.length;
    var handleImageClick = function (index) {
        if (selectedImageIndex === index) {
            setSelectedImageIndex(undefined);
        }
        else {
            setSelectedImageIndex(index);
        }
    };
    var handleShareClick = function () {
        if (props.onShareClick) {
            var selectedImageUrl = selectedImageIndex !== undefined ? props.productListImages[selectedImageIndex] : undefined;
            props.onShareClick(selectedImageUrl);
        }
    };
    return (React.createElement(Card, { noGutter: true },
        React.createElement(VerticalSpacer, { "$height": Spacing.XLarge }),
        React.createElement(MassShareCardGutterStyled, null,
            React.createElement(InputLabel, null, strings.merchantsLabel),
            React.createElement(MassShareMerchantsWrapperStyled, null,
                shownMerchantNames.map(function (merchantName, index) { return (React.createElement(Badge, { color: 'white', key: "".concat(merchantName, "-").concat(index), text: merchantName })); }),
                hiddenMerchantNamesLength > 0 && (React.createElement(MassShareCardHiddenMerchantsButtonStyled, { onClick: function () { return setShownMerchantNames(props.merchantNames); } },
                    React.createElement(Badge, { color: 'white', text: "+".concat(hiddenMerchantNamesLength) })))),
            React.createElement(VerticalSpacer, null),
            React.createElement(InputLabel, null, strings.messageLabel),
            React.createElement(MassShareMessageWrapperStyled, null,
                React.createElement(MassShareCardMessageText, null, strings.message)),
            !!((_a = props.productListImages) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(React.Fragment, null,
                React.createElement(VerticalSpacer, null),
                React.createElement(InputLabel, null, strings.imagesLabel)))),
        !!((_b = props.productListImages) === null || _b === void 0 ? void 0 : _b.length) && (React.createElement(ImageCarousel, { selectedImageIndex: selectedImageIndex, imageUrls: props.productListImages, onClick: handleImageClick })),
        React.createElement(VerticalSpacer, { "$height": Spacing.Large }),
        React.createElement(MassShareCardGutterStyled, null,
            React.createElement(Row, { justifyContent: 'flex-end', noGutter: true },
                React.createElement(Button.Primary, { onClick: handleShareClick, loading: props.loading }, strings.sendButton))),
        React.createElement(VerticalSpacer, { "$height": Spacing.XLarge })));
};
