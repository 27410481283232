var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputValue } from '@atomic/atm.typography';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Color, Spacing } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
var radioSize = 24;
export var RadioFieldStyled = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  margin-bottom: ", ";\n  ", "\n  & ~ * {\n    margin-top: ", ";\n  }\n\n  &:focus-within {\n    ", "\n  }\n"], ["\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  margin-bottom: ", ";\n  ", "\n  & ~ * {\n    margin-top: ", ";\n  }\n\n  &:focus-within {\n    ", "\n  }\n"])), function (props) { return (props.mb ? Spacing.Medium : 0); }, function (props) { return (props.expanded ? 'flex: 1;' : undefined); }, Spacing.Small, clickableFocusCss);
export var RadioFieldLabelStyled = styled(InputValue)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line-height: ", "px !important;\n  padding-left: 36px;\n  cursor: pointer;\n  ", "\n"], ["\n  line-height: ", "px !important;\n  padding-left: 36px;\n  cursor: pointer;\n  ", "\n"])), radioSize, function (props) { return (props.expanded ? 'flex: 1;' : undefined); });
export var RadioFieldContentStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: ", ";\n  ", ";\n"], ["\n  margin-left: ", ";\n  ", ";\n"])), Spacing.XLarge, highlightStyle);
export var RadioCheckedStyled = styled(FaIcon.DotCircle).attrs({ size: 'lg' })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  color: ", ";\n"], ["\n  position: absolute;\n  color: ", ";\n"])), Color.Primary);
export var RadioUncheckedStyled = styled(FaIcon.Circle).attrs({ size: 'lg' })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  color: ", ";\n"], ["\n  position: absolute;\n  color: ", ";\n"])), Color.Black);
export var RadioFieldInputStyled = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  opacity: 0; /* keeping the input available on the accessibility tree */\n"], ["\n  position: absolute;\n  opacity: 0; /* keeping the input available on the accessibility tree */\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
