export var PageName;
(function (PageName) {
    PageName["Home"] = "home";
    PageName["ListDetails"] = "list_details";
    PageName["ListEdit"] = "list_edit";
    PageName["ListMerchants"] = "list_merchants";
    PageName["ListDistributors"] = "list_distributors";
    PageName["ListPricePreview"] = "list_price_preview";
    PageName["ListShare"] = "list_share";
    PageName["ListMassShare"] = "list_mass_share";
    PageName["Login"] = "login";
})(PageName || (PageName = {}));
