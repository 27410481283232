var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { ColumnMaxWidthWrapper } from '@app/components/atm.page-wrapper';
import { Placeholder } from '@app/components/mol.placeholder';
import { ProductsListCard } from '@app/components/mol.products-list-card';
import { useDraft } from '@app/domain/draft';
import { useSearchProductList } from '@app/domain/product-list';
import { ListTabs } from '@app/model';
import { Button } from '@atomic/atm.button';
import { Body, H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row, VerticalSpacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { ListTab } from './list-tab.component';
import { ProductsListShimmer } from './products-list.shimmer.component';
var strings = AppStrings.home.productsList;
export var ProductsList = function (props) {
    var _a = React.useState(ListTabs.Suggestion), currentTab = _a[0], setCurrentTab = _a[1];
    var _b = React.useState(1), page = _b[0], setPage = _b[1];
    var _c = useDraft(), id = _c.id, title = _c.title, merchants = _c.merchants, items = _c.items, hasDraft = _c.hasDraft;
    var draft = {
        id: id,
        title: title !== null && title !== void 0 ? title : strings.draft,
        items: items,
        merchants: merchants,
        type: ListTabs.MadeByMe,
    };
    var _d = useSearchProductList({ page: page, type: currentTab }), data = _d.data, isLoading = _d.isLoading, error = _d.error, refetch = _d.refetch;
    var handleTabChange = function (newTab) {
        setCurrentTab(newTab);
        setPage(1);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ColumnMaxWidthWrapper, { gutter: true },
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { noGutter: true },
                React.createElement(Column, { justifyContent: 'center', flex: 1 },
                    React.createElement(H1, null, strings.title)),
                React.createElement(HorizontalSpacer, { "$width": Spacing.Small }),
                React.createElement(Column, null,
                    React.createElement(Button.CallToAction, { onClick: props.onNewListTap },
                        React.createElement(AppIcon.CartPlus, { size: 'large' }),
                        strings.newListButton))),
            React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
            React.createElement(Body, null, strings.description),
            React.createElement(VerticalSpacer, null)),
        React.createElement(ListTab, { onTabChange: handleTabChange }),
        React.createElement(VerticalSpacer, null),
        React.createElement(ColumnMaxWidthWrapper, { gutter: true },
            React.createElement(LoadingState, { data: !!(data === null || data === void 0 ? void 0 : data.nodes.length), loading: isLoading, error: !!error },
                React.createElement(LoadingState.Shimmer, null, __spreadArray([], new Array(3), true).map(function (_, index) { return (React.createElement(React.Fragment, { key: index },
                    React.createElement(ProductsListShimmer, null),
                    React.createElement(VerticalSpacer, null))); })),
                React.createElement(LoadingState.Error, null,
                    React.createElement(Placeholder, { title: strings.errorMessage, icon: 'GenericError' },
                        React.createElement(Row, { noGutter: true, justifyContent: 'center' },
                            React.createElement(Button.Secondary, { onClick: refetch }, strings.errorButton)))),
                React.createElement(LoadingState.NoData, null,
                    React.createElement(Placeholder, { title: strings.noDataMessage, icon: 'GenericError' })),
                currentTab === ListTabs.MadeByMe && hasDraft && (React.createElement(React.Fragment, null,
                    React.createElement(ProductsListCard, { productList: draft, badgeText: strings.draft, onPrimaryButtonClick: props.onDraftTap }),
                    React.createElement(VerticalSpacer, null))), data === null || data === void 0 ? void 0 :
                data.nodes.map(function (item) { return (React.createElement(React.Fragment, { key: item.id },
                    React.createElement(ProductsListCard, { productList: item, onPrimaryButtonClick: function () { return props.onUseListTap(item, currentTab); }, onSecondaryButtonClick: function () { return props.onSeeListTap(item); }, loading: props.loading }),
                    React.createElement(VerticalSpacer, null))); }),
                (data === null || data === void 0 ? void 0 : data.pageInfo.hasNextPage) && (React.createElement(Row, { justifyContent: 'center' },
                    React.createElement(Button.Link, { onClick: function () { return setPage(function (cur) { return cur + 1; }); } }, strings.seeMore)))))));
};
