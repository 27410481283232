import * as React from 'react';
import { FlashMessageContext } from '@app/components/mol.provider';
import { FlashMessage as AtomicFlashMessage } from '@atomic/mol.flash-message';
import { FlashMessagePositionWrapper, FlashMessageWrapper } from './flash-message.component.style';
export var FlashMessage = function () {
    var _a = React.useContext(FlashMessageContext), flashMessage = _a.flashMessage, hideFlashMessage = _a.hideFlashMessage;
    var _b = flashMessage || {}, show = _b.show, message = _b.message, type = _b.type;
    return show ? (React.createElement(FlashMessagePositionWrapper, null,
        React.createElement(FlashMessageWrapper, null,
            React.createElement(AtomicFlashMessage, { type: type, onClose: hideFlashMessage }, message)))) : null;
};
