var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { MerchantItem } from '@app/components/mol.merchant-item';
import { useFlashMessage } from '@app/components/mol.provider';
import { AnalyticsService, AppErrorAnalytics, CreateLinkAnalytics, ShareAnalytics } from '@app/data/analytics';
import { useShare } from '@app/domain/share';
import { copyToClipboard, getMerchantName, getWhatsAppUrl } from '@app/utils';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { FaIcon } from '@atomic/atm.fa-icon';
import { useMatchMedia } from '@atomic/atm.match-media';
import { Body, H3 } from '@atomic/atm.typography';
import { Accordion } from '@atomic/mol.accordion';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, VerticalSpacer } from '@atomic/obj.grid';
import { ShareMerchantWrapper } from './share-merchant-wrapper.component.style';
import { WhatsappModal } from './whatsapp.modal.component';
var strings = AppStrings.productsList.merchantShare;
export var ShareMerchantCard = function (props) {
    var _a;
    var merchant = props.merchant, productListId = props.productListId;
    var whatsappUrlRef = React.useRef('');
    var _b = useState((_a = props.link) !== null && _a !== void 0 ? _a : ''), link = _b[0], setLink = _b[1];
    var _c = React.useState(false), visible = _c[0], setVisible = _c[1];
    var _d = React.useState(false), detailsExpanded = _d[0], setDetailsExpanded = _d[1];
    var _e = useFlashMessage(), showSuccess = _e.showSuccess, showAlert = _e.showAlert, showWarning = _e.showWarning;
    var _f = useShare({
        onSuccess: function (_a) {
            var url = _a.url, addedProducts = _a.addedProducts;
            setLink(url);
            AnalyticsService.send(CreateLinkAnalytics({
                itemId: productListId.toString(),
                client: merchant.id.toString(),
                products: addedProducts,
            }));
        },
        onError: function () { return showAlert(strings.shareError); },
    }), mutate = _f.mutate, isLoadingShare = _f.isLoading;
    var handleClick = function () {
        showWarning(strings.shareLargeCartWarning);
        mutate({ productListId: productListId, merchantId: merchant.id });
    };
    var isMobile = useMatchMedia('md').matchBreakpoint;
    var sendShareAnalytics = function (contentType, method) {
        return AnalyticsService.send(ShareAnalytics({
            contentType: contentType,
            method: method,
            itemId: productListId.toString(),
            client: merchant.id.toString(),
            isMobile: !!isMobile,
        }));
    };
    var handleShare = function () {
        var message = strings.message(getMerchantName(merchant), link);
        whatsappUrlRef.current = getWhatsAppUrl(merchant.phone, message);
        if (isMobile) {
            sendShareAnalytics('url_string_mobile', 'whatsapp');
            window.open(whatsappUrlRef.current);
        }
        else {
            sendShareAnalytics('qr_code', 'whatsapp');
            setVisible(true);
        }
    };
    var handleCopy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var message, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    message = strings.message(getMerchantName(merchant), link);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, copyToClipboard(message)];
                case 2:
                    _b.sent();
                    sendShareAnalytics('message', 'copy');
                    showSuccess(strings.copySuccess);
                    return [3 /*break*/, 4];
                case 3:
                    _a = _b.sent();
                    AnalyticsService.send(AppErrorAnalytics({ message: strings.copyError, type: 'UserError' }));
                    showWarning(strings.copyError);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleWhatappModalShare = function () {
        sendShareAnalytics('url_string_desktop', 'whatsapp');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ShareMerchantWrapper, null,
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { vAlign: 'center' },
                    React.createElement(H3, null, getMerchantName(merchant))),
                React.createElement(Hbox.Item, { noGrow: true, hAlign: 'flex-end', vAlign: 'center' },
                    React.createElement(Button.Link, { onClick: function () { return setDetailsExpanded(!detailsExpanded); } },
                        detailsExpanded ? React.createElement(FaIcon.ChevronUp, null) : React.createElement(FaIcon.ChevronDown, null),
                        strings.details))),
            React.createElement(Accordion, { noTitle: true, expanded: detailsExpanded },
                React.createElement(MerchantItem, { hideName: true, merchant: merchant }),
                link && (React.createElement(React.Fragment, null,
                    React.createElement(VerticalSpacer, null),
                    React.createElement(Row, { responsive: true, noGutter: true },
                        React.createElement(Body, { breakWords: true, bold: true }, "Link: ".concat(link)))))),
            React.createElement(VerticalSpacer, null),
            !link ? (React.createElement(React.Fragment, null,
                React.createElement(Row, { gap: Spacing.Medium, responsive: true, noGutter: true },
                    React.createElement(Button.Primary, { onClick: handleClick, loading: isLoadingShare, expanded: true }, strings.generateLink)))) : (React.createElement(React.Fragment, null,
                React.createElement(Row, { gap: Spacing.Medium, responsive: true, noGutter: true },
                    !!merchant.phone && (React.createElement(Column, { flex: 1 },
                        React.createElement(Button.Primary, { onClick: handleShare, disabled: !merchant.phone || !link, loading: isLoadingShare, expanded: true },
                            React.createElement(AppIcon.Whatsapp, null),
                            strings.share))),
                    React.createElement(Column, { flex: 1 },
                        React.createElement(Button.Secondary, { onClick: handleCopy, disabled: !link, loading: isLoadingShare, expanded: true },
                            React.createElement(AppIcon.Copy, null),
                            strings.copy)))))),
        React.createElement(Divider, null),
        React.createElement(WhatsappModal, { opened: visible, onClose: function () { return setVisible(false); }, onRedirect: handleWhatappModalShare, whatsappUrl: whatsappUrlRef.current })));
};
