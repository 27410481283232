import React from 'react';
import { formatPrice } from '@app/utils';
import { ProductOldPrice, ProductPrice } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { HorizontalSpacer, Row } from '@atomic/obj.grid';
export var Price = function (props) {
    var _a;
    var oldPrice = (_a = props.oldPrice) !== null && _a !== void 0 ? _a : 0;
    return (React.createElement(Row, { noGutter: true, alignItems: 'flex-end' }, oldPrice > 0 ? (React.createElement(React.Fragment, null,
        React.createElement(ProductPrice, { isFeatured: true }, formatPrice(props.price)),
        React.createElement(HorizontalSpacer, { "$width": Spacing.Small }),
        React.createElement(ProductOldPrice, null, formatPrice(oldPrice)))) : (React.createElement(ProductPrice, null, formatPrice(props.price)))));
};
