import React from 'react';
import { Divider } from '@atomic/atm.divider';
import { H3 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Row } from '@atomic/obj.grid';
import { CloseIconStyled, ModalCloseStyled } from './modal.component.style';
export var ModalHeading = function (props) { return (React.createElement(React.Fragment, null,
    React.createElement(Separator, { size: Spacing.Small }),
    React.createElement(Row, null,
        React.createElement(H3, null, props.title)),
    React.createElement(ModalCloseStyled, { onClick: props.onClick },
        React.createElement(CloseIconStyled, null)),
    React.createElement(Separator, { size: Spacing.Small }),
    !!props.title && React.createElement(Divider, null))); };
