import React from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { Price } from '@app/components/atm.price';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { StepperField } from '@atomic/atm.stepper-field';
import { Body } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row, VerticalSpacer } from '@atomic/obj.grid';
import { ProductCardImageWrapperStyled, ProductCardTrashButtonStyled, ProductCardWrapperStyled, } from './product-card.component.style';
var strings = AppStrings.components.productCard;
export var ProductCard = function (props) {
    var _a, _b;
    var product = props.product;
    var _c = React.useState((_a = props.initialQuantity) !== null && _a !== void 0 ? _a : 1), quantity = _c[0], setQuantity = _c[1];
    React.useEffect(function () { var _a; return setQuantity((_a = props.initialQuantity) !== null && _a !== void 0 ? _a : 1); }, [props.initialQuantity]);
    var handleQuantityChange = function (qty) {
        var _a;
        setQuantity(qty !== null && qty !== void 0 ? qty : 0);
        (_a = props.onQuantityChange) === null || _a === void 0 ? void 0 : _a.call(props, product, qty !== null && qty !== void 0 ? qty : 0);
    };
    var discountPercentage = getDiscountPercentage(product.price, product.listPrice);
    return (React.createElement(ProductCardWrapperStyled, null,
        React.createElement(Row, { noGutter: true },
            product.thumbnail && (React.createElement(Column, null,
                React.createElement(ProductCardImageWrapperStyled, null,
                    React.createElement(LazyLoadImage, { src: product.thumbnail, alt: '' })))),
            React.createElement(HorizontalSpacer, null),
            React.createElement(Column, { flex: 1 },
                React.createElement(Body, { variant: 'black' }, product.name),
                React.createElement(Body, null, strings.sku + product.sku),
                props.showFixedQuantity && !props.onQuantityChange && !props.onAddToList && (React.createElement(Body, { variant: 'callToAction' }, strings.quantity + props.initialQuantity)),
                React.createElement(VerticalSpacer, { "$height": Spacing.XSmall }),
                discountPercentage && (React.createElement(React.Fragment, null,
                    React.createElement(Badge, { text: strings.discountPercentage(discountPercentage), color: 'primary' }),
                    React.createElement(VerticalSpacer, { "$height": Spacing.Small }))),
                React.createElement(Price, { price: (_b = product.price) !== null && _b !== void 0 ? _b : 0, oldPrice: product.listPrice })),
            props.onRemoveFromList && (React.createElement(React.Fragment, null,
                React.createElement(HorizontalSpacer, null),
                React.createElement(Column, null,
                    React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
                    React.createElement(ProductCardTrashButtonStyled, { onClick: function () { var _a; return (_a = props.onRemoveFromList) === null || _a === void 0 ? void 0 : _a.call(props, product.sku); } },
                        React.createElement(AppIcon.Trash, null),
                        React.createElement("span", { className: 'sr-only' }, strings.removeFromList)))))),
        (props.onQuantityChange || props.onAddToList) && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { alignItems: 'center', noGutter: true },
                React.createElement(Column, { flex: 1 },
                    React.createElement(StepperField, { value: quantity, onValueChange: handleQuantityChange, minValue: 0 })),
                props.onAddToList && (React.createElement(React.Fragment, null,
                    React.createElement(HorizontalSpacer, null),
                    React.createElement(Column, null,
                        React.createElement(Button.Primary, { onClick: function () { var _a; return (_a = props.onAddToList) === null || _a === void 0 ? void 0 : _a.call(props, product, quantity); }, expanded: true },
                            React.createElement(FaIcon.CartPlus, null),
                            strings.addToList)))))))));
};
var getDiscountPercentage = function (price, oldPrice) {
    if (!price || !oldPrice) {
        return;
    }
    return (100 * (1 - price / oldPrice)).toFixed(0);
};
