var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { AppStrings } from '@app/app-strings';
import { MerchantItem } from '@app/components/mol.merchant-item';
import { Placeholder } from '@app/components/mol.placeholder';
import { SelectableList } from '@app/components/org.selectable-list';
import { useMerchantList } from '@app/domain/merchant';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { MerchantListShimmer } from './merchant-list.shimmer.component';
var strings = AppStrings.productsList.merchantsPage;
export var MerchantSelectableList = function (props) {
    var _a, _b;
    var searchTerm = props.searchTerm, page = props.page, onlyMyBase = props.onlyMyBase, filter = props.filter, selected = props.selected, onSelectedChange = props.onSelectedChange, onNextPage = props.onNextPage;
    var _c = useMerchantList({ searchTerm: searchTerm, page: page, onlyMyBase: onlyMyBase, filter: filter }), data = _c.data, isLoading = _c.isLoading, error = _c.error, refetch = _c.refetch;
    var selectedAndSearch = unique(__spreadArray(__spreadArray([], (selected !== null && selected !== void 0 ? selected : []), true), ((_a = data === null || data === void 0 ? void 0 : data.nodes) !== null && _a !== void 0 ? _a : []), true));
    return (React.createElement(LoadingState, { data: !!(data === null || data === void 0 ? void 0 : data.nodes.length), loading: isLoading, error: !!error },
        React.createElement(LoadingState.Shimmer, null,
            React.createElement(Divider, null),
            __spreadArray([], Array(5), true).map(function (_, index) { return (React.createElement(MerchantListShimmer, { key: index, withCheckbox: true })); })),
        React.createElement(LoadingState.NoData, null,
            React.createElement(Placeholder, { title: strings.noDataMessage, icon: 'EmptySearch' })),
        React.createElement(LoadingState.Error, null,
            React.createElement(Placeholder, { title: strings.errorMessage, icon: 'GenericError' },
                React.createElement(Row, { noGutter: true, justifyContent: 'center' },
                    React.createElement(Button.Secondary, { onClick: refetch }, strings.errorButton)))),
        React.createElement(SelectableList, { items: searchTerm ? (_b = data === null || data === void 0 ? void 0 : data.nodes) !== null && _b !== void 0 ? _b : [] : selectedAndSearch, selectedItems: selected, onSelectedChange: onSelectedChange, renderItem: function (item) { return React.createElement(MerchantItem, { merchant: item }); } }),
        (data === null || data === void 0 ? void 0 : data.pageInfo.hasNextPage) && (React.createElement(Row, { justifyContent: 'center' },
            React.createElement(Button.Link, { onClick: onNextPage }, strings.seeMore)))));
};
var unique = function (oldArray) {
    var newArray = [];
    oldArray === null || oldArray === void 0 ? void 0 : oldArray.forEach(function (oldElement) {
        if (newArray.findIndex(function (newElement) { return newElement.id === oldElement.id; }) === -1) {
            newArray.push(oldElement);
        }
    });
    return newArray;
};
