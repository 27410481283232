import React from 'react';
import { AppStrings } from '@app/app-strings';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Accordion } from '@atomic/mol.accordion';
import { Spacing } from '@atomic/obj.constants';
import { VerticalSpacer } from '@atomic/obj.grid';
import { AccountFrameWrapperStyled } from './account-frame.component.style';
export var AccountFrame = function (_a) {
    var visible = _a.visible, onClick = _a.onClick;
    var _b = React.useState(false), focus = _b[0], setFocus = _b[1];
    return (React.createElement(AccountFrameWrapperStyled, { onFocus: function () { return setFocus(true); }, onBlur: function () { return setFocus(false); } },
        React.createElement(Frame, null,
            React.createElement(Accordion, { expanded: visible || focus, noTitle: true },
                React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
                React.createElement(Button.DestructiveLink, { onClick: onClick }, AppStrings.components.navbar.logout),
                React.createElement(VerticalSpacer, { "$height": Spacing.Small })))));
};
