var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
export var LazyLoadImageStyled = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  transition: opacity 0.6s ease;\n  opacity: ", ";\n  height: ", ";\n  max-height: ", ";\n  border-radius: ", ";\n  -webkit-user-drag: none;\n"], ["\n  display: block;\n  width: 100%;\n  transition: opacity 0.6s ease;\n  opacity: ", ";\n  height: ", ";\n  max-height: ", ";\n  border-radius: ", ";\n  -webkit-user-drag: none;\n"])), function (props) { return (props.loaded ? '1' : '0'); }, function (props) { return (props.height ? props.height + 'px' : 'auto'); }, function (props) { return (props.loaded ? 'none' : '0'); }, function (props) { return (props.round ? '50%' : '0'); });
export var LazyLoadPlaceholderStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  top: 50%;\n  background-color: ", ";\n  text-align: center;\n  position: relative;\n  display: flex;\n  justify-content: center;\n"], ["\n  width: 100%;\n  top: 50%;\n  background-color: ", ";\n  text-align: center;\n  position: relative;\n  display: flex;\n  justify-content: center;\n"])), Color.GrayLight);
var placeholderImage = function (height) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", "px;\n  position: absolute;\n  top: calc(50% - ", "px);\n"], ["\n  color: ", ";\n  font-size: ", "px;\n  position: absolute;\n  top: calc(50% - ", "px);\n"])), Color.Gray, height, height / 2); };
export var LazyLoadPlaceholderImageStyled = styled(FaIcon.Image)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), placeholderImage(28));
export var LazyLoadErrorImageStyled = styled(FaIcon.Ban)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), placeholderImage(32));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
