import { trpc } from '@app/core/trpc';
export var useDetailProductList = function (_a) {
    var id = _a.id, skip = _a.skip, onSuccess = _a.onSuccess;
    var response = trpc.productList.detail.useQuery({ id: id }, { onSuccess: onSuccess, enabled: !!id && !skip });
    return {
        data: response.data,
        isLoading: response.isLoading,
        isFetching: response.isFetching,
        error: response.error,
        refetch: response.refetch,
    };
};
