import React from 'react';
import { buttonHeight } from '@atomic/atm.button/button.component.style';
import { ThumbShimmerBoxStyled, TextShimmerBoxStyled, getRandomPercentage } from '@atomic/mol.shimmer';
import { Column, HorizontalSpacer, Row } from '@atomic/obj.grid';
import { ProductCardWrapperStyled } from './product-card.component.style';
export var ProductCardShimmer = function (_a) {
    var withStepper = _a.withStepper;
    return (React.createElement(ProductCardWrapperStyled, null,
        React.createElement(Row, { noGutter: true },
            React.createElement(Column, null,
                React.createElement(ThumbShimmerBoxStyled, { width: '80px', margin: '0' })),
            React.createElement(HorizontalSpacer, null),
            React.createElement(Column, { flex: 1 },
                React.createElement(TextShimmerBoxStyled, { height: '32px', width: '100%', margin: '0' }),
                React.createElement(TextShimmerBoxStyled, { height: '16px', width: getRandomPercentage(20, 50) }),
                React.createElement(TextShimmerBoxStyled, { height: '16px', width: getRandomPercentage(50, 80) }))),
        withStepper && React.createElement(TextShimmerBoxStyled, { height: buttonHeight, width: '100%', margin: '0' })));
};
