var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Border, Color, Spacing } from '@atomic/obj.constants';
export var ProductCardWrapperStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-style: solid;\n  border-width: 1px;\n  border-radius: ", ";\n  border-color: ", ";\n  background-color: ", ";\n  padding: ", ";\n"], ["\n  border-style: solid;\n  border-width: 1px;\n  border-radius: ", ";\n  border-color: ", ";\n  background-color: ", ";\n  padding: ", ";\n"])), Border.Radius, Color.Gray, Color.White, Spacing.Medium);
var IMAGE_WIDTH = 80;
export var ProductCardImageWrapperStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", "px;\n  height: 100%;\n"], ["\n  width: ", "px;\n  height: 100%;\n"])), IMAGE_WIDTH);
export var ProductCardTrashButtonStyled = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n\n  &:focus-visible {\n    ", "\n  }\n"], ["\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n\n  &:focus-visible {\n    ", "\n  }\n"])), clickableFocusCss);
var templateObject_1, templateObject_2, templateObject_3;
