import React, { useState } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TRPCClientError } from '@trpc/client';
import Container from 'typedi';
import { AnalyticsService, AppErrorAnalytics } from '@app/data/analytics';
import { useAuthStorage } from '@app/domain/authentication';
import { trpc, TrpcClient } from './trpc.utils';
export var TrpcProvider = function (_a) {
    var children = _a.children;
    var setAuth = useAuthStorage().setAuth;
    var handleError = function (error) {
        var _a, _b, _c;
        if (!(error instanceof TRPCClientError)) {
            return;
        }
        AnalyticsService.send(AppErrorAnalytics({ message: error.message, type: (_a = error.data) === null || _a === void 0 ? void 0 : _a.name, request: (_b = error.data) === null || _b === void 0 ? void 0 : _b.path }));
        if (((_c = error.data) === null || _c === void 0 ? void 0 : _c.code) === 401) {
            setAuth({ token: undefined });
        }
    };
    var queryClient = useState(function () {
        return new QueryClient({
            queryCache: new QueryCache({ onError: handleError }),
            mutationCache: new MutationCache({ onError: handleError }),
        });
    })[0];
    var trpcClient = useState(function () { return Container.get(TrpcClient); })[0];
    return (React.createElement(trpc.Provider, { client: trpcClient, queryClient: queryClient },
        React.createElement(QueryClientProvider, { client: queryClient }, children)));
};
