import { EventType } from '@app/data/analytics/analytics.model';
export var ViewModalAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'view_modal',
        params: {
            name: params.name,
        },
    };
};
