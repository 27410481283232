import React from 'react';
import { AppStrings } from '@app/app-strings';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { PasswordField } from '@atomic/mol.password-field';
import { Form, Validators } from '@atomic/obj.form';
import { FormFieldCaption } from '@atomic/obj.form/form-field-caption.component';
import { VerticalSpacer } from '@atomic/obj.grid';
var loginFormFields = {
    username: 'username',
    password: 'password',
};
var strings = AppStrings.authentication.loginPage.form;
export var LoginForm = function (props) {
    var handleSubmit = function (result) {
        if (result.hasError) {
            return;
        }
        props.onSubmit(result.data);
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Form.Field, { name: loginFormFields.username, label: strings.username.label, validators: [Validators.Required(strings.errorMessage.required)] },
            React.createElement(TextField, { type: 'text', autoComplete: 'username', placeholder: strings.username.placeholder })),
        React.createElement(FormFieldCaption, { validationPlaceholder: strings.username.caption }),
        React.createElement(VerticalSpacer, null),
        React.createElement(Form.Field, { name: loginFormFields.password, label: strings.password.label, validators: [Validators.Required(strings.errorMessage.required)] },
            React.createElement(PasswordField, { autoComplete: 'current-password', placeholder: strings.password.placeholder })),
        React.createElement(VerticalSpacer, null),
        React.createElement(Button.Primary, { type: 'submit', loading: props.loading, expanded: true }, strings.submit.button)));
};
