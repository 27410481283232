var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Divider } from '@atomic/atm.divider';
import { HorizontalSpacer, Row } from '@atomic/obj.grid';
import { SelectableListItemStyled, SelectableListWrapperStyled } from './selectable-list.component.style';
export var SelectableList = function (props) {
    var _a, _b;
    var handleItemTap = function (id, checked) {
        var newSelected = __spreadArray([], props.selectedItems, true);
        if (checked) {
            var selectedItem = props.items.find(function (item) { return item.id === id; });
            newSelected.push(selectedItem);
        }
        else {
            var selectedItemIndex = newSelected.findIndex(function (item) { return item.id === id; });
            newSelected.splice(selectedItemIndex, 1);
        }
        props.onSelectedChange(newSelected);
    };
    return (React.createElement(SelectableListWrapperStyled, null,
        ((_a = props.items) === null || _a === void 0 ? void 0 : _a.length) > 0 && React.createElement(Divider, null), (_b = props.items) === null || _b === void 0 ? void 0 :
        _b.map(function (item) { return (React.createElement(SelectableListItemStyled, { key: item.id, selected: props.selectedItems.some(function (_a) {
                var id = _a.id;
                return id === item.id;
            }) },
            React.createElement(Row, { noGutter: true },
                React.createElement(HorizontalSpacer, null),
                React.createElement(CheckboxField, { id: item.id, onValueChange: handleItemTap, initialChecked: props.selectedItems.some(function (_a) {
                        var id = _a.id;
                        return id === item.id;
                    }) }, props.renderItem(item))),
            React.createElement(Divider, null))); })));
};
