var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { IcSvgStyled } from '@app/components/atm.app-icon/app-icon.component.style';
export var IcHome = function (props) { return (React.createElement(IcSvgStyled, __assign({ viewBox: '0 0 16 16' }, props),
    React.createElement("path", { d: 'M6.66668 13.6666V9.66665H9.33334V13.6666H12.6667V8.33331H14.6667L8.00001 2.33331L1.33334 8.33331H3.33334V13.6666H6.66668Z', fill: 'currentColor' }))); };
