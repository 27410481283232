import React from 'react';
import { AppStrings } from '@app/app-strings';
import { useMerchantFilterList } from '@app/domain/merchant/merchant-filters.use-case';
import { Button } from '@atomic/atm.button';
import { SelectField } from '@atomic/atm.select';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer';
import { LoadingState } from '@atomic/obj.loading-state';
var strings = AppStrings.productsList.merchantsPage;
export var MerchantFilterSelect = function (_a) {
    var active = _a.active, onFilterChange = _a.onFilterChange;
    var _b = useMerchantFilterList(), data = _b.data, isLoading = _b.isLoading, error = _b.error, refetch = _b.refetch;
    var handleFilter = function (filterId) {
        if (filterId === '') {
            onFilterChange(undefined);
        }
        else {
            onFilterChange(+filterId);
        }
    };
    return (React.createElement(LoadingState, { data: !!(data === null || data === void 0 ? void 0 : data.length), loading: isLoading, error: !!error },
        React.createElement(LoadingState.Shimmer, null,
            React.createElement(TextShimmerBoxStyled, null)),
        React.createElement(LoadingState.Error, null,
            React.createElement(Button.Secondary, { onClick: refetch }, strings.errorButton)),
        active && (data === null || data === void 0 ? void 0 : data.length) && (React.createElement(SelectField, { initialValue: undefined, onValueChange: handleFilter },
            React.createElement("option", { value: '' }, strings.noFilter),
            data.map(function (filterItem, index) { return (React.createElement("option", { key: index, value: filterItem.id }, filterItem.name)); })))));
};
