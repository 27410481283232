import * as React from 'react';
import { createPortal } from 'react-dom';
import { LazyLoadImage } from '@atomic/atm.lazy-load-image';
import { CloseIconStyled, ImageModalBoxStyled, ModalCloseStyled, ModalOpacityStyled, ModalStyled, } from './modal.component.style';
export var ImageModal = function (props) {
    var _a;
    return createPortal(React.createElement(ModalStyled, { opened: props.opened },
        React.createElement(ModalOpacityStyled, { opened: props.opened, onClick: props.onClose }),
        props.opened && (React.createElement(ImageModalBoxStyled, { aspectRatio: props.aspectRatio },
            React.createElement(ModalCloseStyled, { onClick: props.onClose },
                React.createElement(CloseIconStyled, null)),
            React.createElement(LazyLoadImage, { src: (_a = props.src) !== null && _a !== void 0 ? _a : '', aspectRatio: props.aspectRatio })))), document.getElementById('modal'));
};
