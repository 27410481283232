import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { AnalyticsService, SelectContentAnalytics } from '@app/data/analytics';
import { useDraft } from '@app/domain/draft';
import { Distributors } from '@app/model/distributor.model';
import { RadioField } from '@atomic/atm.radio';
import { H1 } from '@atomic/atm.typography';
import { FormField } from '@atomic/obj.form';
import { Column, Grid, Row, VerticalSpacer } from '@atomic/obj.grid';
import { DistributorsWrapperStyled, ResponsiveButton } from './components';
import { NEW_LIST_ID, ProductsListRoutes } from './products-list.routes';
var strings = AppStrings.productsList.distributorsPage;
var DistributorsPage = function () {
    var _a = useDraft(), draftDistributor = _a.distributor, draftId = _a.id, draftTitle = _a.title, updateDistributor = _a.updateDistributor, setDraft = _a.setDraft;
    var _b = React.useState(draftDistributor), selected = _b[0], setSelected = _b[1];
    var navigate = useNavigate();
    var listId = useParams().listId;
    var state = useLocation().state;
    React.useEffect(function () {
        var isPageNewList = listId === NEW_LIST_ID;
        var isDraftNewList = draftId === NEW_LIST_ID;
        if (isPageNewList && !isDraftNewList) {
            setDraft({ id: NEW_LIST_ID });
        }
    }, [draftId, setDraft, listId]);
    var handleSubmit = function () {
        var _a;
        if (selected) {
            AnalyticsService.send(SelectContentAnalytics({ contentType: 'add_dealer', itemId: draftId === null || draftId === void 0 ? void 0 : draftId.toString(), itemName: draftTitle }));
            navigate((_a = state === null || state === void 0 ? void 0 : state.nextRoute) !== null && _a !== void 0 ? _a : ProductsListRoutes.Merchants(listId), {
                state: { nextRoute: ProductsListRoutes.Edit(NEW_LIST_ID) },
                replace: true,
            });
            updateDistributor(selected);
        }
    };
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(H1, null, strings.title),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(ResponsiveButton, { text: strings.submitButton, disabled: !selected, onClick: handleSubmit }))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    React.createElement(DistributorsWrapperStyled, null,
                        React.createElement(FormField, { name: 'distributor', onValueChange: setSelected, initialValue: draftDistributor }, Object.entries(Distributors).map(function (distributor) {
                            return (React.createElement(RadioField, { id: distributor[1], key: distributor[1] }, distributor[0]));
                        }))))),
            React.createElement(VerticalSpacer, null)),
        React.createElement(ResponsiveButton, { text: strings.submitButton, disabled: !selected, onClick: handleSubmit, isSticky: true })));
};
export default DistributorsPage;
