var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useState } from 'react';
import { trpc } from '@app/core/trpc';
var LIMIT = 20;
export var useMerchantList = function (_a) {
    var searchTerm = _a.searchTerm, _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.onlyMyBase, onlyMyBase = _c === void 0 ? false : _c, filter = _a.filter;
    var _d = useState(), data = _d[0], setData = _d[1];
    var pagination = {
        limit: LIMIT,
        offset: (page - 1) * LIMIT,
    };
    var response = trpc.merchant.list.useQuery({ pagination: pagination, searchTerm: searchTerm, onlyMyBase: onlyMyBase, filter: filter }, {
        onSuccess: function (merchantList) {
            if (page === 1) {
                setData(merchantList);
                return;
            }
            setData(function (prev) { var _a; return (__assign(__assign({}, merchantList), { nodes: __spreadArray(__spreadArray([], ((_a = prev === null || prev === void 0 ? void 0 : prev.nodes) !== null && _a !== void 0 ? _a : []), true), merchantList.nodes, true) })); });
        },
    });
    return {
        data: data,
        isLoading: response.isLoading,
        error: response.error,
        refetch: response.refetch,
    };
};
