import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color, IconSize } from '@atomic/obj.constants';
import { ImageModal } from '@atomic/obj.modal';
import { ImageCarouselButtonStyled, ImageCarouselImageStyled, ImageCarouselImageWrapperStyled, ImageCarouselScrollStyled, ImageCarouselStyled, ImageFloatingButtonStyled, ImageFloatingButtonsWrapperStyled, SelectedImageIconStyled, } from './image-carousel.component.style';
export var ImageCarousel = function (props) {
    var _a = React.useState(true), rightButtonVisible = _a[0], setRightButtonVisible = _a[1];
    var _b = React.useState(false), leftButtonVisible = _b[0], setLeftButtonVisible = _b[1];
    var _c = React.useState(), zoomedImage = _c[0], setZoomedImage = _c[1];
    var carouselRef = React.useRef(null);
    var zoomedImageAspectRatio = React.useRef();
    React.useEffect(function () {
        if (!carouselRef.current) {
            return;
        }
        var ref = carouselRef.current;
        var observer = new ResizeObserver(function (entries) {
            handleScrollChange(entries[0].target);
        });
        if (ref) {
            observer.observe(ref);
        }
        return function () {
            observer.unobserve(ref);
        };
    }, [props.imageUrls]);
    var handleRightClick = function () {
        if (!carouselRef.current) {
            return;
        }
        carouselRef.current.scrollLeft += 100;
        handleScrollChange(carouselRef.current);
    };
    var handleLeftClick = function () {
        if (!carouselRef.current) {
            return;
        }
        carouselRef.current.scrollLeft -= 100;
        handleScrollChange(carouselRef.current);
    };
    var handleScrollChange = function (ref) {
        if (!ref) {
            return;
        }
        if (ref.scrollLeft <= 0) {
            setLeftButtonVisible(false);
        }
        else {
            setLeftButtonVisible(true);
        }
        if (ref.scrollLeft + ref.clientWidth >= ref.scrollWidth) {
            setRightButtonVisible(false);
        }
        else {
            setRightButtonVisible(true);
        }
    };
    var handleZoomClick = function (imageUrl, aspectRatio) {
        setZoomedImage(imageUrl);
        zoomedImageAspectRatio.current = aspectRatio;
    };
    var handleZoomModalClose = function () {
        setZoomedImage(undefined);
        zoomedImageAspectRatio.current = undefined;
    };
    return (React.createElement(ImageCarouselStyled, null,
        leftButtonVisible && (React.createElement(ImageCarouselButtonStyled, { left: true, onClick: handleLeftClick },
            React.createElement(FaIcon.ChevronLeft, { size: '2x' }))),
        rightButtonVisible && (React.createElement(ImageCarouselButtonStyled, { onClick: handleRightClick },
            React.createElement(FaIcon.ChevronRight, { size: '2x' }))),
        React.createElement(ImageCarouselScrollStyled, { ref: carouselRef, onScroll: function () { return handleScrollChange(carouselRef.current); } }, props.imageUrls.map(function (imageUrl, index) { return (React.createElement(ImageCarouselItem, { key: index, imageUrl: imageUrl, selected: props.selectedImageIndex === index, onClick: function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, index); }, onZoomClick: handleZoomClick })); })),
        React.createElement(ImageModal, { opened: !!zoomedImage, onClose: handleZoomModalClose, src: zoomedImage, aspectRatio: zoomedImageAspectRatio.current })));
};
var ImageCarouselItem = function (props) {
    var _a = React.useState(false), hovered = _a[0], setHovered = _a[1];
    var imageRef = React.useRef(null);
    var handleZoomClick = function (event) {
        var _a, _b, _c;
        event.stopPropagation();
        var aspectRatio;
        if (imageRef.current) {
            aspectRatio = ((_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.naturalWidth) / ((_b = imageRef.current) === null || _b === void 0 ? void 0 : _b.naturalHeight);
        }
        (_c = props.onZoomClick) === null || _c === void 0 ? void 0 : _c.call(props, props.imageUrl, aspectRatio);
    };
    return (React.createElement(ImageCarouselImageWrapperStyled, { role: 'button', onClick: props.onClick, selected: props.selected, onMouseEnter: function () { return setHovered(true); }, onMouseLeave: function () { return setHovered(false); } },
        React.createElement(ImageFloatingButtonsWrapperStyled, null,
            hovered && props.onZoomClick && (React.createElement(ImageFloatingButtonStyled, { hovered: hovered, onClick: handleZoomClick },
                React.createElement(FaIcon.Zoom, { color: Color.Primary, height: IconSize.Large, width: IconSize.Large }))),
            (hovered || props.selected) && (React.createElement(ImageFloatingButtonStyled, { hovered: hovered },
                React.createElement(SelectedImageIconStyled, { selected: props.selected },
                    React.createElement(FaIcon.Check, { color: props.selected ? Color.White : Color.GrayLight }))))),
        React.createElement(ImageCarouselImageStyled, { src: props.imageUrl, ref: imageRef })));
};
