var _a;
import { EventType } from '@app/data/analytics/analytics.model';
import { PageName } from '@app/model/page-name.model';
export var ScreenViewAnalytics = function (params) {
    var screenName = mapPageNameToFirebase[params.pageName];
    return {
        type: EventType.PAGE_VIEW,
        params: {
            firebase_screen: screenName,
            firebase_screen_class: screenName,
        },
    };
};
var mapPageNameToFirebase = (_a = {},
    _a[PageName.Home] = 'available_lists_page',
    _a[PageName.ListDetails] = 'view_list_page',
    _a[PageName.ListEdit] = 'search_page',
    _a[PageName.ListMerchants] = 'clients_page',
    _a[PageName.ListDistributors] = 'dealers_page',
    _a[PageName.ListPricePreview] = 'cart_view_page',
    _a[PageName.ListShare] = 'share_page',
    _a[PageName.ListMassShare] = 'mass_share_page',
    _a[PageName.Login] = 'login_page',
    _a);
