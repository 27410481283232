var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var pluralize = function (_a) {
    var _b;
    var count = _a.count, options = __rest(_a, ["count"]);
    if (!count && options.empty) {
        return options.empty;
    }
    var plural = (_b = options.plural) !== null && _b !== void 0 ? _b : options.singular + 's';
    return count === 1 ? options.singular : plural;
};
