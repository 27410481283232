var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color, DrawerWidth, HeaderHeight } from '@atomic/obj.constants';
export var DrawerStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: ", ";\n  left: 0;\n  z-index: ", ";\n  transition: ", ";\n  pointer-events: ", ";\n\n  @media (prefers-reduced-motion: reduce) {\n    transition: none;\n  }\n"], ["\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: ", ";\n  left: 0;\n  z-index: ", ";\n  transition: ", ";\n  pointer-events: ", ";\n\n  @media (prefers-reduced-motion: reduce) {\n    transition: none;\n  }\n"])), HeaderHeight.Mobile, function (props) { return (props.active ? '99' : '-1'); }, function (props) { return (props.active ? 'none' : 'z-index .3s .3s'); }, function (props) { return (props.active ? 'initial' : 'none'); });
export var DrawerMenuStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  background-color: ", ";\n  width: ", ";\n  height: 100%;\n  left: ", ";\n  top: 0;\n  -webkit-overflow-scrolling: touch;\n  -ms-overflow-style: -ms-autohiding-scrollbar;\n  overscroll-behavior: contain;\n  transition: ", ";\n  overflow: hidden;\n  z-index: ", ";\n\n  @media (prefers-reduced-motion: reduce) {\n    transition: none;\n  }\n"], ["\n  position: absolute;\n  background-color: ", ";\n  width: ", ";\n  height: 100%;\n  left: ", ";\n  top: 0;\n  -webkit-overflow-scrolling: touch;\n  -ms-overflow-style: -ms-autohiding-scrollbar;\n  overscroll-behavior: contain;\n  transition: ", ";\n  overflow: hidden;\n  z-index: ", ";\n\n  @media (prefers-reduced-motion: reduce) {\n    transition: none;\n  }\n"])), Color.GrayXLight, DrawerWidth, function (props) { return (props.active ? '0px' : "-".concat(DrawerWidth)); }, function (props) { return (props.active ? 'left .3s' : 'left .3s, z-index .3s .3s'); }, function (props) { return (props.active ? '2' : 'initial'); });
export var DrawerOverlayStyled = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  right: 0;\n  top: 0;\n  background-color: ", ";\n  opacity: ", ";\n  z-index: ", ";\n  pointer-events: ", ";\n  transition: ", ";\n\n  @media (prefers-reduced-motion: reduce) {\n    transition: none;\n  }\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  right: 0;\n  top: 0;\n  background-color: ", ";\n  opacity: ", ";\n  z-index: ", ";\n  pointer-events: ", ";\n  transition: ", ";\n\n  @media (prefers-reduced-motion: reduce) {\n    transition: none;\n  }\n"])), Color.Black, function (props) { return (props.active ? '.3' : '0'); }, function (props) { return (props.active ? '1' : '-1'); }, function (props) { return (props.active ? 'initial' : 'none'); }, function (props) { return (props.active ? 'opacity .3s ease-out' : 'opacity .3s ease-out, z-index .3s .3s'); });
var templateObject_1, templateObject_2, templateObject_3;
