import { useCallback, useEffect, useState } from 'react';
export var ScrollState;
(function (ScrollState) {
    ScrollState[ScrollState["Initial"] = 0] = "Initial";
    ScrollState[ScrollState["Moved"] = 1] = "Moved";
    ScrollState[ScrollState["ReachedEnd"] = 2] = "ReachedEnd";
})(ScrollState || (ScrollState = {}));
export var useScrollEvents = function () {
    var _a = useState(ScrollState.Initial), scrollState = _a[0], setScrollState = _a[1];
    var handleScroll = useCallback(function () {
        if (window.scrollY === 0) {
            setScrollState(ScrollState.Initial);
            return;
        }
        var scrollMaxHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
        var isAtTheEndOfScroll = window.scrollY + window.innerHeight > 0.95 * scrollMaxHeight;
        if (isAtTheEndOfScroll) {
            setScrollState(ScrollState.ReachedEnd);
            return;
        }
        setScrollState(ScrollState.Moved);
    }, []);
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, [handleScroll]);
    return { scrollState: scrollState };
};
