var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from 'styled-components';
import { Border, Spacing } from '@atomic/obj.constants';
var shimmerAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    background-position: -1000px 0;\n  }\n  100% {\n    background-position: 1000px 0;\n  }\n"], ["\n  0% {\n    background-position: -1000px 0;\n  }\n  100% {\n    background-position: 1000px 0;\n  }\n"])));
export var BaseShimmerBoxStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: linear-gradient(90deg, #f5f5f5 8%, #eaeaea 38%, #f5f5f5 60%);\n  background-size: 1000px;\n  position: relative;\n  border-radius: ", ";\n  @media (prefers-reduced-motion: no-preference) {\n    animation: ", " 3s linear infinite;\n    animation-fill-mode: forwards;\n  }\n"], ["\n  background: linear-gradient(90deg, #f5f5f5 8%, #eaeaea 38%, #f5f5f5 60%);\n  background-size: 1000px;\n  position: relative;\n  border-radius: ", ";\n  @media (prefers-reduced-motion: no-preference) {\n    animation: ", " 3s linear infinite;\n    animation-fill-mode: forwards;\n  }\n"])), Border.Radius, shimmerAnimation);
export var textShimmerType = function (width, height, margin) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  margin: ", ";\n"], ["\n  height: ", ";\n  width: ", ";\n  margin: ", ";\n"])), height, width, margin || "".concat(Spacing.Small, " 0")); };
export var TextShimmerBoxStyled = styled(BaseShimmerBoxStyled)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) { return textShimmerType(props.width ? props.width : '100%', props.height, props.margin); });
export var thumbShimmerType = function (x, y, margin) { return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n"])), x, y ? y : x, margin || 'auto'); };
export var ThumbShimmerBoxStyled = styled(BaseShimmerBoxStyled)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) { return thumbShimmerType(props.width ? props.width : '100%', props.height, props.margin); });
export var ShimmerAspectRatioBoxStyled = styled(BaseShimmerBoxStyled)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  padding-bottom: ", "%;\n"], ["\n  width: 100%;\n  padding-bottom: ", "%;\n"])), function (props) { return props.ratioPercentage; });
export var ShimmerCircleStyled = styled(BaseShimmerBoxStyled)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: ", "px;\n  width: ", "px;\n  border-radius: ", "px;\n"], ["\n  height: ", "px;\n  width: ", "px;\n  border-radius: ", "px;\n"])), function (props) { return 2 * props.radius; }, function (props) { return 2 * props.radius; }, function (props) { return props.radius; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
