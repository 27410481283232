var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
export var TabItemStyled = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  display: flex;\n  align-items: center;\n  border-radius: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n\n  & + & {\n    margin-left: ", ";\n  }\n"], ["\n  list-style: none;\n  display: flex;\n  align-items: center;\n  border-radius: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n\n  & + & {\n    margin-left: ", ";\n  }\n"])), Border.Radius, Color.GrayLight, function (_a) {
    var active = _a.active;
    return active && "background-color: ".concat(Color.Accessory, ";");
}, Spacing.Small);
export var TabItemTextStyled = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: none;\n  background: none;\n  text-decoration: none;\n  display: inline-block;\n  position: relative;\n  color: ", ";\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  cursor: pointer;\n\n  &:focus-visible {\n    border-radius: ", ";\n    ", "\n  }\n"], ["\n  border: none;\n  background: none;\n  text-decoration: none;\n  display: inline-block;\n  position: relative;\n  color: ", ";\n  padding: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  cursor: pointer;\n\n  &:focus-visible {\n    border-radius: ", ";\n    ", "\n  }\n"])), Color.Primary, Spacing.Medium, FontFamily.Primary, FontWeight.Medium, FontSize.Small, Border.Radius, clickableFocusCss);
export var TabStyled = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin: 0;\n  padding: 0;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin: 0;\n  padding: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
