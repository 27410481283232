var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Container from 'typedi';
import { AuthStorageService, DraftStorageService } from '@app/data/storage';
export var useDraftStorage = function () {
    var _a, _b, _c;
    var draftStorage = Container.get(DraftStorageService);
    var authStorage = Container.get(AuthStorageService);
    var filteredDraft = ((_a = draftStorage.draft) === null || _a === void 0 ? void 0 : _a.userId) === ((_c = (_b = authStorage.auth) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.id) ? draftStorage.draft : null;
    var _d = React.useState(filteredDraft), draft = _d[0], setDraft = _d[1];
    var setDraftLocalStorage = function (newDraft) {
        var _a;
        var userId = (_a = authStorage.auth.user) === null || _a === void 0 ? void 0 : _a.id;
        if (!userId) {
            return;
        }
        var draftWithUserId = null;
        if (newDraft !== null) {
            draftWithUserId = __assign({ userId: userId }, newDraft);
        }
        draftStorage.draft = draftWithUserId;
        setDraft(draftWithUserId);
    };
    var handleStorageChange = React.useCallback(function (newDraft) { return setDraft(newDraft); }, [setDraft]);
    React.useEffect(function () {
        draftStorage.addObserver(handleStorageChange);
        return function () { return draftStorage.removeObserver(handleStorageChange); };
    }, [draftStorage, handleStorageChange]);
    return { draft: draft, setDraft: setDraftLocalStorage };
};
