var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styled from 'styled-components';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Divider } from '@atomic/atm.divider';
import { TextShimmerBoxStyled, getRandomPercentage } from '@atomic/mol.shimmer';
import { Color, Spacing } from '@atomic/obj.constants';
import { Column, Row } from '@atomic/obj.grid';
export var MerchantListShimmer = function (_a) {
    var withCheckbox = _a.withCheckbox;
    return (React.createElement(React.Fragment, null,
        React.createElement(MerchantListShimmerStyled, null, withCheckbox ? (React.createElement(CheckboxField, { id: 1, checked: false },
            React.createElement(MerchantItemShimmer, null))) : (React.createElement(MerchantItemShimmer, null))),
        React.createElement(Divider, null)));
};
var MerchantItemShimmer = function () { return (React.createElement(Row, null,
    React.createElement(Column, null,
        React.createElement(TextShimmerBoxStyled, { width: '200px', height: '20px' }),
        __spreadArray([], Array(2), true).map(function (_, index) { return (React.createElement(TextShimmerBoxStyled, { key: index, width: getRandomPercentage(50, 70), height: '14px' })); })))); };
var MerchantListShimmerStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n"])), Color.White, Spacing.Medium);
var templateObject_1;
