var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { IcSvgStyled } from '@app/components/atm.app-icon/app-icon.component.style';
export var IcWhatsapp = function (props) { return (React.createElement(IcSvgStyled, __assign({ viewBox: '0 0 17 16' }, props),
    React.createElement("g", { clipPath: 'url(#clip0_269_3386)' },
        React.createElement("path", { d: 'M14.4184 2.32513C12.9143 0.826621 10.9143 0.000914274 8.78344 0C4.39248 0 0.819126 3.5565 0.817368 7.92794C0.816856 9.32529 1.18358 10.6893 1.88062 11.8917L0.750488 16L4.9734 14.8976C6.13689 15.5293 7.4469 15.8622 8.78002 15.8627H8.7834H8.78344C13.1737 15.8627 16.7476 12.3056 16.7495 7.93437C16.7502 5.81576 15.9223 3.82374 14.4184 2.32513ZM8.78344 14.5237H8.78086C7.59259 14.523 6.42732 14.2054 5.41093 13.605L5.16905 13.4622L2.66309 14.1163L3.33215 11.6847L3.17455 11.4354C2.51172 10.3861 2.16181 9.17355 2.1623 7.92845C2.16376 4.29505 5.1338 1.33911 8.78595 1.33911C10.5545 1.33984 12.2168 2.02617 13.4669 3.27169C14.717 4.51719 15.4049 6.17292 15.4044 7.93381C15.4028 11.5673 12.4327 14.5237 8.78344 14.5237Z', fill: 'white' }),
        React.createElement("path", { d: 'M1.11621 15.5441L2.19472 11.6235C1.52935 10.476 1.17944 9.1742 1.17986 7.84094C1.18161 3.66927 4.59177 0.27533 8.78179 0.27533C10.8154 0.276244 12.7241 1.06419 14.1591 2.49429C15.5945 3.92437 16.3845 5.82526 16.3837 7.84686C16.382 12.0186 12.9716 15.4129 8.78209 15.4129C8.78179 15.4129 8.7823 15.4129 8.78209 15.4129H8.7787C7.50648 15.4125 6.25641 15.0945 5.14614 14.4921L1.11621 15.5441Z', fill: 'url(#paint0_linear_269_3386)' }),
        React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M6.81494 4.56212C6.66756 4.23589 6.51239 4.22936 6.37218 4.22366C6.2575 4.21876 6.1262 4.21906 5.99512 4.21906C5.86393 4.21906 5.65075 4.26814 5.47047 4.46416C5.29009 4.66018 4.78174 5.13405 4.78174 6.09781C4.78174 7.06168 5.48687 7.993 5.58516 8.1238C5.68355 8.25441 6.94634 10.2954 8.94623 11.0806C10.6083 11.7331 10.9465 11.6033 11.3073 11.5707C11.6681 11.538 12.4714 11.0969 12.6354 10.6395C12.7994 10.1821 12.7994 9.79011 12.7502 9.70816C12.701 9.62653 12.5698 9.57755 12.373 9.47959C12.1762 9.38164 11.2089 8.90766 11.0285 8.84236C10.8481 8.77705 10.717 8.7444 10.5858 8.94053C10.4546 9.13644 10.0777 9.57755 9.96293 9.70816C9.84814 9.83907 9.73336 9.8554 9.53657 9.75744C9.33979 9.65918 8.70609 9.45254 7.95433 8.7852C7.36942 8.26603 6.97452 7.62481 6.85973 7.42869C6.74494 7.23278 6.84742 7.12666 6.94613 7.029C7.03447 6.94124 7.14291 6.80033 7.2413 6.68594C7.33948 6.57155 7.37228 6.48992 7.43787 6.35931C7.50346 6.22849 7.47067 6.11411 7.42147 6.01615C7.37228 5.9182 6.98989 4.94946 6.81494 4.56212Z', fill: 'white' }),
        React.createElement("path", { d: 'M14.3533 2.29991C12.8665 0.818604 10.8894 0.00239218 8.78304 0.00146484C4.44247 0.00146484 0.910135 3.51713 0.908391 7.8384C0.907879 9.21972 1.27039 10.5681 1.95943 11.7566L0.842285 15.8178L5.01672 14.728C6.16687 15.3525 7.46184 15.6816 8.77966 15.6821H8.783H8.78304C13.1229 15.6821 16.6557 12.1658 16.6576 7.84472C16.6583 5.75047 15.8399 3.78132 14.3533 2.29991ZM8.78304 14.3584H8.78048C7.60584 14.3578 6.45395 14.0438 5.44923 13.4503L5.21012 13.3091L2.73292 13.9558L3.39429 11.552L3.23851 11.3056C2.58329 10.2684 2.23739 9.06971 2.2379 7.83892C2.23933 4.24723 5.17527 1.32523 8.78551 1.32523C10.5338 1.32595 12.1769 2.0044 13.4127 3.23562C14.6485 4.46683 15.3285 6.10354 15.328 7.84423C15.3264 11.436 12.3904 14.3584 8.78304 14.3584Z', fill: 'white' })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: 'paint0_linear_269_3386', x1: '8.74996', y1: '15.5441', x2: '8.74996', y2: '0.275355', gradientUnits: 'userSpaceOnUse' },
            React.createElement("stop", { stopColor: '#20B038' }),
            React.createElement("stop", { offset: '1', stopColor: '#60D66A' })),
        React.createElement("clipPath", { id: 'clip0_269_3386' },
            React.createElement("rect", { width: '16', height: '16', fill: 'white', transform: 'translate(0.75)' }))))); };
IcWhatsapp.defaultProps = { size: 'large' };
