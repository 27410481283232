import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { AccordionCollapsibleStyled } from './accordion.component.style';
export var Accordion = function (props) {
    var _a = React.useState(0), currentHeight = _a[0], setCurrentHeight = _a[1];
    var measureElement = function (element) {
        var values = element === null || element === void 0 ? void 0 : element.getBoundingClientRect();
        if ((values === null || values === void 0 ? void 0 : values.height) && (values === null || values === void 0 ? void 0 : values.height) !== currentHeight) {
            setCurrentHeight(values.height);
        }
    };
    return (React.createElement(React.Fragment, null,
        !props.trailing && !props.noTitle && (React.createElement(React.Fragment, null,
            React.createElement(Button.Link, { onClick: props.onClick }, props.expanded ? props.openedTitle : props.closedTitle),
            React.createElement(Separator, { size: Spacing.Small }))),
        React.createElement(AccordionCollapsibleStyled, { expanded: props.expanded, contentHeight: currentHeight },
            React.createElement("div", { ref: measureElement }, props.children)),
        props.trailing && !props.noTitle && (React.createElement(React.Fragment, null,
            props.expanded && React.createElement(Separator, { size: Spacing.Small }),
            React.createElement(Button.Link, { onClick: props.onClick }, props.expanded ? props.openedTitle : props.closedTitle)))));
};
