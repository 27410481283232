var _a;
import React from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { ColumnMaxWidthWrapper } from '@app/components/atm.page-wrapper';
import { ListTabs } from '@app/model';
import { Divider } from '@atomic/atm.divider';
import { Tab } from '@atomic/mol.tab';
import { Spacing } from '@atomic/obj.constants';
import { HorizontalSpacer, Row, VerticalSpacer } from '@atomic/obj.grid';
import { ListTabWrapperStyled } from './list-tab.component.style';
var strings = AppStrings.home.listTab.tabs;
export var ListTab = function (_a) {
    var onTabChange = _a.onTabChange;
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null),
        React.createElement(ListTabWrapperStyled, null,
            React.createElement(ColumnMaxWidthWrapper, { gutter: true },
                React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
                React.createElement(Tab, { onIndexChanged: function (index) { return onTabChange(tabIndex(index)); } }, Object.keys(ListTabs).map(function (tab) { return (React.createElement(Tab.Item, { key: tab },
                    React.createElement(Row, { alignItems: 'center', noGutter: true },
                        tab === ListTabs.MadeByMe && (React.createElement(React.Fragment, null,
                            React.createElement(AppIcon.User, null),
                            React.createElement(HorizontalSpacer, { "$width": Spacing.Small }))),
                        tabNameMapper[tab]))); })),
                React.createElement(VerticalSpacer, { "$height": Spacing.Small }))),
        React.createElement(Divider, null)));
};
var tabNameMapper = (_a = {},
    _a[ListTabs.Suggestion] = strings.suggestion,
    _a[ListTabs.MadeByMe] = strings.madeByMe,
    _a);
var tabIndex = function (index) { return Object.values(ListTabs)[index]; };
