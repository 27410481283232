var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color, IconSize } from '@atomic/obj.constants';
export var IcDefaultProps = { size: 'medium' };
export var IcColors = {
    primary: Color.Primary,
    primaryLight: Color.PrimaryLight,
};
export var IcSizes = {
    medium: IconSize.Medium,
    large: IconSize.Large,
};
export var IcSvgStyled = styled.svg.attrs(function (props) {
    var _a, _b;
    return ({
        width: IcSizes[(_a = props.size) !== null && _a !== void 0 ? _a : IcDefaultProps.size],
        height: IcSizes[(_b = props.size) !== null && _b !== void 0 ? _b : IcDefaultProps.size],
        fill: 'transparent',
        stroke: 'transparent',
    });
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  vertical-align: middle;\n"], ["\n  ", "\n  vertical-align: middle;\n"])), function (props) { return props.variant && "color: ".concat(IcColors[props.variant], ";"); });
var templateObject_1;
