var _a, _b;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticsService, ScreenViewAnalytics } from '@app/data/analytics';
import { PageName } from '@app/model/page-name.model';
import { AuthenticationRoutes } from '@app/modules/authentication/authentication.routes';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { ProductsListBase, ProductsListRoutesPathEnd } from '@app/modules/products-list/products-list.routes';
export var usePageViewAnalytics = function () {
    var location = useLocation();
    React.useEffect(function () {
        var pageName = mapPathToPageName(location.pathname);
        if (pageName) {
            AnalyticsService.send(ScreenViewAnalytics({ pageName: pageName }));
        }
    }, [location]);
};
var mapPathToPageName = function (pathname) {
    if (pathname.match("^/".concat(ProductsListBase))) {
        var pathEnd = pathname.split('/').at(-1);
        if (!pathEnd) {
            return;
        }
        return mapListPathToPageName[pathEnd];
    }
    return mapOtherPathToPageName[pathname];
};
var mapOtherPathToPageName = (_a = {},
    _a[AuthenticationRoutes.Base] = PageName.Login,
    _a[HomeRoutes.Base] = PageName.Home,
    _a);
var mapListPathToPageName = (_b = {},
    _b[ProductsListRoutesPathEnd.Details] = PageName.ListDetails,
    _b[ProductsListRoutesPathEnd.Edit] = PageName.ListEdit,
    _b[ProductsListRoutesPathEnd.Merchants] = PageName.ListMerchants,
    _b[ProductsListRoutesPathEnd.Distributors] = PageName.ListDistributors,
    _b[ProductsListRoutesPathEnd.PricePreview] = PageName.ListPricePreview,
    _b[ProductsListRoutesPathEnd.Share] = PageName.ListShare,
    _b[ProductsListRoutesPathEnd.MassShare] = PageName.ListMassShare,
    _b);
