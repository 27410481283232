var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { httpBatchLink } from '@trpc/react-query';
import Container, { Service } from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { trpc } from './trpc.utils';
var TrpcClientBuilderService = /** @class */ (function () {
    function TrpcClientBuilderService() {
    }
    // eslint-disable-next-line deprecation/deprecation
    TrpcClientBuilderService.prototype.build = function (url) {
        var trpcClient = trpc.createClient({
            links: [
                httpBatchLink({
                    url: url + '/trpc',
                    headers: function () {
                        var authStorage = Container.get(AuthStorageService);
                        return {
                            authorization: authStorage === null || authStorage === void 0 ? void 0 : authStorage.auth.token,
                        };
                    },
                }),
            ],
        });
        return trpcClient;
    };
    TrpcClientBuilderService = __decorate([
        Service()
    ], TrpcClientBuilderService);
    return TrpcClientBuilderService;
}());
export { TrpcClientBuilderService };
