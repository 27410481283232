import { EventType } from '@app/data/analytics/analytics.model';
import { getCartValue, mapProductToAnalytics } from './item.mapper';
export var CreateLinkAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'create_link',
        params: {
            currency: 'BRL',
            value: getCartValue(params.products),
            items: params.products.map(mapProductToAnalytics),
            item_id: params.itemId,
            client: params.client,
        },
    };
};
