var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { IcSvgStyled } from '@app/components/atm.app-icon/app-icon.component.style';
export var IcShare = function (props) { return (React.createElement(IcSvgStyled, __assign({ viewBox: '0 0 25 24' }, props),
    React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M16.54 16.89C17.06 16.42 17.74 16.12 18.5 16.12C20.11 16.12 21.42 17.43 21.42 19.04C21.42 20.65 20.11 21.96 18.5 21.96C16.89 21.96 15.58 20.65 15.58 19.04C15.58 18.82 15.61 18.6 15.66 18.39L8.54 14.23C8 14.73 7.29 15.04 6.5 15.04C4.84 15.04 3.5 13.7 3.5 12.04C3.5 10.38 4.84 9.04004 6.5 9.04004C7.29 9.04004 8 9.35004 8.54 9.85004L15.59 5.74004C15.54 5.51004 15.5 5.28004 15.5 5.04004C15.5 3.38004 16.84 2.04004 18.5 2.04004C20.16 2.04004 21.5 3.38004 21.5 5.04004C21.5 6.70004 20.16 8.04004 18.5 8.04004C17.71 8.04004 17 7.73004 16.46 7.23004L9.41 11.34C9.46 11.57 9.5 11.8 9.5 12.04C9.5 12.28 9.46 12.51 9.41 12.74L16.54 16.89ZM19.5 5.04004C19.5 4.49004 19.05 4.04004 18.5 4.04004C17.95 4.04004 17.5 4.49004 17.5 5.04004C17.5 5.59004 17.95 6.04004 18.5 6.04004C19.05 6.04004 19.5 5.59004 19.5 5.04004ZM6.5 13.04C5.95 13.04 5.5 12.59 5.5 12.04C5.5 11.49 5.95 11.04 6.5 11.04C7.05 11.04 7.5 11.49 7.5 12.04C7.5 12.59 7.05 13.04 6.5 13.04ZM17.5 19.06C17.5 19.61 17.95 20.06 18.5 20.06C19.05 20.06 19.5 19.61 19.5 19.06C19.5 18.51 19.05 18.06 18.5 18.06C17.95 18.06 17.5 18.51 17.5 19.06Z', fill: 'currentColor' }))); };
IcShare.defaultProps = { size: 'large' };
