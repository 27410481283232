var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getListPrice } from '@app/utils';
import { useDraftStorage } from './draft-storage.use-case';
export var useDraft = function () {
    var _a, _b, _c;
    var _d = useDraftStorage(), draft = _d.draft, setDraft = _d.setDraft;
    var totalPrice = getListPrice((_a = draft === null || draft === void 0 ? void 0 : draft.items) !== null && _a !== void 0 ? _a : []);
    var addProducts = function (newItem, quantity) {
        var _a;
        if (!draft) {
            return;
        }
        var currentItems = __spreadArray([], ((_a = draft.items) !== null && _a !== void 0 ? _a : []), true);
        var index = currentItems.findIndex(function (item) { return item.sku === newItem.sku; });
        if (index === -1) {
            currentItems.push(__assign(__assign({}, newItem), { quantity: quantity }));
        }
        else {
            currentItems[index] = __assign(__assign({}, currentItems[index]), { quantity: currentItems[index].quantity + quantity });
        }
        setDraft(__assign(__assign({}, draft), { items: currentItems }));
    };
    var changeProductsQuantity = function (newItem, quantity) {
        var _a;
        if (!draft) {
            return;
        }
        var currentItems = __spreadArray([], ((_a = draft.items) !== null && _a !== void 0 ? _a : []), true);
        var index = currentItems.findIndex(function (item) { return item.sku === newItem.sku; });
        if (index === -1) {
            return;
        }
        currentItems[index] = __assign(__assign({}, currentItems[index]), { quantity: quantity });
        setDraft(__assign(__assign({}, draft), { items: currentItems }));
    };
    var removeProducts = function (sku) {
        var _a;
        if (!draft) {
            return;
        }
        var currentItems = __spreadArray([], ((_a = draft.items) !== null && _a !== void 0 ? _a : []), true);
        var index = currentItems.findIndex(function (item) { return item.sku === sku; });
        if (index === -1) {
            return;
        }
        currentItems.splice(index, 1);
        setDraft(__assign(__assign({}, draft), { items: currentItems }));
    };
    var updateMerchants = function (merchants) {
        if (!draft) {
            return;
        }
        setDraft(__assign(__assign({}, draft), { merchants: merchants }));
    };
    var updateDistributor = function (distributor) {
        if (!draft) {
            return;
        }
        var items = draft.distributor && draft.distributor !== distributor ? [] : draft.items;
        setDraft(__assign(__assign({}, draft), { distributor: distributor, items: items }));
    };
    var clearDraft = function () { return setDraft(null); };
    var setTitle = function (title) {
        if (!draft) {
            return;
        }
        setDraft(__assign(__assign({}, draft), { title: title }));
    };
    return {
        items: (_b = draft === null || draft === void 0 ? void 0 : draft.items) !== null && _b !== void 0 ? _b : [],
        merchants: (_c = draft === null || draft === void 0 ? void 0 : draft.merchants) !== null && _c !== void 0 ? _c : [],
        distributor: draft === null || draft === void 0 ? void 0 : draft.distributor,
        hasDraft: !!(draft === null || draft === void 0 ? void 0 : draft.items) || !!(draft === null || draft === void 0 ? void 0 : draft.merchants),
        id: draft === null || draft === void 0 ? void 0 : draft.id,
        title: draft === null || draft === void 0 ? void 0 : draft.title,
        totalPrice: totalPrice,
        addProducts: addProducts,
        changeProductsQuantity: changeProductsQuantity,
        removeProducts: removeProducts,
        updateMerchants: updateMerchants,
        updateDistributor: updateDistributor,
        setTitle: setTitle,
        clearDraft: clearDraft,
        setDraft: setDraft,
    };
};
