var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ColorFunc from 'color';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
var btnColors = {
    primary: { bg: Color.Primary, border: Color.Primary, text: Color.White },
    secondary: { bg: 'transparent', border: Color.Primary, text: Color.Primary },
    callToAction: { bg: Color.CallToAction, border: Color.CallToAction, text: Color.Black },
    destructive: { bg: Color.ErrorLight, border: Color.ErrorDark, text: Color.ErrorDark },
    link: { bg: 'transparent', border: 'transparent', text: Color.Primary },
    destructiveLink: { bg: 'transparent', border: 'transparent', text: Color.Error },
};
export var buttonHeight = '44px';
export var ButtonStyledCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  display: inline-block;\n  min-height: ", ";\n  line-height: ", ";\n  padding: 0 ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  text-decoration: none;\n  text-align: ", ";\n  ", "\n  cursor: ", ";\n  opacity: ", ";\n\n  /* this is useful when its father is pointer-events: none */\n  pointer-events: auto;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-style: solid;\n  border-width: ", ";\n  border-radius: ", ";\n\n  &:focus-visible {\n    ", "\n  }\n\n  ", "\n"], ["\n  position: relative;\n  width: ", ";\n  display: inline-block;\n  min-height: ", ";\n  line-height: ", ";\n  padding: 0 ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  text-decoration: none;\n  text-align: ", ";\n  ", "\n  cursor: ", ";\n  opacity: ", ";\n\n  /* this is useful when its father is pointer-events: none */\n  pointer-events: auto;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n  border-style: solid;\n  border-width: ", ";\n  border-radius: ", ";\n\n  &:focus-visible {\n    ", "\n  }\n\n  ", "\n"])), function (props) { return (props.expanded ? '100%' : 'auto'); }, buttonHeight, buttonHeight, Spacing.Large, FontFamily.Primary, FontWeight.Bold, FontSize.Medium, function (props) { return (props.expanded ? 'center' : 'left'); }, highlightStyle, function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) { return (props.disabled || props.loading ? 0.5 : 1); }, function (props) { return btnColors[props.variant].text; }, function (props) { return btnColors[props.variant].bg; }, function (props) { return btnColors[props.variant].border; }, Border.Width, Border.RadiusLarge, clickableFocusCss, function (props) {
    return !props.disabled &&
        "\n    &:hover {\n      color: ".concat(ColorFunc(btnColors[props.variant].text).darken(0.2).hsl().string(), ";\n      background-color: ").concat(ColorFunc(btnColors[props.variant].bg).darken(0.1).hsl().string(), ";\n      ").concat(['link', 'destructiveLink'].includes(props.variant) && "background-color: ".concat(Color.GrayLight, ";"), "\n    }\n\n    &:active {\n      color: ").concat(ColorFunc(btnColors[props.variant].text).darken(0.3).hsl().string(), ";\n      background-color: ").concat(ColorFunc(btnColors[props.variant].bg).darken(0.2).hsl().string(), ";\n    }\n  ");
});
export var LinkButtonStyled = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonStyledCss);
export var AnchorButtonStyled = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonStyledCss);
export var ButtonStyled = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ButtonStyledCss);
export var ButtonContentStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  transition: all 0.2s ease-in-out;\n  opacity: ", ";\n  font-stretch: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: ", ";\n"], ["\n  transition: all 0.2s ease-in-out;\n  opacity: ", ";\n  font-stretch: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: ", ";\n"])), function (props) { return (props.processing ? 0 : 1); }, Spacing.Small);
export var ButtonSpinnerStyled = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: ", " !important;\n  position: absolute;\n  right: calc(50% - 7px);\n  top: 0;\n"], ["\n  display: ", " !important;\n  position: absolute;\n  right: calc(50% - 7px);\n  top: 0;\n"])), function (props) { return (props.processing ? 'inline-block' : 'none'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
