import React from 'react';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { H1 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { Row, VerticalSpacer } from '@atomic/obj.grid';
import { EditTitleButtonStyled } from './editable-title.component.style';
var strings = AppStrings.productsList.editableTitle;
var formFields = {
    title: 'title',
};
export var EditableTitle = function (_a) {
    var title = _a.title, onChange = _a.onChange;
    var _b = React.useState(false), editing = _b[0], setEditing = _b[1];
    var handleSubmit = function (formData) {
        if (formData.hasError) {
            return;
        }
        setEditing(false);
        if (formData.data.title === title) {
            return;
        }
        onChange(formData.data.title);
    };
    return (React.createElement(React.Fragment, null, editing ? (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Form.Field, { name: formFields.title, validators: [Validators.Required(strings.requiredField)], initialValue: title },
            React.createElement(TextField, { dismissable: true })),
        React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
        React.createElement(Row, { noGutter: true, gap: Spacing.Small, justifyContent: 'flex-end' },
            React.createElement(Button.Link, { onClick: function () { return setEditing(false); } }, strings.cancel),
            React.createElement(Button.Primary, { type: 'submit' }, strings.change)))) : (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, null,
            React.createElement(H1, null, title)),
        React.createElement(Hbox.Separator, null),
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
            React.createElement(EditTitleButtonStyled, { onClick: function () { return setEditing(true); } },
                React.createElement(AppIcon.Edit, null)))))));
};
