import React from 'react';
import { Button } from '@atomic/atm.button';
import { ResponsiveContentFooterStyled, ResponsiveContentStaticStyled } from './responsive-button.component.style';
export var ResponsiveButton = function (props) {
    var _a;
    return (React.createElement(ResponsiveContent, { isSticky: (_a = props.isSticky) !== null && _a !== void 0 ? _a : false },
        React.createElement(Button.CallToAction, { onClick: props.onClick, disabled: props.disabled, loading: props.loading, expanded: true }, props.text)));
};
var ResponsiveContent = function (_a) {
    var isSticky = _a.isSticky, children = _a.children;
    return isSticky ? (React.createElement(ResponsiveContentFooterStyled, null, children)) : (React.createElement(ResponsiveContentStaticStyled, null, children));
};
