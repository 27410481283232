export var formatPhone = function (phone) {
    if (phone.length === 10) {
        return phone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
    }
    if (phone.length === 11) {
        return phone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
    }
    if (phone.length === 12) {
        return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/g, '+$1 ($2) $3-$4');
    }
    if (phone.length === 13) {
        return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 ($2) $3-$4');
    }
    return phone;
};
