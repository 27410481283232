var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';
export var SelectableListWrapperStyled = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0;\n  margin: 0;\n"], ["\n  padding: 0;\n  margin: 0;\n"])));
export var SelectableListItemStyled = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style: none;\n  background-color: ", ";\n"], ["\n  list-style: none;\n  background-color: ", ";\n"])), function (_a) {
    var selected = _a.selected;
    return (selected ? Color.Accessory : Color.White);
});
var templateObject_1, templateObject_2;
