import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { AnalyticsService, LogoutAnalytics } from '@app/data/analytics';
import { useAuthStorage } from '@app/domain/authentication';
import { NavbarItems } from '@app/model';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row } from '@atomic/obj.grid';
import { AccountFrame } from './account-frame.component';
import { NavbarDesktopListStyled, NavbarLinkWrapperStyled, NavbarLinkStyled, NavbarAnimatedChevronStyled, NavbarLogoLinkStyled, } from './navbar.component.style';
import { isExternalLink, NavbarIconMapper, NavbarLinkMapper, NavbarStringMapper } from './navbar.utils';
var strings = AppStrings.components.navbar;
export var NavbarDesktop = function () {
    var _a = useState(false), accountOpen = _a[0], setAccountOpen = _a[1];
    var location = useLocation();
    var setAuth = useAuthStorage().setAuth;
    var handleLogoutTap = function () {
        setAuth({ loggedOut: true });
        AnalyticsService.send(LogoutAnalytics());
        AnalyticsService.setUserId(null);
    };
    return (React.createElement(NavbarDesktopListStyled, null,
        React.createElement(Row, { alignItems: 'center', gap: Spacing.Medium },
            React.createElement(Column, { flex: 1 },
                React.createElement(NavbarLogoLinkStyled, { href: NavbarLinkMapper[NavbarItems.Home] },
                    React.createElement(AppIcon.Logo, null),
                    React.createElement("span", { className: 'sr-only' }, strings.goToHome))),
            Object.values(NavbarItems).map(function (item) { return (React.createElement(NavbarLinkWrapperStyled, { key: item, selected: location.pathname === NavbarLinkMapper[item] },
                React.createElement(NavbarLinkStyled, { href: NavbarLinkMapper[item], external: isExternalLink(NavbarLinkMapper[item]), selected: location.pathname === NavbarLinkMapper[item] },
                    React.createElement(Row, { noGutter: true, alignItems: 'center' },
                        NavbarIconMapper[item],
                        React.createElement(HorizontalSpacer, { "$width": Spacing.XSmall }),
                        NavbarStringMapper[item])))); }),
            React.createElement(NavbarLinkWrapperStyled, { selected: false },
                React.createElement(NavbarLinkStyled, { onClick: function () { return setAccountOpen(function (cur) { return !cur; }); } },
                    React.createElement(Row, { noGutter: true },
                        React.createElement(AppIcon.UserFilled, null),
                        React.createElement(HorizontalSpacer, { "$width": Spacing.XSmall }),
                        strings.pages.account,
                        React.createElement(HorizontalSpacer, { "$width": Spacing.XSmall }),
                        React.createElement(NavbarAnimatedChevronStyled, { selected: accountOpen })))),
            React.createElement(AccountFrame, { visible: accountOpen, onClick: handleLogoutTap }))));
};
