var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var flashMessageDefaultContext = {
    flashMessage: {
        show: false,
        message: '',
        type: 'info',
    },
    showFlashMessage: function () { return null; },
    hideFlashMessage: function () { return null; },
};
export var FlashMessageContext = React.createContext(flashMessageDefaultContext);
export var FlashMessageProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(flashMessageDefaultContext.flashMessage), flashMessage = _b[0], setFlashMessage = _b[1];
    var showFlashMessage = React.useCallback(function (data) { return setFlashMessage(__assign(__assign({}, data), { show: true })); }, []);
    var hideFlashMessage = React.useCallback(function () { return setFlashMessage(flashMessageDefaultContext.flashMessage); }, []);
    return (React.createElement(FlashMessageContext.Provider, { value: { flashMessage: flashMessage, showFlashMessage: showFlashMessage, hideFlashMessage: hideFlashMessage } }, children));
};
export var useFlashMessage = function () {
    var context = React.useContext(FlashMessageContext);
    if (!context) {
        throw new Error('useFlashMessage must be used within FlashMessageProvider');
    }
    return {
        showAlert: function (error) {
            return context.showFlashMessage({ type: 'alert', message: getErrorMessage(error) });
        },
        showInfo: function (message) { return context.showFlashMessage({ type: 'info', message: message }); },
        showSuccess: function (message) { return context.showFlashMessage({ type: 'success', message: message }); },
        showWarning: function (message) { return context.showFlashMessage({ type: 'warning', message: message }); },
    };
};
var getErrorMessage = function (error) { return (typeof error === 'string' ? error : error === null || error === void 0 ? void 0 : error.message); };
