import { EventType } from '@app/data/analytics/analytics.model';
import { getCartValue, mapProductToAnalytics } from './item.mapper';
export var ViewCartAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'view_cart',
        params: {
            currency: 'BRL',
            value: getCartValue(params.productList.items),
            items: params.productList.items.map(mapProductToAnalytics),
            item_id: params.itemId,
        },
    };
};
