import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { useFlashMessage } from '@app/components/mol.provider';
import { AnalyticsService, LoginAnalytics, SelectContentAnalytics } from '@app/data/analytics';
import { useLogin } from '@app/domain/authentication/login.use-case';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { Button } from '@atomic/atm.button';
import { Spacing } from '@atomic/obj.constants';
import { Column, VerticalSpacer } from '@atomic/obj.grid';
import { LoginForm, LoginFormCardStyled } from './components';
var strings = AppStrings.authentication.loginPage;
var LoginPage = function () {
    var showAlert = useFlashMessage().showAlert;
    var navigate = useNavigate();
    var _a = useLogin({
        onSuccess: function () {
            AnalyticsService.send(LoginAnalytics({ method: 'standard' }));
            navigate(HomeRoutes.Base, { replace: true });
        },
        onError: function (error) { return showAlert(error.message); },
    }), login = _a.mutate, isLoading = _a.isLoading;
    var handleLogin = function (input) {
        login(input);
    };
    var handleForgotPasswordClick = function () {
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'password_reset' }));
    };
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Column, { alignItems: 'center' },
            React.createElement(VerticalSpacer, { "$height": Spacing.XLarge }),
            React.createElement(AppIcon.Logo, null),
            React.createElement(VerticalSpacer, { "$height": Spacing.XLarge }),
            React.createElement(VerticalSpacer, { "$height": Spacing.XLarge }),
            React.createElement(LoginFormCardStyled, { doubleGutter: true },
                React.createElement(LoginForm, { onSubmit: handleLogin, loading: isLoading }),
                React.createElement(VerticalSpacer, null),
                React.createElement(Column, { alignItems: 'center' },
                    React.createElement(Button.Link, { href: forgotPasswordUrl, target: '_blank', onClick: handleForgotPasswordClick }, strings.forgotPassword))),
            React.createElement(VerticalSpacer, null))));
};
var forgotPasswordUrl = 'https://www.somospet2pet.com.br/vendas/admin/auth/forgotpassword/';
export default LoginPage;
