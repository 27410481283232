import { formatDate, pluralize } from './utils';
export var AppStrings = {
    components: {
        merchantItem: {
            email: 'E-mail',
            cnpj: 'CNPJ',
            phone: 'Telefone',
        },
        confirmationModal: {
            title: 'Excluir rascunho?',
            description: 'Você já tem uma lista de compras iniciada salva como rascunho. Ao iniciar uma nova lista, seu rascunho será excluído.',
            backButton: 'Voltar',
            continueButton: 'Excluir rascunho',
        },
        navbar: {
            menu: 'Menu',
            goToHome: 'Navegar para página inicial',
            logout: 'Sair da conta',
            pages: {
                home: 'Início',
                account: 'Conta',
            },
        },
        productCard: {
            sku: 'SKU#: ',
            quantity: 'Quantidade: ',
            discountPercentage: function (discount) { return "".concat(discount, "% de desconto"); },
            addToList: 'Adicionar',
            removeFromList: 'Remover item da lista',
        },
        productsListCard: {
            seeDetailsButton: 'Detalhes',
            useListButton: 'Usar lista',
            sentTo: function (names) { return "Enviado para: ".concat(names.join(', '), "."); },
        },
    },
    authentication: {
        loginPage: {
            form: {
                username: {
                    label: 'Usuário',
                    placeholder: 'Digite seu usuário',
                    caption: 'Usuário não é um e-mail',
                },
                password: {
                    label: 'Senha',
                    placeholder: 'Digite sua senha',
                },
                submit: {
                    button: 'Entrar',
                },
                errorMessage: {
                    required: 'Campo obrigatório',
                },
            },
            forgotPassword: 'Esqueci minha senha',
        },
        expiredTokenMessage: 'Sessão expirada, por favor faça o login novamente',
    },
    home: {
        productsList: {
            title: 'Listas prontas',
            description: 'Economize tempo com uma lista pronta e altere os produtos do jeito que quiser!',
            newListButton: 'Criar lista',
            seeMore: 'Ver mais',
            draft: 'Rascunho',
            noDataMessage: 'Você não tem nenhuma lista, crie a sua primeira.',
            errorMessage: 'Aconteceu um erro. Tente novamente.',
            errorButton: 'Tentar novamente',
        },
        listTab: {
            tabs: {
                suggestion: 'Sugeridas',
                madeByMe: 'Criadas por você',
            },
        },
        homePage: {
            listNameSufix: " - ".concat(formatDate(new Date())),
        },
    },
    productsList: {
        listQuantity: {
            text: function (quantity) { return "O carrinho tem ".concat(quantity, " produto").concat(quantity === 1 ? '' : 's', "."); },
        },
        editableTitle: {
            change: 'Salvar',
            cancel: 'Cancelar',
            requiredField: 'Campo obrigatório',
        },
        productsListing: {
            seeMore: 'Ver mais',
            noSearchDataMessage: 'Faça a busca dos produtos que deseja compartilhar.',
            noCartItensMessage: 'O seu carrinho está vazio. Adicione itens pela busca.',
            errorMessage: 'Aconteceu um erro. Tente novamente.',
            errorButton: 'Tentar novamente',
        },
        whatsappModal: {
            title: 'Compartilhar por Whatsapp',
            description: 'Escaneie o QR Code acima para compartilhar a lista de compra com seu cliente.',
            share: 'Enviar pelo app desktop',
            close: 'Fechar',
        },
        pricePreview: {
            subtotal: 'Subtotal',
            discountFallback: 'Desconto',
            total: 'Total',
            emptyDiscount: '-',
        },
        merchantShare: {
            generateLink: 'Gerar link',
            share: 'Compartilhar',
            details: 'Detalhes',
            copy: 'Copiar mensagem',
            shareLargeCartWarning: 'Gerando o carrinho. Isso pode demorar alguns minutos',
            copySuccess: 'Copiado para área de transferência.',
            copyError: 'Erro ao copiar para área de transferência. Por favor, tente novamente.',
            shareError: 'Erro ao obter link. Por favor, tente novamente',
            message: function (name, url) {
                return "Ol\u00E1, ".concat(name, ". Preparei uma compra para voc\u00EA, acesse esse link para execut\u00E1-la: ").concat(url);
            },
        },
        productListDetailsPage: {
            editButton: 'Editar lista',
            shareButton: 'Compartilhar',
            useListButton: function (price) { return "".concat(price ? "".concat(price, " ").concat(BULLET_POINT, " ") : '', "Usar esta lista"); },
            listQuantity: function (count) { return "".concat(count, " ").concat(pluralize({ count: count, singular: 'produto' })); },
            sentTo: function (names) { return "Enviado para: ".concat(names.join(', '), "."); },
            listNameSufix: " - ".concat(formatDate(new Date())),
            priceDisclaimer: 'Valores finais podem variar de cliente para cliente.',
        },
        productListEditPage: {
            listQuantity: function (count) {
                return "".concat(count, " ").concat(pluralize({ count: count, singular: 'produto', plural: 'produtos' }), " no carrinho");
            },
            searchQuantity: function (count) {
                return "".concat(count, " ").concat(pluralize({ count: count, singular: 'produto encontrado', plural: 'produtos encontrados' }));
            },
            submitButton: function (price) { return "".concat(price, " ").concat(BULLET_POINT, " Finalizar"); },
            searchDescription: 'Buscar por nome do produto ou SKU',
            searchPlaceholder: 'Adicionar produto',
            defaultListName: "Nova lista - ".concat(formatDate(new Date())),
            genericErrorMessage: 'Aconteceu um erro. Tente novamente.',
            noMerchantsMessage: 'Adicione clientes antes de continuar.',
            noDistributorMessage: 'Selecione o distribuidor antes de continuar.',
            noProductsMessage: 'Adicione produtos antes de continuar.',
            noQuantityProductsMessage: 'Garanta que todos os produtos tenham pelo menos uma unidade.',
            addItemsSuccessMessage: 'Produto adicionado com sucesso.',
            addItemWithNoQuantityMessage: 'Adicione pelo menos uma unidade.',
            priceDisclaimer: 'Valores finais podem variar de cliente para cliente.',
            distributorEditDisclaimer: 'Se trocar de distrubuidor o carrinho será limpo.',
        },
        merchantsPage: {
            title: 'Selecionar clientes',
            noFilter: 'Sem filtro',
            submitButton: function (count) {
                return "".concat(count, " ").concat(pluralize({ count: count, singular: 'selecionado' }), " ").concat(BULLET_POINT, " Continuar");
            },
            searchPlaceholder: 'Procure um cliente',
            seeMore: 'Ver mais',
            myBaseSwitchTitle: 'Buscar só a minha carteira',
            noDataMessage: 'Nenhum resultado para esta busca.',
            errorMessage: 'Aconteceu um erro. Tente novamente.',
            errorButton: 'Tentar novamente',
        },
        distributorsPage: {
            title: 'Selecionar distribuidor',
            submitButton: 'Continuar',
        },
        pricePreviewPage: {
            sentTo: function (names) { return "Enviado para: ".concat(names.join(', '), "."); },
            continueButton: 'Confirmar carrinho',
            backButton: 'Editar carrinho',
            largeCartWarning: 'Processando os totais. Isso pode demorar alguns minutos',
            listNotAddedSwitch: 'Exibir produtos não adicionados',
            listAddedQuantity: function (count) {
                return "".concat(count, " ").concat(pluralize({ count: count, singular: 'produto adicionado', plural: 'produtos adicionados' }));
            },
            listNotAddedQuantity: function (count) {
                return "".concat(count, " ").concat(pluralize({
                    count: count,
                    singular: 'produto não adicionado',
                    plural: 'produtos não adicionados',
                }), " por falta de estoque");
            },
            priceDisclaimer: 'Esta página apresenta apenas o preço e desconto referente ao carrinho completo. Os descontos individuais (para cada produto) serão apresentados corretamente no carrinho do cliente, considerando as campanhas e políticas promocionais. Caso deseje uma prévia, é possível consultar a página de carrinho do cliente após a criação da lista.',
        },
        sharePage: {
            placeholderTitle: 'Lista criada com sucesso!',
            placeholderDescription: 'Agora é só compartilhar com seus clientes',
            errorMessage: 'Aconteceu um erro. Tente novamente.',
            errorButton: 'Tentar novamente',
        },
        massSharePage: {
            successPlaceholder: {
                title: 'Lista criada com sucesso!',
                description: 'Agora é só compartilhar com seus clientes',
            },
            errorPlaceholder: {
                title: 'Falha no disparo em massa',
                description: 'Compartilhe o pedido manualmente para cada cliente',
            },
            warningPlaceholder: {
                title: 'Disparo em massa parcial',
                description: 'Não foi possível completar o disparo para alguns clientes. Compartilhe o pedido dos clientes pendentes manualmente.',
            },
            placeholderTitle: 'Lista criada com sucesso!',
            placeholderDescription: 'Agora é só compartilhar com seus clientes',
            errorMessage: 'Aconteceu um erro. Tente novamente.',
            errorButton: 'Tentar novamente',
            massShareTitle: 'Disparo em massa',
            beta: 'BETA',
            sentMerchantsTitle: function (sentQuantity, totalQuantity) {
                return "Enviado com sucesso para ".concat(sentQuantity, "/").concat(totalQuantity, " ").concat(totalQuantity === 1 ? 'cliente' : 'clientes');
            },
            individualShareTitle: 'Disparo individual',
            individualShareSubtitle: 'Clientes com falha no envio em massa e/ou sem telefone cadastrado.',
            merchantsLabel: 'Clientes',
            messageLabel: 'Mensagem',
            message: 'Olá!\nA Pet 2 Pet preparou uma nova sugestão de pedido personalizada com descontos exclusivos para você comprar em nosso site!',
            imagesLabel: 'Selecione uma imagem abaixo',
            sendButton: 'Enviar mensagem em massa',
            massShareWarning: 'Gerando links e enviando mensagens. Isso pode demorar alguns minutos',
            massShareError: 'Erro ao enviar mensagens em massa. Por favor, tente novamente ou utilize o disparo individual.',
            failedMerchantsTitle: function (quantity) { return "Falha no envio (".concat(quantity, ")"); },
            noPhoneMerchantsTitle: function (quantity) { return "Sem telefone cadastrado (".concat(quantity, ")"); },
        },
    },
};
var BULLET_POINT = '\u2022';
