export var formatPrice = function (price, _a) {
    var _b = _a === void 0 ? defaultFormatPriceOptions : _a, withPrefix = _b.withPrefix;
    if (isNaN(price)) {
        return '';
    }
    var isNegative = price < 0;
    var formattedPrice = (Math.abs(price) / 100).toFixed(2).replace('.', ',');
    return "".concat(isNegative ? '- ' : '').concat(withPrefix ? 'R$ ' : '').concat(formattedPrice);
};
var defaultFormatPriceOptions = { withPrefix: true };
