import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { AnalyticsService, LogoutAnalytics } from '@app/data/analytics';
import { useAuthStorage } from '@app/domain/authentication';
import { KeyboardKeys, useKeyboardListener } from '@app/domain/keyboard-listener';
import { NavbarItems } from '@app/model';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { Drawer } from '@atomic/atm.drawer';
import { HamburgerButton } from '@atomic/atm.hamburger-button';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row, VerticalSpacer } from '@atomic/obj.grid';
import { NavbarMobileStyled, NavbarMenuStyled, NavbarLinkWrapperStyled, NavbarLinkStyled, DrawerItemWrapperStyled, NavbarMenuLinkStyled, } from './navbar.component.style';
import { isExternalLink, NavbarIconMapper, NavbarLinkMapper, NavbarStringMapper } from './navbar.utils';
var strings = AppStrings.components.navbar;
export var NavbarMobile = function () {
    var _a;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var location = useLocation();
    var setAuth = useAuthStorage().setAuth;
    useKeyboardListener((_a = {}, _a[KeyboardKeys.Escape] = function () { return setOpen(false); }, _a));
    var handleLogoutTap = function () {
        setAuth({ loggedOut: true });
        AnalyticsService.send(LogoutAnalytics());
        AnalyticsService.setUserId(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NavbarMobileStyled, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { noGutter: true, alignItems: 'center' },
                React.createElement(HorizontalSpacer, null),
                React.createElement(Column, { flex: 1 },
                    React.createElement("a", { href: NavbarLinkMapper[NavbarItems.Home] },
                        React.createElement(AppIcon.Logo, null),
                        React.createElement("span", { className: 'sr-only' }, strings.goToHome))),
                React.createElement(NavbarMenuStyled, { onClick: function () { return setOpen(function (prev) { return !prev; }); } },
                    React.createElement(Row, { noGutter: true, alignItems: 'center' },
                        React.createElement(NavbarMenuLinkStyled, null, strings.menu),
                        React.createElement(HamburgerButton, { active: open, ariaLabel: open ? 'Fechar menu' : 'Abrir menu' })))),
            React.createElement(VerticalSpacer, null)),
        React.createElement(Drawer, { active: open, onOverlayClick: function () { return setOpen(false); } },
            Object.values(NavbarItems).map(function (item) { return (React.createElement(React.Fragment, { key: item },
                React.createElement(VerticalSpacer, null),
                React.createElement(DrawerItemWrapperStyled, null,
                    React.createElement(HorizontalSpacer, null),
                    React.createElement(NavbarLinkWrapperStyled, { selected: location.pathname === NavbarLinkMapper[item] },
                        React.createElement(NavbarLinkStyled, { href: NavbarLinkMapper[item], external: isExternalLink(NavbarLinkMapper[item]), onClick: function () { return setOpen(false); }, selected: location.pathname === NavbarLinkMapper[item] },
                            React.createElement(Row, { noGutter: true, alignItems: 'center' },
                                NavbarIconMapper[item],
                                React.createElement(HorizontalSpacer, { "$width": Spacing.XSmall }),
                                NavbarStringMapper[item])))))); }),
            React.createElement(VerticalSpacer, null),
            React.createElement(Divider, null),
            React.createElement(VerticalSpacer, null),
            React.createElement(DrawerItemWrapperStyled, null,
                React.createElement(HorizontalSpacer, null),
                React.createElement(NavbarLinkWrapperStyled, null,
                    React.createElement(NavbarLinkStyled, null,
                        React.createElement(Row, { noGutter: true },
                            React.createElement(AppIcon.UserFilled, null),
                            React.createElement(HorizontalSpacer, { "$width": Spacing.XSmall }),
                            strings.pages.account)),
                    React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
                    React.createElement(Button.DestructiveLink, { onClick: handleLogoutTap }, strings.logout))))));
};
