import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { useFlashMessage } from '@app/components/mol.provider';
import { AnalyticsService, CartConfirmationAnalytics, SelectContentAnalytics, ViewCartAnalytics, } from '@app/data/analytics';
import { useAuthStorage } from '@app/domain/authentication';
import { useDraft } from '@app/domain/draft';
import { useDetailProductList } from '@app/domain/product-list';
import { usePricePreview } from '@app/domain/product-list/price-preview.use-case';
import { getListId, getProductListProperties } from '@app/utils';
import { Button } from '@atomic/atm.button';
import { BodySecondary, Ellipsed, H1 } from '@atomic/atm.typography';
import { Accordion } from '@atomic/mol.accordion';
import { SwitchCell } from '@atomic/mol.switch-cell';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, VerticalSpacer } from '@atomic/obj.grid';
import { PriceDisclaimer, PricePreview, ProductsListing } from './components';
import { ProductsListRoutes } from './products-list.routes';
var strings = AppStrings.productsList.pricePreviewPage;
export var PricePreviewPage = function () {
    var _a, _b, _c;
    var navigate = useNavigate();
    var auth = useAuthStorage().auth;
    var clearDraft = useDraft().clearDraft;
    var showWarning = useFlashMessage().showWarning;
    var _d = useState(false), notAddedListOpen = _d[0], setNotAddedListOpen = _d[1];
    var _e = useState(), priceData = _e[0], setPriceData = _e[1];
    var listId = useParams().listId;
    var id = getListId(listId);
    useEffect(function () {
        showWarning(strings.largeCartWarning);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var _f = useDetailProductList({
        id: id,
        onSuccess: function (productList) {
            return AnalyticsService.send(ViewCartAnalytics({ productList: productList, itemId: id.toString() }));
        },
    }), data = _f.data, isLoading = _f.isLoading, isFetching = _f.isFetching, error = _f.error, refetch = _f.refetch;
    var merchantNames = getProductListProperties(data).merchantNames;
    var _g = usePricePreview({ id: id, skip: !!priceData, onSuccess: function (productList) { return setPriceData(productList); } }), priceIsLoading = _g.isLoading, priceError = _g.error, priceRefetch = _g.refetch;
    var handleContinueTap = function () {
        var _a;
        if (!id) {
            return;
        }
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'validate_cart' }));
        clearDraft();
        AnalyticsService.send(CartConfirmationAnalytics({ itemId: id.toString(), clients: merchantNames }));
        var nextRoute = ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.whatsappApiEnabled) ? ProductsListRoutes.MassShare : ProductsListRoutes.Share;
        navigate(nextRoute(id.toString()));
    };
    var handleBackTap = function () {
        if (!id) {
            return;
        }
        navigate(ProductsListRoutes.Edit(id.toString()));
    };
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true, gap: { xs: Spacing.Medium, md: 0 } },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(H1, null, data === null || data === void 0 ? void 0 : data.title),
                        !!merchantNames.length && (React.createElement(React.Fragment, null,
                            React.createElement(BodySecondary, null,
                                React.createElement(Ellipsed, { maxLines: 3 }, strings.sentTo(merchantNames))),
                            React.createElement(VerticalSpacer, null))),
                        !!priceData && (React.createElement(React.Fragment, null,
                            React.createElement(PricePreview, { cartPreview: priceData }),
                            React.createElement(VerticalSpacer, null))),
                        React.createElement(PriceDisclaimer, { text: strings.priceDisclaimer }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(Button.Secondary, { onClick: handleBackTap, expanded: true }, strings.backButton),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(Button.CallToAction, { onClick: handleContinueTap, expanded: true }, strings.continueButton))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    ((_a = priceData === null || priceData === void 0 ? void 0 : priceData.notAddedProducts) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement(React.Fragment, null,
                        React.createElement(SwitchCell, { title: strings.listNotAddedSwitch, checked: notAddedListOpen, onChange: setNotAddedListOpen }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(Accordion, { expanded: notAddedListOpen, noTitle: true },
                            React.createElement(ProductsListing, { items: (priceData === null || priceData === void 0 ? void 0 : priceData.notAddedProducts) || [], loading: isLoading || priceIsLoading || isFetching, error: error || priceError, title: strings.listNotAddedQuantity((_b = priceData === null || priceData === void 0 ? void 0 : priceData.notAddedProducts) === null || _b === void 0 ? void 0 : _b.length), refetch: function () { return (!!error ? refetch() : priceRefetch()); }, showFixedQuantity: true }),
                            React.createElement(VerticalSpacer, null)))) : null,
                    React.createElement(ProductsListing, { items: (priceData === null || priceData === void 0 ? void 0 : priceData.addedProducts) || [], loading: isLoading || priceIsLoading || isFetching, error: error || priceError, title: strings.listAddedQuantity(((_c = priceData === null || priceData === void 0 ? void 0 : priceData.addedProducts) === null || _c === void 0 ? void 0 : _c.length) || 0), refetch: function () { return (!!error ? refetch() : priceRefetch()); }, showFixedQuantity: true }))),
            React.createElement(VerticalSpacer, null))));
};
