var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from 'typedi';
export var DRAFT_LOCALSTORAGE_KEY = 'DRAFT-STORAGE';
var DraftStorageService = /** @class */ (function () {
    function DraftStorageService() {
        this.observers = new Set();
        this._draft = JSON.parse(localStorage.getItem(DRAFT_LOCALSTORAGE_KEY) || 'null');
    }
    Object.defineProperty(DraftStorageService.prototype, "draft", {
        get: function () {
            return this._draft;
        },
        set: function (draft) {
            this._draft = draft;
            localStorage.setItem(DRAFT_LOCALSTORAGE_KEY, JSON.stringify(draft));
            this.observers.forEach(function (observer) { return observer === null || observer === void 0 ? void 0 : observer(draft); });
        },
        enumerable: false,
        configurable: true
    });
    DraftStorageService.prototype.addObserver = function (observer) {
        this.observers.add(observer);
    };
    DraftStorageService.prototype.removeObserver = function (observer) {
        this.observers.delete(observer);
    };
    DraftStorageService = __decorate([
        Service()
    ], DraftStorageService);
    return DraftStorageService;
}());
export { DraftStorageService };
