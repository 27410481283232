import { ApolloClient } from '@apollo/client';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import { setConfiguration } from 'react-grid-system';
import { Container } from 'typedi';
import { ApolloClientBuilderService } from '@app/core/graphql';
import { FirebaseAnalyticsService } from '@app/data/analytics/firebase-analytics.service';
import { GridSettings } from '@atomic/obj.grid';
import { TrpcClientBuilderService, TrpcClient } from './core/trpc';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function configure(frontendEnvironmentVariables) {
    var _a;
    var baseUrl = (_a = frontendEnvironmentVariables.BASE_URL) !== null && _a !== void 0 ? _a : '';
    configApolloClient(baseUrl);
    configTrpcClient(baseUrl);
    configureGrid();
    configureFirebase(frontendEnvironmentVariables);
}
function configureGrid() {
    setConfiguration(GridSettings);
}
function configApolloClient(url) {
    var client = Container.get(ApolloClientBuilderService).build(url);
    Container.set(ApolloClient, client);
}
function configTrpcClient(url) {
    var client = Container.get(TrpcClientBuilderService).build(url);
    Container.set(TrpcClient, client);
}
function configureFirebase(frontendEnvironmentVariables) {
    var config = {
        apiKey: frontendEnvironmentVariables.FIREBASE_API_KEY,
        authDomain: frontendEnvironmentVariables.FIREBASE_AUTH_DOMAIN,
        projectId: frontendEnvironmentVariables.FIREBASE_PROJECT_ID,
        storageBucket: frontendEnvironmentVariables.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: frontendEnvironmentVariables.FIREBASE_MESSAGING_SENDER_ID,
        appId: frontendEnvironmentVariables.FIREBASE_APP_ID,
        measurementId: frontendEnvironmentVariables.FIREBASE_MEASUREMENT_ID,
    };
    var app = initializeApp(config);
    Container.set(FirebaseAnalyticsService, new FirebaseAnalyticsService(app));
    getPerformance(app);
}
