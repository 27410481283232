import { EventType } from '@app/data/analytics/analytics.model';
export var CartConfirmationAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'cart_confirmation',
        params: {
            item_id: params.itemId,
            clients: params.clients,
        },
    };
};
