var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ListTabs } from '@app/model';
export var addQuantityToProductItems = function (searchItems) {
    return searchItems.map(function (item) { return (__assign({ quantity: 1 }, item)); });
};
export var getListId = function (listId) {
    return (listId && Number.isInteger(+listId) && +listId) || undefined;
};
export var getMerchantName = function (merchant) { var _a; return (_a = merchant.companyName) !== null && _a !== void 0 ? _a : merchant.name; };
export var getListPrice = function (items) { var _a; return (_a = items.reduce(function (total, item) { var _a; return item.quantity * ((_a = item.price) !== null && _a !== void 0 ? _a : 0) + total; }, 0)) !== null && _a !== void 0 ? _a : 0; };
export var getProductListProperties = function (list) {
    var _a, _b, _c;
    var totalPrice = getListPrice((_a = list === null || list === void 0 ? void 0 : list.items) !== null && _a !== void 0 ? _a : []);
    var isMadeByMe = (list === null || list === void 0 ? void 0 : list.type) === ListTabs.MadeByMe;
    var merchantNames = (_c = (_b = list === null || list === void 0 ? void 0 : list.merchants) === null || _b === void 0 ? void 0 : _b.map(getMerchantName)) !== null && _c !== void 0 ? _c : [];
    return {
        totalPrice: totalPrice,
        isMadeByMe: isMadeByMe,
        merchantNames: merchantNames,
    };
};
