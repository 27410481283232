import React from 'react';
import { AppStrings } from '@app/app-strings';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Column, Row, VerticalSpacer } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
var strings = AppStrings.components.confirmationModal;
export var ConfirmationModal = function (props) {
    return (React.createElement(Modal, { opened: props.opened, onClose: props.onClose, title: strings.title, small: true },
        React.createElement(Row, null,
            React.createElement(Column, { justifyContent: 'center' },
                React.createElement(Body, null, strings.description),
                React.createElement(VerticalSpacer, { "$height": Spacing.Large }),
                React.createElement(Hbox, null,
                    React.createElement(Hbox.Item, null,
                        React.createElement(Button.Link, { expanded: true, onClick: props.onClose }, strings.backButton)),
                    React.createElement(Hbox.Separator, { size: Spacing.Medium }),
                    React.createElement(Hbox.Item, null,
                        React.createElement(Button.Primary, { expanded: true, onClick: props.onConfirm, loading: props.loading }, strings.continueButton)))))));
};
