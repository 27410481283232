import * as React from 'react';
/**
 * This context helps in the integration of Form.Field and your field (an input, radio etc).
 * If you create a new custom field, you need to use this context and call some
 * methods, such as onValueChange, onFocusChange, onClear. To see an example,
 * check select.component.tsx or text-field.component.tsx
 */
export var FormFieldContext = React.createContext(undefined);
/**
 * React Context used to collect all "Form.Field" elements inside a "Form"
 */
export var FormContext = React.createContext(undefined);
