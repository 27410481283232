import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { ConfirmationModal } from '@app/components/mol.confirmation-modal';
import { AnalyticsService, SelectContentAnalytics, ViewModalAnalytics } from '@app/data/analytics';
import { useDraft } from '@app/domain/draft';
import { useDetailProductList } from '@app/domain/product-list';
import { ListTabs } from '@app/model';
import { NEW_LIST_ID, ProductsListRoutes } from '@app/modules/products-list/products-list.routes';
import { addQuantityToProductItems } from '@app/utils';
import { VerticalSpacer } from '@atomic/obj.grid';
import { ProductsList } from './components';
var strings = AppStrings.home.homePage;
var HomePage = function () {
    var _a = React.useState(false), opened = _a[0], setOpened = _a[1];
    var newDraftRef = React.useRef(null);
    var _b = React.useState(undefined), listId = _b[0], setListId = _b[1];
    var isFetching = useDetailProductList({
        id: listId,
        onSuccess: function (list) {
            newDraftRef.current = {
                id: NEW_LIST_ID,
                title: list.title + strings.listNameSufix,
                items: addQuantityToProductItems(list.items),
                merchants: [],
            };
            setListId(undefined);
            if (hasDraft) {
                return;
            }
            navigateToNewList();
        },
    }).isFetching;
    var _c = useDraft(), hasDraft = _c.hasDraft, setDraft = _c.setDraft;
    var navigate = useNavigate();
    var navigateToNewList = function () {
        if (newDraftRef.current) {
            setDraft(newDraftRef.current);
        }
        navigate(ProductsListRoutes.Distributors(NEW_LIST_ID), {
            state: { nextRoute: ProductsListRoutes.Merchants(NEW_LIST_ID) },
        });
    };
    var handleNewListTap = function () {
        newDraftRef.current = { id: NEW_LIST_ID, items: [], merchants: [] };
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'begin_cart' }));
        if (hasDraft) {
            openConfirmationModal();
            return;
        }
        navigateToNewList();
    };
    var handleUseListTap = function (list, tab) {
        AnalyticsService.send(SelectContentAnalytics({
            contentType: tab === ListTabs.Suggestion ? 'suggested_list' : 'user_list',
            itemId: list.id.toString(),
            itemName: list.title,
        }));
        setListId(list.id);
        if (hasDraft) {
            openConfirmationModal();
            return;
        }
    };
    var handleDraftTap = function () {
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'user_list' }));
        navigateToNewList();
    };
    var handleSeeListTap = function (list) {
        navigate(ProductsListRoutes.Details(list.id.toString()));
    };
    var openConfirmationModal = function () {
        setOpened(true);
        AnalyticsService.send(ViewModalAnalytics({ name: 'exclude_draft' }));
    };
    var handleConfirm = function () {
        setOpened(false);
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'exclude_draft' }));
        navigateToNewList();
    };
    var handleClose = function () {
        newDraftRef.current = null;
        setOpened(false);
    };
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(VerticalSpacer, null),
        React.createElement(ProductsList, { onNewListTap: handleNewListTap, onSeeListTap: handleSeeListTap, onUseListTap: handleUseListTap, onDraftTap: handleDraftTap, loading: isFetching }),
        React.createElement(ConfirmationModal, { opened: opened, onConfirm: handleConfirm, onClose: handleClose, loading: isFetching }),
        React.createElement(VerticalSpacer, null)));
};
export default HomePage;
