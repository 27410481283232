import Container from 'typedi';
import { EventType } from './analytics.model';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
export var AnalyticsService = {
    send: function (event) {
        try {
            switch (event.type) {
                case EventType.EVENT: {
                    Container.get(FirebaseAnalyticsService).logEvent(event.eventName, event.params);
                    break;
                }
                case EventType.PAGE_VIEW: {
                    Container.get(FirebaseAnalyticsService).logPageView(event.params);
                    break;
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    },
    setUserId: function (userId) {
        try {
            Container.get(FirebaseAnalyticsService).setUserId(userId);
        }
        catch (error) {
            console.error(error);
        }
    },
    setUserProperties: function (properties) {
        try {
            Container.get(FirebaseAnalyticsService).setUserProperties(properties);
        }
        catch (error) {
            console.error(error);
        }
    },
};
