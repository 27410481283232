export var mapProductSearchItemToAnalytics = function (item, quantity) {
    return {
        item_name: item.name,
        item_id: item.sku,
        price: item.price ? item.price / 100 : undefined,
        discount: item.price && item.listPrice ? (item.listPrice - item.price) / 100 : undefined,
        quantity: quantity,
    };
};
export var mapProductToAnalytics = function (item) {
    return {
        item_name: item.name,
        item_id: item.sku,
        price: item.price ? item.price / 100 : undefined,
        discount: item.price && item.listPrice ? (item.listPrice - item.price) / 100 : undefined,
        quantity: item.quantity,
    };
};
export var getCartValue = function (products) {
    return products.reduce(function (total, item) { var _a; return (total += (((_a = item.price) !== null && _a !== void 0 ? _a : 0) / 100) * item.quantity); }, 0);
};
