var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/display-name */
import * as React from 'react';
import Alert from '@assets/icons/custom/ic_alert.svg';
import ArrowDown from '@assets/icons/custom/ic_arrow_down.svg';
import ChevronDown from '@assets/icons/custom/ic_chevron_down.svg';
import Copy from '@assets/icons/custom/ic_copy.svg';
import EmptySearch from '@assets/icons/custom/ic_empty_search.svg';
import Error from '@assets/icons/custom/ic_error.svg';
import GenericError from '@assets/icons/custom/ic_generic_error.svg';
import InfoFilled from '@assets/icons/custom/ic_info_filled.svg';
import InfoOutlined from '@assets/icons/custom/ic_info_outlined.svg';
import Paw from '@assets/icons/custom/ic_paw.svg';
import Search from '@assets/icons/custom/ic_search.svg';
import Success from '@assets/icons/custom/ic_success.svg';
import Trash from '@assets/icons/custom/ic_trash.svg';
import User from '@assets/icons/custom/ic_user.svg';
import UserFilled from '@assets/icons/custom/ic_user_filled.svg';
import Warning from '@assets/icons/custom/ic_warning.svg';
import Logo from '@assets/img/img_logo.svg';
import { IcCartPlus } from './icons/ic-cart-plus.component';
import { IcEdit } from './icons/ic-edit.component';
import { IcHome } from './icons/ic-home.component';
import { IcShare } from './icons/ic-share.component';
import { IcWhatsapp } from './icons/ic-whatsapp.component';
export var AppIcon = {
    Alert: function () { return React.createElement("img", { alt: '', src: Alert }); },
    ArrowDown: function () { return React.createElement("img", { alt: '', src: ArrowDown }); },
    CartPlus: function (props) { return React.createElement(IcCartPlus, __assign({}, props)); },
    ChevronDown: function (props) { return React.createElement("img", __assign({ alt: '', src: ChevronDown }, props)); },
    Copy: function (props) { return React.createElement("img", __assign({ alt: '', src: Copy }, props)); },
    Edit: function (props) { return React.createElement(IcEdit, __assign({ alt: 'Editar' }, props)); },
    EmptySearch: function () { return React.createElement("img", { alt: '', src: EmptySearch }); },
    Error: function () { return React.createElement("img", { alt: '', src: Error }); },
    GenericError: function () { return React.createElement("img", { alt: '', src: GenericError }); },
    Home: function (props) { return React.createElement(IcHome, __assign({}, props)); },
    InfoFilled: function () { return React.createElement("img", { alt: '', src: InfoFilled }); },
    InfoOutlined: function () { return React.createElement("img", { alt: '', src: InfoOutlined }); },
    Logo: function () { return React.createElement("img", { alt: '', src: Logo, width: '70px' }); },
    Paw: function () { return React.createElement("img", { alt: '', src: Paw }); },
    Search: function () { return React.createElement("img", { alt: '', src: Search }); },
    Share: function () { return React.createElement(IcShare, null); },
    Success: function () { return React.createElement("img", { alt: '', src: Success }); },
    Trash: function () { return React.createElement("img", { alt: '', src: Trash }); },
    User: function () { return React.createElement("img", { alt: '', src: User }); },
    UserFilled: function () { return React.createElement("img", { alt: '', src: UserFilled }); },
    Warning: function () { return React.createElement("img", { alt: '', src: Warning }); },
    Whatsapp: function () { return React.createElement(IcWhatsapp, null); },
};
