import React from 'react';
import { AppStrings } from '@app/app-strings';
import { formatPrice } from '@app/utils';
import { Divider } from '@atomic/atm.divider';
import { DD, DT } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Column, VerticalSpacer } from '@atomic/obj.grid';
var strings = AppStrings.productsList.pricePreview;
export var PricePreview = function (_a) {
    var _b;
    var cartPreview = _a.cartPreview;
    return (React.createElement(Column, null,
        React.createElement(PricePreviewItem, { title: strings.subtotal, price: cartPreview.subTotal }),
        React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
        React.createElement(PricePreviewItem, { title: (_b = cartPreview.discountCampaignTitle) !== null && _b !== void 0 ? _b : strings.discountFallback, price: cartPreview.discount }),
        React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
        React.createElement(Divider, null),
        React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
        React.createElement(PricePreviewItem, { title: strings.total, price: cartPreview.total })));
};
var PricePreviewItem = function (_a) {
    var title = _a.title, price = _a.price;
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, null,
            React.createElement(DT, null, title)),
        React.createElement(Hbox.Separator, null),
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(DD, null, !!price ? formatPrice(price) : strings.emptyDiscount))));
};
