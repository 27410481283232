import React from 'react';
import { AppStrings } from '@app/app-strings';
import { Placeholder } from '@app/components/mol.placeholder';
import { ProductCard } from '@app/components/mol.product-card';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { H2 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Row, VerticalSpacer } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { ProductsListingShimmer } from './products-listing.shimmer.component';
var strings = AppStrings.productsList.productsListing;
export var ProductsListing = function (props) {
    var _a, _b, _c;
    return (React.createElement(LoadingState, { data: !!((_a = props.items) === null || _a === void 0 ? void 0 : _a.length), loading: props.loading, error: !!props.error },
        React.createElement(LoadingState.Shimmer, null,
            React.createElement(ProductsListingShimmer, { withStepper: !!props.onAddToList || !!props.onQuantityChange })),
        React.createElement(LoadingState.NoData, null, !!props.onAddToList ? (React.createElement(Placeholder, { title: strings.noSearchDataMessage, icon: 'EmptySearch' })) : (React.createElement(Placeholder, { title: strings.noCartItensMessage, icon: 'GenericError' }))),
        React.createElement(LoadingState.Error, null,
            React.createElement(Placeholder, { title: strings.errorMessage, icon: 'GenericError' },
                React.createElement(Row, { noGutter: true, justifyContent: 'center' },
                    React.createElement(Button.Secondary, { onClick: props.refetch }, strings.errorButton)))),
        React.createElement(H2, { noMargin: true }, props.title),
        React.createElement(VerticalSpacer, null),
        ((_b = props.items) === null || _b === void 0 ? void 0 : _b.length) > 0 && React.createElement(Divider, null), (_c = props.items) === null || _c === void 0 ? void 0 :
        _c.map(function (item) { return (React.createElement(React.Fragment, { key: item.id },
            React.createElement(ProductCard, { product: item, initialQuantity: item.quantity, onRemoveFromList: props.onRemoveFromList, onQuantityChange: props.onQuantityChange, onAddToList: props.onAddToList, showFixedQuantity: props.showFixedQuantity }))); }),
        props.onNextPageTap && (React.createElement(React.Fragment, null,
            React.createElement(VerticalSpacer, { "$height": Spacing.Small }),
            React.createElement(Row, { justifyContent: 'center' },
                React.createElement(Button.Link, { onClick: props.onNextPageTap }, strings.seeMore))))));
};
