import React from 'react';
import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage';
export var useAuthStorage = function () {
    var authStorage = Container.get(AuthStorageService);
    var _a = React.useState(authStorage.auth), auth = _a[0], setAuth = _a[1];
    var setAuthLocalStorage = function (newAuth) {
        authStorage.auth = newAuth;
        setAuth(newAuth);
    };
    var handleStorageChange = React.useCallback(function (newAuth) { return setAuth(newAuth); }, [setAuth]);
    React.useEffect(function () {
        authStorage.addObserver(handleStorageChange);
        return function () { return authStorage.removeObserver(handleStorageChange); };
    }, [authStorage, handleStorageChange]);
    return { auth: auth, setAuth: setAuthLocalStorage };
};
