var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Border, Color, MediaQuery, Shadow, Spacing } from '@atomic/obj.constants';
export var CardCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  background-color: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n\n  ", "\n\n  ", " {\n    ", "\n  }\n"], ["\n  ", "\n  background-color: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n\n  ", "\n\n  ", " {\n    ", "\n  }\n"])), function (_a) {
    var noGutter = _a.noGutter;
    return !noGutter && "padding: ".concat(Spacing.Medium, ";");
}, Color.White, Border.Radius, Shadow.Medium, function (props) {
    return props.vGrow && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      min-height: 100%;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n    "], ["\n      min-height: 100%;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n    "])));
}, MediaQuery.md, function (_a) {
    var noGutter = _a.noGutter, doubleGutter = _a.doubleGutter;
    return !noGutter && doubleGutter && "padding: ".concat(Spacing.XLarge, ";");
});
export var CardStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), CardCss);
var templateObject_1, templateObject_2, templateObject_3;
