var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { LoadingStateBlockStyled, LoadingStateStyled, LoadingStyled } from './loading-state.style';
var StateShimmer = function (props) { return React.createElement(React.Fragment, null, props.children); };
StateShimmer.displayName = 'StateShimmer';
var StateError = function (props) { return React.createElement(React.Fragment, null, props.children); };
StateError.displayName = 'StateError';
var StateNoData = function (props) { return React.createElement(React.Fragment, null, props.children); };
StateNoData.displayName = 'StateNoData';
var LoadingState = /** @class */ (function (_super) {
    __extends(LoadingState, _super);
    function LoadingState() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoadingState.prototype.render = function () {
        var _a = this.getTypedChildren(), shimmer = _a.shimmer, error = _a.error, noData = _a.noData, data = _a.data;
        return (React.createElement(LoadingStateStyled, null,
            React.createElement(LoadingStateBlockStyled, { visible: this.props.error && !this.props.loading && !this.props.data }, error),
            React.createElement(LoadingStateBlockStyled, { visible: !this.props.data && this.props.loading }, shimmer),
            React.createElement(LoadingStateBlockStyled, { visible: !this.props.error && !this.props.data && !this.props.loading }, noData),
            React.createElement(LoadingStateBlockStyled, { visible: this.props.data }, this.props.data && data),
            React.createElement(LoadingStyled, { visible: this.props.data && this.props.loading && this.props.enableActivityIndicator, topMost: this.props.topMost },
                React.createElement(ActivityIndicator, { type: 'spinner' }))));
    };
    LoadingState.prototype.getTypedChildren = function () {
        var shimmer;
        var error;
        var noData;
        var data = [];
        React.Children.map(this.props.children, function (child) {
            if (child === null || child === void 0 ? void 0 : child.type) {
                switch (child.type.displayName) {
                    case StateShimmer.displayName:
                        shimmer = child;
                        break;
                    case StateError.displayName:
                        error = child;
                        break;
                    case StateNoData.displayName:
                        noData = child;
                        break;
                    default:
                        data.push(child);
                }
            }
            else {
                data.push(child);
            }
        });
        return { shimmer: shimmer, error: error, noData: noData, data: data };
    };
    LoadingState.defaultProps = {
        data: true,
        enableActivityIndicator: true,
        topMost: false,
    };
    LoadingState.Shimmer = StateShimmer;
    LoadingState.Error = StateError;
    LoadingState.NoData = StateNoData;
    return LoadingState;
}(React.Component));
export { LoadingState };
