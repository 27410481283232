import { NEW_LIST_ID } from '@app/modules/products-list/products-list.routes';
import { useCreateProductList } from './create-product-list.use-case';
import { useUpdateProductList } from './update-product-list.use-case';
export var useUpsertProductList = function (params) {
    var _a, _b, _c;
    var updateResponse = useUpdateProductList(params);
    var createResponse = useCreateProductList(params);
    var mutate = function (data, id) {
        if (id === NEW_LIST_ID) {
            createResponse.mutate(data);
        }
        else {
            updateResponse.mutate({ id: +id, data: data });
        }
    };
    return {
        mutate: mutate,
        data: (_a = updateResponse.data) !== null && _a !== void 0 ? _a : createResponse.data,
        isLoading: (_b = updateResponse.isLoading) !== null && _b !== void 0 ? _b : createResponse.isLoading,
        error: (_c = updateResponse.error) !== null && _c !== void 0 ? _c : createResponse.error,
    };
};
