var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { FaIconSize } from '@atomic/obj.constants';
import { PasswordFieldViewButtonStyled } from './password-field.component.style';
export var PasswordField = function (props) {
    var _a = React.useState(true), secure = _a[0], setSecure = _a[1];
    var Icon = secure ? FaIcon.PasswordShow : FaIcon.PasswordHide;
    var handleIconTap = function () {
        setSecure(function (cur) { return !cur; });
    };
    return (React.createElement(TextField, __assign({}, props, { type: secure ? 'password' : 'text', autoCapitalize: 'none' }),
        React.createElement(PasswordFieldViewButtonStyled, { onClick: handleIconTap, disabled: props.disabled, type: 'button', role: 'switch', "aria-checked": !secure },
            React.createElement("span", { className: 'sr-only' }, "Mostrar senha"),
            React.createElement(Icon, { size: FaIconSize.Large }))));
};
