var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, MediaQuery, Spacing } from '@atomic/obj.constants';
var variantMapper = {
    black: Color.Black,
    callToAction: Color.CallToAction,
    success: Color.SuccessDark,
    primary: Color.Primary,
};
export var HDisplay = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", " 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", " 0;\n"])), Color.Primary, FontSize.XXXLarge, MediaQuery.md, FontSize.XXXXLarge, FontFamily.Primary, FontWeight.Medium, Spacing.Small);
HDisplay.displayName = 'HDisplay';
export var H1 = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", ";\n"])), function (_a) {
    var variant = _a.variant;
    return (variant ? variantMapper[variant] : Color.Primary);
}, FontSize.XXXLarge, MediaQuery.md, FontSize.XXXXLarge, FontFamily.Primary, FontWeight.Bold, function (_a) {
    var noMargin = _a.noMargin;
    return (noMargin ? '0' : "".concat(Spacing.Small, " 0"));
});
export var H2 = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", ";\n"])), function (_a) {
    var variant = _a.variant;
    return (variant ? variantMapper[variant] : Color.Primary);
}, FontSize.XXLarge, MediaQuery.md, FontSize.XXXLarge, FontFamily.Primary, FontWeight.Bold, function (_a) {
    var noMargin = _a.noMargin;
    return (noMargin ? '0' : "".concat(Spacing.Small, " 0"));
});
H2.displayName = 'H2';
export var H3 = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n  margin: ", ";\n"])), Color.Primary, FontSize.XLarge, MediaQuery.md, FontSize.XXLarge, FontFamily.Primary, FontWeight.Medium, function (_a) {
    var noMargin = _a.noMargin;
    return (noMargin ? '0' : "".concat(Spacing.Small, " 0"));
});
H3.displayName = 'H3';
export var H4 = styled.h4(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  ", " {\n    font-size: ", ";\n  }\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.Primary, FontSize.Large, MediaQuery.md, FontSize.XLarge, FontFamily.Primary, FontWeight.Bold);
H4.displayName = 'H4';
export var Body = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: 1.5;\n  ", ";\n"], ["\n  color: ", ";\n  margin: 0;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: 1.5;\n  ", ";\n"])), function (_a) {
    var variant = _a.variant;
    return (variant ? variantMapper[variant] : Color.GrayDark);
}, FontSize.Medium, FontFamily.Primary, function (_a) {
    var bold = _a.bold;
    return (bold ? FontWeight.Bold : FontWeight.Normal);
}, function (_a) {
    var breakWords = _a.breakWords;
    return breakWords && 'word-break: break-all;';
});
Body.displayName = 'Body';
export var BodySecondary = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: 1.5;\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: 1.5;\n  margin: 0;\n"])), Color.GrayDark, FontSize.Small, FontFamily.Primary, function (_a) {
    var bold = _a.bold;
    return (bold ? FontWeight.Bold : FontWeight.Normal);
});
export var BodySecondaryCentered = styled(BodySecondary)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var InputLabel = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), function (props) { return (props.hasError ? Color.Error : Color.Black); }, FontSize.Small, FontFamily.Primary, FontWeight.Bold, Spacing.XSmall);
export var InputValueCss = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.GrayXDark, FontSize.Medium, FontFamily.Primary, FontWeight.Medium);
export var InputValue = styled.label(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), InputValueCss);
export var InputDisabledCss = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  /* https://stackoverflow.com/a/45209441/3670829 */\n  -webkit-text-fill-color: ", ";\n  opacity: 1;\n  -webkit-opacity: 1;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  /* https://stackoverflow.com/a/45209441/3670829 */\n  -webkit-text-fill-color: ", ";\n  opacity: 1;\n  -webkit-opacity: 1;\n"])), Color.Gray, FontSize.Small, FontFamily.Primary, FontWeight.Medium, Color.GrayLight);
export var InputDisabled = styled.label(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), InputDisabledCss);
export var InputCaption = styled.label(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  display: block;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  display: block;\n"])), Color.Neutral, FontSize.XSmall, FontFamily.Primary, FontWeight.Normal);
export var InputCaptionError = styled(InputCaption)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), Color.Error);
export var InputPlaceholderCss = css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.Gray, FontSize.Medium, FontFamily.Primary, FontWeight.Medium);
export var InputPlaceholder = styled.label(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  pointer-events: none;\n  ", ";\n"], ["\n  pointer-events: none;\n  ", ";\n"])), InputPlaceholderCss);
export var ProductPricePrefix = styled.span(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-weight: ", ";\n"])), Color.GrayDark, FontWeight.Bold);
export var ProductPrice = styled.label(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), function (props) { return (props.isFeatured ? Color.SuccessDark : Color.Black); }, FontSize.XXXLarge, FontFamily.Primary, FontWeight.Bold);
export var ProductOldPrice = styled.label(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: line-through;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  text-decoration: line-through;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.GrayDark, FontSize.Medium, FontFamily.Primary, FontWeight.Medium);
export var DT = styled.dt(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: inline;\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  display: inline;\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.GrayXDark, FontSize.Medium, FontFamily.Primary, FontWeight.Bold);
export var DD = styled.dd(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  display: inline;\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin: 0;\n"], ["\n  display: inline;\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin: 0;\n"])), Color.GrayXDark, FontSize.Medium, FontFamily.Primary, FontWeight.Normal);
// Utilities
export var Ellipsed = styled.span(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: ", ";\n  line-clamp: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n"], ["\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: ", ";\n  line-clamp: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])), function (_a) {
    var maxLines = _a.maxLines;
    return maxLines !== null && maxLines !== void 0 ? maxLines : 1;
}, function (_a) {
    var maxLines = _a.maxLines;
    return maxLines !== null && maxLines !== void 0 ? maxLines : 1;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
