var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color, ColumnMaxWidth, Spacing } from '@atomic/obj.constants';
export var PageBgColorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  min-height: 100%;\n"], ["\n  background-color: ", ";\n  min-height: 100%;\n"])), Color.Background);
export var ColumnMaxWidthWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: ", ";\n  margin-left: auto;\n  margin-right: auto;\n  ", "\n"], ["\n  max-width: ", ";\n  margin-left: auto;\n  margin-right: auto;\n  ", "\n"])), ColumnMaxWidth, function (_a) {
    var gutter = _a.gutter;
    return gutter && "padding: 0 ".concat(Spacing.Medium, ";");
});
var templateObject_1, templateObject_2;
