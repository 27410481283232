var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var _a;
import React from 'react';
import styled from 'styled-components';
import { AppStrings } from '@app/app-strings';
import { formatCNPJ, getMerchantName } from '@app/utils';
import { formatPhone } from '@app/utils';
import { H3, Body } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column } from '@atomic/obj.grid';
var MerchantInfo;
(function (MerchantInfo) {
    MerchantInfo["cnpj"] = "cnpj";
    MerchantInfo["email"] = "email";
    MerchantInfo["phone"] = "phone";
})(MerchantInfo || (MerchantInfo = {}));
var strings = AppStrings.components.merchantItem;
export var MerchantItem = function (props) {
    return (React.createElement(MerchantItemWrapper, { noPadding: props.hideName },
        React.createElement(Column, { flex: 1 },
            !props.hideName && React.createElement(H3, null, getMerchantName(props.merchant)),
            Object.values(MerchantInfo).map(function (info) {
                var value = merchantInfoMapper(props.merchant)[info];
                return (!!value && (React.createElement(Body, { key: info },
                    merchantInfoTitleMapper[info],
                    ": ",
                    value)));
            }))));
};
var merchantInfoMapper = function (merchant) {
    var _a;
    var _b;
    return (_a = {},
        _a[MerchantInfo.email] = (_b = merchant.email) !== null && _b !== void 0 ? _b : '',
        _a[MerchantInfo.cnpj] = merchant.cnpj ? formatCNPJ(merchant.cnpj) : '',
        _a[MerchantInfo.phone] = merchant.phone ? formatPhone(merchant.phone) : '',
        _a);
};
var merchantInfoTitleMapper = (_a = {},
    _a[MerchantInfo.email] = strings.email,
    _a[MerchantInfo.cnpj] = strings.cnpj,
    _a[MerchantInfo.phone] = strings.phone,
    _a);
var MerchantItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), function (_a) {
    var noPadding = _a.noPadding;
    return (noPadding ? 0 : Spacing.Medium);
});
var templateObject_1;
