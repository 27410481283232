var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { buttonHeight } from '@atomic/atm.button/button.component.style';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputValueCss } from '@atomic/atm.typography';
import { clickableFocusCss } from '@atomic/mol.focus/focus.style';
import { Border, Color, IconSize } from '@atomic/obj.constants';
import { fieldBorderCss } from '@atomic/obj.form/field.component.styled';
var iconSize = IconSize.Medium;
export var StepperFieldStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: ", ";\n\n  &:focus-within {\n    ", "\n  }\n\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  input[type='number'] {\n    -moz-appearance: textfield; /* Firefox */\n    appearance: textfield;\n  }\n"], ["\n  border: ", " solid ", ";\n  border-radius: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: ", ";\n\n  &:focus-within {\n    ", "\n  }\n\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  input[type='number'] {\n    -moz-appearance: textfield; /* Firefox */\n    appearance: textfield;\n  }\n"])), Border.Width, Border.Color, Border.RadiusLarge, buttonHeight, clickableFocusCss);
export var StepperFieldSymbolCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", ";\n  line-height: ", ";\n  color: ", ";\n  padding: calc((", " - ", ") / 2);\n  cursor: ", ";\n\n  &:hover {\n    ", "\n  }\n\n  &:active {\n    ", "\n  }\n"], ["\n  width: ", ";\n  line-height: ", ";\n  color: ", ";\n  padding: calc((", " - ", ") / 2);\n  cursor: ", ";\n\n  &:hover {\n    ", "\n  }\n\n  &:active {\n    ", "\n  }\n"])), iconSize, iconSize, function (props) { return (props.disabled ? Color.Gray : Color.Primary); }, buttonHeight, iconSize, function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) { return !props.disabled && "background-color: ".concat(Color.GrayLight, ";"); }, function (props) { return !props.disabled && "background-color: ".concat(Color.Gray, ";"); });
export var StepperFieldMinusStyled = styled(FaIcon.StepperMinus)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  border-top-left-radius: ", ";\n  border-bottom-left-radius: ", ";\n"], ["\n  ", "\n  border-top-left-radius: ", ";\n  border-bottom-left-radius: ", ";\n"])), StepperFieldSymbolCss, Border.RadiusLarge, Border.RadiusLarge);
export var StepperFieldPlusStyled = styled(FaIcon.StepperPlus)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: ", ";\n"], ["\n  ", "\n  border-top-right-radius: ", ";\n  border-bottom-right-radius: ", ";\n"])), StepperFieldSymbolCss, Border.RadiusLarge, Border.RadiusLarge);
export var StepperFieldInputStyled = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  ", "\n  color: ", ";\n  border: none;\n  text-align: center;\n  width: 100%;\n\n  &:focus-visible {\n    outline: 0;\n  }\n"], ["\n  ", "\n  ", "\n  color: ", ";\n  border: none;\n  text-align: center;\n  width: 100%;\n\n  &:focus-visible {\n    outline: 0;\n  }\n"])), InputValueCss, fieldBorderCss, Color.Black);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
