import React from 'react';
import { ScrollState, useScrollEvents } from '@app/domain/scroll-events';
import { NavbarDesktop } from './navbar-desktop.component';
import { NavbarMobile } from './navbar-mobile.component';
import { NavbarHeaderStyled, NavbarSafeAreaStyled, NavbarStyled } from './navbar.component.style';
export var Navbar = function () {
    var scrollState = useScrollEvents().scrollState;
    return (React.createElement(React.Fragment, null,
        React.createElement(NavbarHeaderStyled, null,
            React.createElement(NavbarStyled, { shadow: scrollState !== ScrollState.Initial },
                React.createElement(NavbarDesktop, null),
                React.createElement(NavbarMobile, null))),
        React.createElement(NavbarSafeAreaStyled, null)));
};
