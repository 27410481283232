var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon/fa-icon.component';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Spacing, Color } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
export var SelectWrapperStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  position: relative;\n  padding: 0 ", ";\n\n  &:focus-within {\n    ", "\n  }\n"], ["\n  ", "\n  position: relative;\n  padding: 0 ", ";\n\n  &:focus-within {\n    ", "\n  }\n"])), fieldBorderCss, Spacing.Small, clickableFocusCss);
export var SelectStyled = styled.select(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  appearance: none;\n  border: 0;\n  min-width: 100px;\n  outline: none;\n  ", "\n"], ["\n  appearance: none;\n  border: 0;\n  min-width: 100px;\n  outline: none;\n  ", "\n"])), fieldCss);
export var SelectIconStyled = styled(FaIcon.ChevronDown)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  color: ", ";\n  pointer-events: none;\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  color: ", ";\n  pointer-events: none;\n"])), Spacing.Medium, Spacing.Large, Color.GrayDark);
var templateObject_1, templateObject_2, templateObject_3;
