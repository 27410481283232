import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { BodySecondary } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, HorizontalSpacer, Row } from '@atomic/obj.grid';
export var PriceDisclaimer = function (_a) {
    var text = _a.text;
    return (React.createElement(Row, { noGutter: true, alignItems: 'center' },
        React.createElement(Column, null,
            React.createElement(AppIcon.InfoOutlined, null)),
        React.createElement(HorizontalSpacer, { "$width": Spacing.Small }),
        React.createElement(Column, null,
            React.createElement(BodySecondary, null, text))));
};
