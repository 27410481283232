import { EventType } from '@app/data/analytics/analytics.model';
export var SearchAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'search',
        params: {
            search_term: params.searchTerm,
        },
    };
};
