var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { IcSvgStyled } from '@app/components/atm.app-icon/app-icon.component.style';
export var IcEdit = function (props) { return (React.createElement(IcSvgStyled, __assign({ viewBox: '0 0 24 24' }, props),
    React.createElement("path", { d: 'M11.4585 4H4.16683C3.61429 4 3.08439 4.21071 2.69369 4.58579C2.30299 4.96086 2.0835 5.46957 2.0835 6V20C2.0835 20.5304 2.30299 21.0391 2.69369 21.4142C3.08439 21.7893 3.61429 22 4.16683 22H18.7502C19.3027 22 19.8326 21.7893 20.2233 21.4142C20.614 21.0391 20.8335 20.5304 20.8335 20V13', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }),
    React.createElement("path", { d: 'M19.271 2.49998C19.6854 2.10216 20.2474 1.87866 20.8335 1.87866C21.4195 1.87866 21.9816 2.10216 22.396 2.49998C22.8104 2.89781 23.0432 3.43737 23.0432 3.99998C23.0432 4.56259 22.8104 5.10216 22.396 5.49998L12.5002 15L8.3335 16L9.37516 12L19.271 2.49998Z', stroke: 'currentColor', strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round' }))); };
IcEdit.defaultProps = { size: 'large', variant: 'primary' };
