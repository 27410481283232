var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { Placeholder } from '@app/components/mol.placeholder';
import { useDetailProductList } from '@app/domain/product-list';
import { getListId } from '@app/utils';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { Grid, VerticalSpacer, Column, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { MerchantListShimmer, ShareMerchantCard } from './components';
var strings = AppStrings.productsList.sharePage;
var SharePage = function () {
    var _a, _b;
    var listId = useParams().listId;
    var id = getListId(listId);
    var _c = useDetailProductList({ id: id }), data = _c.data, isLoading = _c.isLoading, error = _c.error, refetch = _c.refetch;
    var merchants = (_a = data === null || data === void 0 ? void 0 : data.merchants) !== null && _a !== void 0 ? _a : [];
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(Placeholder, { icon: 'Success', title: strings.placeholderTitle, description: strings.placeholderDescription }))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    React.createElement(LoadingState, { data: !!((_b = data === null || data === void 0 ? void 0 : data.merchants) === null || _b === void 0 ? void 0 : _b.length), loading: isLoading, error: !!error },
                        React.createElement(LoadingState.Shimmer, null,
                            React.createElement(Divider, null),
                            __spreadArray([], Array(5), true).map(function (_, index) { return (React.createElement(MerchantListShimmer, { key: index })); })),
                        React.createElement(LoadingState.Error, null,
                            React.createElement(Placeholder, { title: strings.errorMessage, icon: 'GenericError' },
                                React.createElement(Row, { noGutter: true, justifyContent: 'center' },
                                    React.createElement(Button.Secondary, { onClick: refetch }, strings.errorButton)))),
                        !!merchants.length && React.createElement(Divider, null),
                        merchants.map(function (merchant) { return (React.createElement(ShareMerchantCard, { key: merchant.id, merchant: merchant, productListId: data === null || data === void 0 ? void 0 : data.id })); })))),
            React.createElement(VerticalSpacer, null))));
};
export default SharePage;
