var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
var badgeColors = {
    primary: Color.Primary,
    white: Color.White,
    black: Color.Black,
};
var badgeTextColors = {
    primary: Color.White,
    white: Color.Black,
    black: Color.White,
};
export var BadgeStyled = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " ", ";\n  border-radius: ", ";\n  border-color: ", ";\n  background-color: ", ";\n  align-self: flex-start;\n  justify-content: center;\n"], ["\n  padding: ", " ", ";\n  border-radius: ", ";\n  border-color: ", ";\n  background-color: ", ";\n  align-self: flex-start;\n  justify-content: center;\n"])), Spacing.XSmall, Spacing.Small, function (props) { return (props.radiusLarge ? Border.RadiusLarge : Border.Radius); }, function (props) { return (props.color ? badgeColors[props.color] : Color.Primary); }, function (props) { return (props.color ? badgeColors[props.color] : Color.Primary); });
export var BadgeTextStyled = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  background-color: ", ";\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), function (props) { return (props.color ? badgeColors[props.color] : Color.Primary); }, function (props) { return (props.color ? badgeTextColors[props.color] : Color.White); }, FontSize.Small, FontFamily.Primary, FontWeight.Bold);
var templateObject_1, templateObject_2;
