var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { AppIcon } from '@app/components/atm.app-icon';
import { clickableFocusCss } from '@atomic/mol.focus';
import { Border, Color, FontFamily, FontSize, FontWeight, HeaderHeight, MediaQuery, Shadow, Spacing, TransitionDuration, ZIndex, } from '@atomic/obj.constants';
export var NavbarHeaderStyled = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: ", ";\n"])), ZIndex.top);
var NavbarHeightCss = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", ";\n\n  ", " {\n    height: ", ";\n  }\n"], ["\n  height: ", ";\n\n  ", " {\n    height: ", ";\n  }\n"])), HeaderHeight.Mobile, MediaQuery.md, HeaderHeight.Desk);
export var NavbarStyled = styled.nav(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), NavbarHeightCss, function (_a) {
    var shadow = _a.shadow;
    return shadow && "box-shadow: ".concat(Shadow.Small, ";");
});
export var NavbarSafeAreaStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), NavbarHeightCss);
export var NavbarMobileStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 100%;\n  background-color: ", ";\n\n  ", " {\n    display: none;\n    visibility: hidden;\n  }\n"], ["\n  height: 100%;\n  background-color: ", ";\n\n  ", " {\n    display: none;\n    visibility: hidden;\n  }\n"])), Color.White, MediaQuery.md);
export var NavbarMenuStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), Color.Primary);
export var NavbarLinkWrapperStyled = styled.li(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  list-style: none;\n  padding: ", " ", ";\n  border-radius: ", ";\n\n  ", "\n\n  &:focus-within {\n    ", "\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  list-style: none;\n  padding: ", " ", ";\n  border-radius: ", ";\n\n  ", "\n\n  &:focus-within {\n    ", "\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), Spacing.Small, Spacing.Medium, Border.RadiusLarge, function (_a) {
    var selected = _a.selected;
    return selected && "background-color: ".concat(Color.Accessory, ";");
}, clickableFocusCss, Color.GrayLight);
export var DrawerItemWrapperStyled = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var NavbarMenuLinkStyled = styled.a(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  cursor: pointer;\n  text-decoration: none;\n  color: ", ";\n"], ["\n  font-family: ", ";\n  font-weight: ", ";\n  font-size: ", ";\n  cursor: pointer;\n  text-decoration: none;\n  color: ", ";\n"])), FontFamily.Primary, FontWeight.Medium, FontSize.Medium, Color.Primary);
export var NavbarLinkStyled = styled(NavbarMenuLinkStyled).attrs(function (_a) {
    var external = _a.external;
    return external && {
        target: '_blank',
        rel: 'noopener',
    };
})(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-weight: ", ";\n  color: ", ";\n\n  &:focus {\n    outline: 0;\n  }\n"], ["\n  font-weight: ", ";\n  color: ", ";\n\n  &:focus {\n    outline: 0;\n  }\n"])), FontWeight.Bold, function (_a) {
    var selected = _a.selected;
    return (selected ? Color.Primary : Color.PrimaryLight);
});
export var NavbarLogoLinkStyled = styled.a(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  &:focus-visible {\n    ", "\n  }\n"], ["\n  &:focus-visible {\n    ", "\n  }\n"])), clickableFocusCss);
export var NavbarDesktopListStyled = styled.ul(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: none;\n  visibility: hidden;\n\n  ", " {\n    display: flex;\n    height: 100%;\n    margin: 0;\n    padding: 0 ", ";\n    visibility: initial;\n    background-color: ", ";\n  }\n"], ["\n  display: none;\n  visibility: hidden;\n\n  ", " {\n    display: flex;\n    height: 100%;\n    margin: 0;\n    padding: 0 ", ";\n    visibility: initial;\n    background-color: ", ";\n  }\n"])), MediaQuery.md, Spacing.Medium, Color.White);
export var NavbarAnimatedChevronStyled = styled(AppIcon.ChevronDown)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", "\n\n  @media (prefers-reduced-motion: no-preference) {\n    -moz-transition: -moz-transform ", " ease-in-out;\n    -ms-transition: -ms-transform ", " ease-in-out;\n    -o-transition: -o-transform ", " ease-in-out;\n    -webkit-transition: -webkit-transform ", " ease-in-out;\n    transition: transform ", " ease-in-out;\n  }\n"], ["\n  ", "\n\n  @media (prefers-reduced-motion: no-preference) {\n    -moz-transition: -moz-transform ", " ease-in-out;\n    -ms-transition: -ms-transform ", " ease-in-out;\n    -o-transition: -o-transform ", " ease-in-out;\n    -webkit-transition: -webkit-transform ", " ease-in-out;\n    transition: transform ", " ease-in-out;\n  }\n"])), function (_a) {
    var selected = _a.selected;
    return selected && css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      -moz-transform: rotate(180deg);\n      -ms-transform: rotate(180deg);\n      -o-transform: rotate(180deg);\n      -webkit-transform: rotate(180deg);\n      transform: rotate(180deg);\n    "], ["\n      -moz-transform: rotate(180deg);\n      -ms-transform: rotate(180deg);\n      -o-transform: rotate(180deg);\n      -webkit-transform: rotate(180deg);\n      transform: rotate(180deg);\n    "])));
}, TransitionDuration, TransitionDuration, TransitionDuration, TransitionDuration, TransitionDuration);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
