import React from 'react';
import jwt_decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { Navbar } from '@app/components/mol.navbar';
import { useFlashMessage } from '@app/components/mol.provider';
import { useAuthStorage } from '@app/domain/authentication/auth-storage.use-case';
import { AuthenticationRoutes } from '@app/modules/authentication/authentication.routes';
export var LoginGuard = function (_a) {
    var children = _a.children;
    var _b = useAuthStorage(), auth = _b.auth, setAuth = _b.setAuth;
    var showWarning = useFlashMessage().showWarning;
    if ((auth === null || auth === void 0 ? void 0 : auth.loggedOut) || !isTokenValid(auth === null || auth === void 0 ? void 0 : auth.token)) {
        if (!(auth === null || auth === void 0 ? void 0 : auth.loggedOut)) {
            showWarning(AppStrings.authentication.expiredTokenMessage);
            setAuth({ loggedOut: true });
        }
        return React.createElement(Navigate, { to: AuthenticationRoutes.Base });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar, null),
        children));
};
var getTokenData = function (token) {
    return jwt_decode(token.replace('Bearer ', ''));
};
var CONVERT_UNIX_TO_DATE = 1000;
var fromUnix = function (exp) { return exp * CONVERT_UNIX_TO_DATE; };
var isTokenValid = function (token) {
    if (!token) {
        return false;
    }
    var exp = getTokenData(token).exp;
    var isExpired = fromUnix(exp) < Date.now();
    return !isExpired;
};
