var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';
var HANDLE_DIAMETER = 26;
var SWITCH_HEIGHT = 28;
var SWITCH_WIDTH = 56;
export var SwitchRootStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  border-radius: ", "px;\n  opacity: ", ";\n  text-align: left;\n  transition: opacity 0.25s;\n  user-select: none;\n  touch-action: none;\n  -webkit-transition: opacity 0.25s;\n  -moz-transition: opacity 0.25s;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n"], ["\n  display: inline-block;\n  position: relative;\n  border-radius: ", "px;\n  opacity: ", ";\n  text-align: left;\n  transition: opacity 0.25s;\n  user-select: none;\n  touch-action: none;\n  -webkit-transition: opacity 0.25s;\n  -moz-transition: opacity 0.25s;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n"])), SWITCH_HEIGHT / 2, function (props) { return (props.disabled ? 0.5 : 1); });
export var SwitchBackgroundStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: ", "px;\n  width: ", "px;\n  margin: ", "px;\n  background-color: ", ";\n  border-radius: ", "px;\n  cursor: ", ";\n  -webkit-transition: background-color 0.25s;\n  -moz-transition: background-color 0.25s;\n  transition: background-color 0.25s;\n"], ["\n  position: relative;\n  height: ", "px;\n  width: ", "px;\n  margin: ", "px;\n  background-color: ", ";\n  border-radius: ", "px;\n  cursor: ", ";\n  -webkit-transition: background-color 0.25s;\n  -moz-transition: background-color 0.25s;\n  transition: background-color 0.25s;\n"])), SWITCH_HEIGHT, SWITCH_WIDTH, Math.max(0, (HANDLE_DIAMETER - SWITCH_HEIGHT) / 2), function (props) { return (props.checked ? Color.Primary : Color.Gray); }, SWITCH_HEIGHT / 2, function (props) { return (props.disabled ? 'default' : 'pointer'); });
export var SwitchHandleStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  position: absolute;\n  top: ", "px;\n  height: ", "px;\n  width: ", "px;\n  outline: 0;\n  background-color: ", ";\n  border: 0;\n  border-radius: 50%;\n  cursor: ", ";\n  transform: translate(", "px);\n  -webkit-transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;\n  -moz-transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;\n  transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;\n"], ["\n  display: inline-block;\n  position: absolute;\n  top: ", "px;\n  height: ", "px;\n  width: ", "px;\n  outline: 0;\n  background-color: ", ";\n  border: 0;\n  border-radius: 50%;\n  cursor: ", ";\n  transform: translate(", "px);\n  -webkit-transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;\n  -moz-transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;\n  transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;\n"])), Math.max(0, (28 - HANDLE_DIAMETER) / 2), HANDLE_DIAMETER, HANDLE_DIAMETER, Color.White, function (props) { return (props.disabled ? 'default' : 'pointer'); }, function (props) { return (props.checked ? SWITCH_WIDTH / 2 : 1); });
var templateObject_1, templateObject_2, templateObject_3;
