import * as React from 'react';
import { TabItemStyled, TabItemTextStyled, TabStyled } from './tab.component.style';
var TabItem = function (props) {
    var handleClick = function () {
        var _a;
        (_a = props.onTap) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    return (React.createElement(TabItemStyled, { onClick: handleClick, active: props.isActiveItem },
        React.createElement(TabItemTextStyled, { active: props.isActiveItem }, props.children)));
};
export var Tab = function (props) {
    var _a = React.useState(props.initialIndex), activeIndex = _a[0], setActiveIndex = _a[1];
    var handleTap = React.useCallback(function (index) {
        var _a;
        if (index !== activeIndex) {
            setActiveIndex(index);
            (_a = props.onIndexChanged) === null || _a === void 0 ? void 0 : _a.call(props, index);
        }
    }, [activeIndex, props]);
    var filteredChildren = React.useMemo(function () {
        return React.Children.map(props.children, function (child, index) {
            if (child.type === TabItem) {
                return React.cloneElement(child, {
                    isActiveItem: index === activeIndex,
                    onTap: function () { return handleTap(index); },
                });
            }
            throw new Error('Childrens type must be the same as the TabItem type');
        });
    }, [props.children, activeIndex, handleTap]);
    return React.createElement(TabStyled, null, filteredChildren);
};
Tab.defaultProps = {
    initialIndex: 0,
};
Tab.Item = TabItem;
