var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { trpc } from '@app/core/trpc';
import { AnalyticsService } from '@app/data/analytics';
import { useAuthStorage } from './auth-storage.use-case';
export var useLogin = function (params) {
    if (params === void 0) { params = {}; }
    var setAuth = useAuthStorage().setAuth;
    var _a = trpc.user.login.useMutation({
        onSuccess: function (response) {
            var _a;
            setAuth(__assign({ loggedOut: false }, response));
            AnalyticsService.setUserId(response.user.id.toString());
            (_a = params.onSuccess) === null || _a === void 0 ? void 0 : _a.call(params, response);
        },
        onError: params.onError,
    }), mutate = _a.mutate, data = _a.data, isLoading = _a.isLoading, error = _a.error;
    return {
        mutate: mutate,
        data: data,
        isLoading: isLoading,
        error: error,
    };
};
