import React from 'react';
import QRCode from 'react-qr-code';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { KeyboardKeys, useKeyboardListener } from '@app/domain/keyboard-listener';
import { Button } from '@atomic/atm.button';
import { Divider } from '@atomic/atm.divider';
import { Body } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { HorizontalSpacer, Row, VerticalSpacer } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
var strings = AppStrings.productsList.whatsappModal;
export var WhatsappModal = function (props) {
    var _a;
    useKeyboardListener((_a = {}, _a[KeyboardKeys.Escape] = props.onClose, _a));
    return (React.createElement(Modal, { opened: props.opened, onClose: props.onClose, title: strings.title, small: true },
        React.createElement(VerticalSpacer, null),
        React.createElement(Row, { justifyContent: 'center' },
            React.createElement(QRCode, { value: props.whatsappUrl })),
        React.createElement(VerticalSpacer, { "$height": Spacing.XLarge }),
        React.createElement(Row, null,
            React.createElement(Body, null, strings.description)),
        React.createElement(VerticalSpacer, null),
        React.createElement(Divider, null),
        React.createElement(VerticalSpacer, null),
        React.createElement(Row, { alignItems: 'center', justifyContent: 'flex-end' },
            React.createElement(Button.Secondary, { href: props.whatsappUrl, target: '_blank', onClick: props.onRedirect },
                React.createElement(AppIcon.Whatsapp, null),
                strings.share),
            React.createElement(HorizontalSpacer, null),
            React.createElement(Button.Primary, { onClick: props.onClose }, strings.close))));
};
