var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { TextField } from '@atomic/atm.text-field';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { SearchButtonStyled } from './search-bar.component.style';
export var SearchBar = function (props) {
    var onSearch = props.onSearch, inputProps = __rest(props, ["onSearch"]);
    var handleValueChange = function (value) {
        var _a;
        (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, value);
        if (value === '') {
            onSearch('');
        }
    };
    var handleSubmit = function (formData) {
        var _a;
        onSearch((_a = formData.data.search) !== null && _a !== void 0 ? _a : '');
    };
    return (React.createElement(Form, { onSubmit: handleSubmit },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, null,
                React.createElement(Form.Field, { name: 'search' },
                    React.createElement(TextField, __assign({ icon: 'search', dismissable: true }, inputProps, { onValueChange: handleValueChange })))),
            React.createElement(Hbox.Separator, null),
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(SearchButtonStyled, { variant: 'primary', type: 'submit' },
                    React.createElement(AppIcon.Search, null))))));
};
