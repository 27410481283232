import { EventType } from '@app/data/analytics/analytics.model';
export var ShareAnalytics = function (params) {
    var _a;
    return {
        type: EventType.EVENT,
        eventName: 'share',
        params: {
            content_type: params.contentType,
            item_id: params.itemId,
            method: params.method,
            clients: (_a = params.clients) !== null && _a !== void 0 ? _a : [params.client],
            platform: params.isMobile ? 'mobile' : 'desktop',
        },
    };
};
