import * as React from 'react';
import { DrawerMenuStyled, DrawerOverlayStyled, DrawerStyled } from './drawer.component.style';
export var Drawer = function (props) {
    var drawerRef = React.useRef(null);
    React.useEffect(function () {
        var drawerElement = drawerRef.current;
        if (props.active && !!drawerElement) {
            drawerElement.style.display = 'block';
            drawerElement.style.visibility = 'visible';
        }
        else if (!!drawerElement) {
            setTimeout(function () {
                drawerElement.style.display = 'none';
                drawerElement.style.visibility = 'hidden';
            }, 500);
        }
    }, [props.active]);
    return (React.createElement(DrawerStyled, { ref: drawerRef, active: props.active },
        React.createElement(DrawerMenuStyled, { active: props.active }, props.children),
        React.createElement(DrawerOverlayStyled, { active: props.active, onClick: props.onOverlayClick })));
};
