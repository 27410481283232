import React from 'react';
import { Card } from '@atomic/atm.card';
import { Divider } from '@atomic/atm.divider';
import { TextShimmerBoxStyled, getRandomPercentage } from '@atomic/mol.shimmer';
import { Spacing } from '@atomic/obj.constants';
import { VerticalSpacer, Column, Row } from '@atomic/obj.grid';
export var ProductsListShimmer = function () { return (React.createElement(Card, { noGutter: true },
    React.createElement(VerticalSpacer, { "$height": Spacing.Large }),
    React.createElement(Row, null,
        React.createElement(TextShimmerBoxStyled, { height: '22px', width: getRandomPercentage(50, 100), margin: '0' })),
    React.createElement(VerticalSpacer, null),
    React.createElement(Divider, null),
    React.createElement(VerticalSpacer, null),
    React.createElement(Row, null,
        React.createElement(Column, { flex: 1 },
            React.createElement(TextShimmerBoxStyled, { height: '16px', width: getRandomPercentage(50, 100), margin: '0' }),
            React.createElement(VerticalSpacer, null),
            React.createElement(TextShimmerBoxStyled, { height: '44px', margin: '0' }))),
    React.createElement(VerticalSpacer, null))); };
