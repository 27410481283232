import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { useFlashMessage } from '@app/components/mol.provider';
import { SearchBar } from '@app/components/mol.search-bar';
import { AddToCartAnalytics, AnalyticsService, AppErrorAnalytics, RemoveFromCartAnalytics, SearchAnalytics, } from '@app/data/analytics';
import { useDraft } from '@app/domain/draft';
import { useProductSearch } from '@app/domain/product';
import { useDetailProductList, useUpsertProductList } from '@app/domain/product-list';
import { Distributors } from '@app/model/distributor.model';
import { formatPrice, getListId } from '@app/utils';
import { BodySecondary } from '@atomic/atm.typography';
import { Column, Grid, Row, VerticalSpacer } from '@atomic/obj.grid';
import { PriceDisclaimer, DistributorEditDisclaimer, EditableTitle, EditButton, ProductsListing, MerchantButton, ResponsiveButton, ListQuantity, ProductListWrapperStyled, } from './components';
import { NEW_LIST_ID, ProductsListRoutes } from './products-list.routes';
var strings = AppStrings.productsList.productListEditPage;
var ProductsListEditPage = function () {
    var _a, _b, _c, _d;
    var _e = React.useState(''), search = _e[0], setSearch = _e[1];
    var _f = React.useState(1), searchPage = _f[0], setSearchPage = _f[1];
    var _g = useFlashMessage(), showAlert = _g.showAlert, showWarning = _g.showWarning, showSuccess = _g.showSuccess;
    var _h = useDraft(), draftItems = _h.items, draftMerchants = _h.merchants, draftDistributor = _h.distributor, totalPrice = _h.totalPrice, draftId = _h.id, draftTitle = _h.title, addProducts = _h.addProducts, changeProductsQuantity = _h.changeProductsQuantity, removeProducts = _h.removeProducts, setTitle = _h.setTitle, setDraft = _h.setDraft;
    var listTitle = draftTitle !== null && draftTitle !== void 0 ? draftTitle : strings.defaultListName;
    var navigate = useNavigate();
    var listId = useParams().listId;
    var location = useLocation();
    React.useEffect(function () {
        var isPageNewList = listId === NEW_LIST_ID;
        var isDraftNewList = draftId === NEW_LIST_ID;
        if (isPageNewList && !isDraftNewList) {
            setDraft({ id: NEW_LIST_ID });
        }
    }, [draftId, setDraft, listId]);
    var handleDetailProductListSuccess = function (productList) {
        var id = productList.id, title = productList.title, items = productList.items, merchants = productList.merchants;
        setDraft({ id: id, title: title, items: items, merchants: merchants !== null && merchants !== void 0 ? merchants : [], distributor: draftDistributor });
    };
    var isFetching = useDetailProductList({
        id: getListId(listId),
        skip: getListId(listId) === draftId,
        onSuccess: handleDetailProductListSuccess,
    }).isFetching;
    var _j = useProductSearch({
        searchTerm: search,
        page: searchPage,
        merchants: draftMerchants,
        distributorId: draftDistributor,
    }), searchResult = _j.data, isLoading = _j.isLoading, error = _j.error, refetch = _j.refetch;
    var _k = useUpsertProductList({
        onSuccess: function (_a) {
            var id = _a.id;
            navigate(ProductsListRoutes.PricePreview(id.toString()));
        },
        onError: function () { return showAlert(strings.genericErrorMessage); },
    }), mutate = _k.mutate, submitIsLoading = _k.isLoading;
    var sendAppErrorAnalytics = function (message) {
        return AnalyticsService.send(AppErrorAnalytics({ message: message, type: 'UserError' }));
    };
    var handleSearch = function (value) {
        updateSearch(value);
    };
    var updateSearch = function (value) {
        if (!draftDistributor) {
            sendAppErrorAnalytics(strings.noDistributorMessage);
            showWarning(strings.noDistributorMessage);
            return;
        }
        setSearchPage(1);
        setSearch(value);
        AnalyticsService.send(SearchAnalytics({ searchTerm: value }));
    };
    var handleRemoveFromList = function (sku) {
        var item = getCurrentItem(draftItems, sku);
        if (item) {
            AnalyticsService.send(RemoveFromCartAnalytics({ product: item, previousQuantity: item.quantity, newQuantity: 0 }));
        }
        removeProducts(sku);
    };
    var handleQuantityChange = function (item, qty) {
        var previousQuantity = getCurrentItemQuantity(draftItems, item.sku);
        if (qty > previousQuantity) {
            AnalyticsService.send(AddToCartAnalytics({ product: item, previousQuantity: previousQuantity, newQuantity: qty }));
        }
        else {
            AnalyticsService.send(RemoveFromCartAnalytics({ product: item, previousQuantity: previousQuantity, newQuantity: qty }));
        }
        changeProductsQuantity(item, qty);
    };
    var handleAddToList = function (item, qty) {
        if (qty) {
            var previousQuantity = getCurrentItemQuantity(draftItems, item.sku);
            AnalyticsService.send(AddToCartAnalytics({ product: item, previousQuantity: previousQuantity, newQuantity: qty }));
            addProducts(item, qty);
            showSuccess(strings.addItemsSuccessMessage);
        }
        else {
            sendAppErrorAnalytics(strings.addItemWithNoQuantityMessage);
            showWarning(strings.addItemWithNoQuantityMessage);
        }
    };
    var handleMerchantClick = function () {
        return navigate(ProductsListRoutes.Merchants(listId), { state: { nextRoute: location.pathname } });
    };
    var handleDistributorClick = function () {
        return navigate(ProductsListRoutes.Distributors(listId), { state: { nextRoute: location.pathname } });
    };
    var handleSubmit = function () {
        if (draftItems.length === 0 || draftMerchants.length === 0) {
            sendAppErrorAnalytics(strings.noProductsMessage);
            showWarning(strings.noProductsMessage);
            return;
        }
        if (!draftDistributor) {
            sendAppErrorAnalytics(strings.noDistributorMessage);
            showWarning(strings.noDistributorMessage);
            return;
        }
        if (draftMerchants.length < 1) {
            sendAppErrorAnalytics(strings.noMerchantsMessage);
            showWarning(strings.noMerchantsMessage);
            return;
        }
        if (draftItems.some(function (item) { return item.quantity < 1; })) {
            sendAppErrorAnalytics(strings.noQuantityProductsMessage);
            showWarning(strings.noQuantityProductsMessage);
            return;
        }
        if (!listId) {
            return;
        }
        var data = {
            title: listTitle,
            items: draftItems.map(function (item) { return ({ sku: item.sku, quantity: item.quantity }); }),
            merchantsIds: draftMerchants.map(function (item) { return item.id; }),
        };
        mutate(data, listId);
    };
    var distributorName = (_b = (_a = Object.entries(Distributors).find(function (distributor) { return distributor[1] === draftDistributor; })) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '';
    var submitButtonText = strings.submitButton(formatPrice(totalPrice));
    var submitButtonDisabled = !draftItems.length || !draftMerchants.length;
    var searchItems = (search === null || search === void 0 ? void 0 : search.length) > 0 ? (_c = searchResult === null || searchResult === void 0 ? void 0 : searchResult.nodes) !== null && _c !== void 0 ? _c : [] : [];
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(EditableTitle, { title: listTitle, onChange: setTitle }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(EditButton, { values: distributorName, onClick: handleDistributorClick }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(DistributorEditDisclaimer, { text: strings.distributorEditDisclaimer }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(MerchantButton, { merchants: draftMerchants, onClick: handleMerchantClick }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(PriceDisclaimer, { text: strings.priceDisclaimer }),
                        React.createElement(ListQuantity, { quantity: draftItems.length }),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(ResponsiveButton, { text: submitButtonText, onClick: handleSubmit, disabled: submitButtonDisabled, loading: submitIsLoading }))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    React.createElement(SearchBar, { placeholder: strings.searchPlaceholder, onSearch: handleSearch, initialValue: search, key: search }),
                    React.createElement(BodySecondary, null, strings.searchDescription),
                    React.createElement(VerticalSpacer, null),
                    React.createElement(ProductListWrapperStyled, null,
                        React.createElement(ProductsListing, { items: searchItems, loading: isLoading, error: error, title: strings.searchQuantity((_d = searchResult === null || searchResult === void 0 ? void 0 : searchResult.count) !== null && _d !== void 0 ? _d : 0), refetch: refetch, onAddToList: handleAddToList, onNextPageTap: (searchResult === null || searchResult === void 0 ? void 0 : searchResult.pageInfo.hasNextPage) ? function () { return setSearchPage(function (cur) { return cur + 1; }); } : undefined })),
                    React.createElement(VerticalSpacer, null),
                    React.createElement(ProductListWrapperStyled, null,
                        React.createElement(ProductsListing, { items: draftItems, loading: isFetching, title: strings.listQuantity(draftItems.length), onQuantityChange: handleQuantityChange, onRemoveFromList: handleRemoveFromList })))),
            React.createElement(VerticalSpacer, null)),
        React.createElement(ResponsiveButton, { text: submitButtonText, onClick: handleSubmit, disabled: submitButtonDisabled, loading: submitIsLoading, isSticky: true })));
};
var getCurrentItem = function (items, sku) { return items.find(function (item) { return item.sku === sku; }); };
var getCurrentItemQuantity = function (items, sku) { var _a, _b; return (_b = (_a = getCurrentItem(items, sku)) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 0; };
export default ProductsListEditPage;
