import { EventType } from '@app/data/analytics/analytics.model';
export var MassShareAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'mass_share',
        params: {
            noPhoneRatio: params.noPhoneRatio,
            cartFailRatio: params.cartFailRatio,
            messageFailRatio: params.messageFailRatio,
            successRatio: params.successRatio,
        },
    };
};
