import { EventType } from '@app/data/analytics/analytics.model';
import { mapProductSearchItemToAnalytics } from './item.mapper';
export var AddToCartAnalytics = function (params) {
    var addedQuantity = params.newQuantity - params.previousQuantity;
    var product = params.product;
    return {
        type: EventType.EVENT,
        eventName: 'add_to_cart',
        params: {
            currency: 'BRL',
            value: product.price !== null ? (product.price / 100) * addedQuantity : undefined,
            items: [mapProductSearchItemToAnalytics(product, addedQuantity)],
        },
    };
};
