import * as React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { Body, H1 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Row } from '@atomic/obj.grid';
import { PlaceholderColStyled } from './placeholder.component.style';
export var Placeholder = function (props) {
    var Icon = AppIcon[props.icon];
    return (React.createElement(Row, { noGutter: true, justifyContent: 'center' },
        React.createElement(PlaceholderColStyled, { wrap: 'wrap' },
            React.createElement(Separator, null),
            React.createElement(Icon, null),
            React.createElement(Separator, null),
            React.createElement(H1, { as: 'label' }, props.title),
            props.description && React.createElement(Body, null, props.description),
            React.createElement(Separator, null),
            props.children)));
};
