var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, keyframes } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Color, IconSize, Spacing, TransitionDuration } from '@atomic/obj.constants';
export var ModalStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  visibility: ", ";\n"], ["\n  position: absolute;\n  visibility: ", ";\n"])), function (props) { return (props.opened ? 'visible' : 'hidden'); });
var slideDown = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    transform: translate(-50%, -100%);\n  }\n"], ["\n  0% {\n    transform: translate(-50%, -100%);\n  }\n"])));
var fadeIn = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"], ["\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n"])));
export var ModalOpacityStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n  opacity: ", ";\n  transition: all 0.2s ease-out;\n  visibility: ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n  opacity: ", ";\n  transition: all 0.2s ease-out;\n  visibility: ", ";\n"])), Color.Black, function (props) { return (props.opened ? '0.5' : '0'); }, function (props) { return (props.opened ? 'visible' : 'hidden'); });
var modalBoxCommonCss = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: fixed;\n  top: 80px;\n  left: 50%;\n  max-width: 90%;\n  max-height: 80%;\n  background-color: ", ";\n  border-radius: ", ";\n  overflow: auto;\n  overscroll-behavior: contain;\n\n  transform: translate(-50%, 0);\n  animation-duration: ", ";\n  animation-timing-function: ease-out;\n  animation-name: ", ";\n\n  @media (prefers-reduced-motion: no-preference) {\n    animation-name: ", ";\n    animation-name: ", ";\n  }\n"], ["\n  position: fixed;\n  top: 80px;\n  left: 50%;\n  max-width: 90%;\n  max-height: 80%;\n  background-color: ", ";\n  border-radius: ", ";\n  overflow: auto;\n  overscroll-behavior: contain;\n\n  transform: translate(-50%, 0);\n  animation-duration: ", ";\n  animation-timing-function: ease-out;\n  animation-name: ", ";\n\n  @media (prefers-reduced-motion: no-preference) {\n    animation-name: ", ";\n    animation-name: ", ";\n  }\n"])), Color.White, Border.Radius, TransitionDuration, fadeIn, fadeIn, slideDown);
export var ModalBoxStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: ", ";\n\n  ", "\n"], ["\n  width: ", ";\n\n  ", "\n"])), function (props) { return (props.small ? '480px' : '90%'); }, modalBoxCommonCss);
export var ImageModalBoxStyled = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n\n  ", "\n"], ["\n  ", ";\n\n  ", "\n"])), function (props) { return props.aspectRatio && "aspect-ratio: ".concat(props.aspectRatio); }, modalBoxCommonCss);
export var ModalCloseStyled = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  color: ", ";\n"], ["\n  position: absolute;\n  top: ", ";\n  right: ", ";\n  color: ", ";\n"])), Spacing.Medium, Spacing.Medium, Color.Primary);
export var CloseIconStyled = styled(FaIcon.Close)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  cursor: pointer;\n  height: ", ";\n  width: ", ";\n"], ["\n  cursor: pointer;\n  height: ", ";\n  width: ", ";\n"])), IconSize.Large, IconSize.Large);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
