import * as React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { InputCaption, InputCaptionError } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
export var FormFieldCaption = function (props) {
    var _a;
    var errors = (_a = props.errors) !== null && _a !== void 0 ? _a : [];
    var wrap = errors.map(function (error, index) { return (React.createElement(CaptionWithIcon, { key: error.name + index, error: true },
        React.createElement(InputCaptionError, null, error.message))); });
    return props.showAll ? (React.createElement("div", null, wrap)) : (React.createElement(React.Fragment, null, errors.length > 0 ? (React.createElement(CaptionWithIcon, { error: true },
        React.createElement(InputCaptionError, { key: errors[0].name }, errors[0].message))) : (props.validationPlaceholder && (React.createElement(CaptionWithIcon, null,
        React.createElement(InputCaption, null, props.validationPlaceholder))))));
};
var CaptionWithIcon = function (_a) {
    var error = _a.error, children = _a.children;
    var Icon = error ? AppIcon.Alert : AppIcon.InfoFilled;
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { noGrow: true },
            React.createElement(Icon, null)),
        React.createElement(Hbox.Separator, { size: Spacing.XSmall }),
        React.createElement(Hbox.Item, null, children)));
};
