import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { AppIcon } from '@app/components/atm.app-icon';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { ConfirmationModal } from '@app/components/mol.confirmation-modal';
import { AnalyticsService, SelectContentAnalytics, ViewModalAnalytics } from '@app/data/analytics';
import { useDraft } from '@app/domain/draft';
import { useDetailProductList } from '@app/domain/product-list';
import { addQuantityToProductItems, formatPrice, getListId, getProductListProperties } from '@app/utils';
import { Button } from '@atomic/atm.button';
import { BodySecondary, Ellipsed, H1 } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { Column, Grid, Row, VerticalSpacer } from '@atomic/obj.grid';
import { PriceDisclaimer, ProductsListing } from './components';
import { NEW_LIST_ID, ProductsListRoutes } from './products-list.routes';
var strings = AppStrings.productsList.productListDetailsPage;
var ProductsListDetailsPage = function () {
    var _a;
    var _b = React.useState(false), opened = _b[0], setOpened = _b[1];
    var newDraftIdRef = React.useRef(null);
    var _c = useDraft(), hasDraft = _c.hasDraft, setDraft = _c.setDraft;
    var navigate = useNavigate();
    var listId = useParams().listId;
    var id = getListId(listId);
    var _d = useDetailProductList({ id: id }), data = _d.data, isLoading = _d.isLoading, error = _d.error, refetch = _d.refetch;
    var items = (_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : [];
    var _e = getProductListProperties(data), merchantNames = _e.merchantNames, totalPrice = _e.totalPrice, isMadeByMe = _e.isMadeByMe;
    var handleShareTap = function () {
        if (data) {
            AnalyticsService.send(SelectContentAnalytics({
                contentType: 'share_user_list',
                itemId: data.id.toString(),
                itemName: data.title,
            }));
        }
        navigate(ProductsListRoutes.PricePreview(listId));
    };
    var handleEditTap = function () {
        newDraftIdRef.current = id;
        if (data) {
            AnalyticsService.send(SelectContentAnalytics({
                contentType: 'edit_user_list',
                itemId: data.id.toString(),
                itemName: data.title,
            }));
        }
        if (hasDraft) {
            openConfirmationModal();
            return;
        }
        navigateToList();
    };
    var handleUseListTap = function () {
        newDraftIdRef.current = NEW_LIST_ID;
        if (data) {
            AnalyticsService.send(SelectContentAnalytics({
                contentType: isMadeByMe ? 'user_list' : 'suggested_list',
                itemId: data.id.toString(),
                itemName: data.title,
            }));
        }
        if (hasDraft) {
            openConfirmationModal();
            return;
        }
        navigateToList();
    };
    var navigateToList = function () {
        var _a;
        if (!newDraftIdRef.current) {
            return;
        }
        var isNewList = newDraftIdRef.current === NEW_LIST_ID;
        setDraft({
            id: newDraftIdRef.current,
            title: (data === null || data === void 0 ? void 0 : data.title) + (isNewList ? strings.listNameSufix : ''),
            items: addQuantityToProductItems(items),
            merchants: !isNewList && ((_a = data === null || data === void 0 ? void 0 : data.merchants) === null || _a === void 0 ? void 0 : _a.length) ? data.merchants : [],
        });
        navigate(ProductsListRoutes.Distributors(newDraftIdRef.current.toString()), {
            state: { nextRoute: ProductsListRoutes.Merchants(newDraftIdRef.current.toString()) },
        });
    };
    var openConfirmationModal = function () {
        setOpened(true);
        AnalyticsService.send(ViewModalAnalytics({ name: 'exclude_draft' }));
    };
    var handleConfirm = function () {
        setOpened(false);
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'exclude_draft' }));
        navigateToList();
    };
    var handleClose = function () {
        newDraftIdRef.current = null;
        setOpened(false);
    };
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true, gap: { xs: Spacing.Medium, md: 0 } },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(H1, null, data === null || data === void 0 ? void 0 : data.title),
                        !!merchantNames.length && (React.createElement(React.Fragment, null,
                            React.createElement(BodySecondary, null,
                                React.createElement(Ellipsed, { maxLines: 3 }, strings.sentTo(merchantNames))),
                            React.createElement(VerticalSpacer, null))),
                        React.createElement(PriceDisclaimer, { text: strings.priceDisclaimer }),
                        React.createElement(VerticalSpacer, null),
                        isMadeByMe && (React.createElement(React.Fragment, null,
                            React.createElement(Column, { gap: Spacing.Medium, responsive: true },
                                React.createElement(Button.Secondary, { onClick: handleEditTap, expanded: true },
                                    React.createElement(AppIcon.Edit, null),
                                    strings.editButton),
                                React.createElement(Button.Secondary, { onClick: handleShareTap, expanded: true },
                                    React.createElement(AppIcon.Share, null),
                                    strings.shareButton)),
                            React.createElement(VerticalSpacer, null))),
                        React.createElement(Button.CallToAction, { onClick: handleUseListTap, expanded: true }, strings.useListButton(isMadeByMe ? formatPrice(totalPrice) : undefined)))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    React.createElement(ProductsListing, { items: items, loading: isLoading, error: error, title: strings.listQuantity(items.length), refetch: refetch }))),
            React.createElement(VerticalSpacer, null)),
        React.createElement(ConfirmationModal, { opened: opened, onClose: handleClose, onConfirm: handleConfirm })));
};
export default ProductsListDetailsPage;
