import * as React from 'react';
import { Switch } from '@atomic/atm.switch';
import { H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
export var SwitchCell = function (props) {
    var _a;
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { vAlign: 'center' },
            React.createElement(H3, null, props.title)),
        React.createElement(Hbox.Item, { vAlign: 'center', noGrow: true },
            React.createElement(Switch, { checked: props.checked, onChange: props.onChange, id: (_a = props.id) !== null && _a !== void 0 ? _a : 0 }))));
};
