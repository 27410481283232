var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { AnchorButtonStyled, ButtonContentStyled, ButtonSpinnerStyled, ButtonStyled, LinkButtonStyled, } from './button.component.style';
var ButtonBase = /** @class */ (function (_super) {
    __extends(ButtonBase, _super);
    function ButtonBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ButtonBase.prototype.render = function () {
        var ButtonComponent = ButtonStyled;
        if (this.props.to || this.props.navigationState) {
            ButtonComponent = LinkButtonStyled;
        }
        if (this.props.href) {
            ButtonComponent = AnchorButtonStyled;
        }
        return getButton(ButtonComponent, this.props);
    };
    ButtonBase.defaultProps = {
        disabled: false,
        expanded: false,
        loading: false,
        type: 'button',
    };
    return ButtonBase;
}(React.Component));
var getButton = function (Component, props) {
    var loading = props.loading, disabled = props.disabled, type = props.type, target = props.target, expanded = props.expanded, variant = props.variant, navigationState = props.navigationState, to = props.to, others = __rest(props, ["loading", "disabled", "type", "target", "expanded", "variant", "navigationState", "to"]);
    return (React.createElement(Component, __assign({ disabled: loading || disabled, type: !props.to && !props.href ? type : undefined, expanded: expanded ? 1 : 0, target: target, 
        // https://developers.google.com/web/tools/lighthouse/audits/noopener
        rel: target === '_blank' ? 'noopener' : undefined, loading: loading, variant: variant, state: navigationState, to: !disabled ? to : undefined }, others),
        React.createElement(ButtonContentStyled, { processing: !!loading }, props.children),
        React.createElement(ButtonSpinnerStyled, { processing: !!loading },
            React.createElement(ActivityIndicator, { type: 'spinner' }))));
};
export var Button = {
    Primary: function (props) { return React.createElement(ButtonBase, __assign({ variant: 'primary' }, props)); },
    Secondary: function (props) { return React.createElement(ButtonBase, __assign({ variant: 'secondary' }, props)); },
    CallToAction: function (props) { return React.createElement(ButtonBase, __assign({ variant: 'callToAction' }, props)); },
    Link: function (props) { return React.createElement(ButtonBase, __assign({ variant: 'link' }, props)); },
    Destructive: function (props) { return React.createElement(ButtonBase, __assign({ variant: 'destructive' }, props)); },
    DestructiveLink: function (props) { return React.createElement(ButtonBase, __assign({ variant: 'destructiveLink' }, props)); },
};
