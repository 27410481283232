import { EventType } from '@app/data/analytics/analytics.model';
export var SelectContentAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'select_content',
        params: {
            content_type: params.contentType,
            item_id: params.itemId,
            item_name: params.itemName,
        },
    };
};
