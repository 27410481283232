var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as Scroll from 'react-scroll';
import { InputLabel } from '@atomic/atm.typography';
import { FormFieldCaption } from './form-field-caption.component';
import { FormContext, FormFieldContext } from './form.context';
/**
 * This component must wrap each field. It validates a field, get its value on
 * submission, show an error message (if needed).
 */
var FormField = /** @class */ (function (_super) {
    __extends(FormField, _super);
    function FormField(props) {
        var _this = _super.call(this, props) || this;
        _this.handleValueChange = function (value, other) {
            var val = value;
            var oth = other;
            // Its a checkbox, must verify if checked/unchecked from the "other" parameter
            if (_this.isCheckboxValue(val)) {
                val = (_this.state.value || []).slice();
                oth = Object.assign({}, _this.state.other);
                var checkboxValue = value[0];
                var index = val.indexOf(checkboxValue, 0);
                if (other && index < 0) {
                    val.push(checkboxValue);
                }
                if (!other && index > -1) {
                    val.splice(index, 1);
                }
                oth[checkboxValue] = other;
            }
            if (_this.isControlled()) {
                _this.fireValueChange(val, oth);
                return;
            }
            // (other?.touched) -> validate on onValueChange for <Select />
            if (_this.state.touched || (other === null || other === void 0 ? void 0 : other.touched)) {
                _this.validate(val);
            }
            _this.setState({ value: val, other: oth, dirty: true });
            _this.fireValueChange(val, oth);
        };
        _this.handleFocusChange = function (focus) {
            _this.setState({ focus: focus });
            if (!focus) {
                _this.validate(_this.state.value);
                _this.setState({ touched: true });
            }
        };
        _this.handleClear = function () {
            var _a, _b;
            _this.setState({ value: null, other: null, dirty: true });
            (_b = (_a = _this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, null, null);
        };
        _this.isControlled = function () { return _this.props.value !== undefined; };
        _this.isCheckboxValue = function (value) { return Array.isArray(value); };
        _this.state = {
            other: undefined,
            name: props.name,
            value: props.initialValue !== undefined ? props.initialValue : props.value,
            errors: [],
            touched: false,
            dirty: false,
            focus: false,
            onValueChange: _this.handleValueChange,
            onFocusChange: _this.handleFocusChange,
            onClear: _this.handleClear,
        };
        return _this;
    }
    FormField.prototype.componentDidMount = function () {
        var _a;
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        return (_a = this.formContext) === null || _a === void 0 ? void 0 : _a.register(this);
    };
    FormField.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        var _a, _b;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            if (this.state.touched) {
                this.validate(this.props.value);
            }
            var other_1 = {};
            if (this.isCheckboxValue(this.props.value)) {
                other_1 = this.state.other || {};
                Object.getOwnPropertyNames(other_1).forEach(function (id) {
                    other_1[id] = _this.props.value.findIndex(function (checkedId) { return String(checkedId) === id; }) !== -1;
                });
                this.props.value.forEach(function (id) {
                    var _a;
                    if (!Object(other_1).hasOwnProperty(id.toString())) {
                        return (other_1 = __assign(__assign({}, other_1), (_a = {}, _a[id] = true, _a)));
                    }
                });
            }
            this.setState({ value: this.props.value, other: other_1, dirty: true });
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value, other_1);
        }
    };
    FormField.prototype.componentWillUnmount = function () {
        var _a;
        return (_a = this.formContext) === null || _a === void 0 ? void 0 : _a.unregister(this);
    };
    FormField.prototype.render = function () {
        var _this = this;
        return (React.createElement(FormContext.Consumer, null, function (formContext) {
            _this.formContext = formContext;
            return (React.createElement(FormFieldContext.Provider, { value: _this.state },
                React.createElement(Scroll.Element, { name: _this.props.name }),
                _this.props.label && React.createElement(InputLabel, { hasError: _this.state.errors.length > 0 }, _this.props.label),
                _this.props.children,
                React.createElement(FormFieldCaption, { errors: _this.state.errors })));
        }));
    };
    FormField.prototype.validate = function (value) {
        var _a;
        var errors = [];
        // Pass it to each validator
        for (var _i = 0, _b = (_a = this.props.validators) !== null && _a !== void 0 ? _a : []; _i < _b.length; _i++) {
            var validator = _b[_i];
            // Add to validation array if errors
            if (!validator.validationFn(value)) {
                errors.push(validator.error);
            }
        }
        return this.setState({ errors: errors });
    };
    FormField.prototype.fireValueChange = function (val, oth) {
        var _a, _b;
        (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, val, oth);
    };
    return FormField;
}(React.Component));
export { FormField };
