var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Hbox } from '@atomic/obj.box';
import { FormFieldContext } from '@atomic/obj.form';
import { StepperFieldInputStyled, StepperFieldMinusStyled, StepperFieldPlusStyled, StepperFieldStyled, } from './stepper-field.component.style';
var DEFAULT_STEP = 1;
var BIG_STEP = 10;
var StepperField = /** @class */ (function (_super) {
    __extends(StepperField, _super);
    function StepperField(props) {
        var _this = this;
        var _a, _b, _c;
        _this = _super.call(this, props) || this;
        _this.handleMinusClick = function (event, step) {
            event.stopPropagation();
            event.preventDefault();
            var currentValue = _this.getCurrentValue();
            step = step !== null && step !== void 0 ? step : DEFAULT_STEP;
            var nextValue = Math.max(currentValue - step, _this.minValue);
            _this.changeValue(nextValue);
        };
        _this.handlePlusClick = function (event, step) {
            event.stopPropagation();
            event.preventDefault();
            var currentValue = _this.getCurrentValue();
            step = step !== null && step !== void 0 ? step : DEFAULT_STEP;
            var nextValue = Math.min(currentValue + step, _this.maxValue);
            _this.changeValue(nextValue);
        };
        _this.changeValue = function (nextValue) {
            var _a, _b, _c;
            (_b = (_a = _this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, nextValue);
            (_c = _this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange(nextValue, null);
            if (_this.inputRef.current) {
                _this.inputRef.current.value = nextValue.toString();
            }
            if (!_this.isControlled()) {
                _this.setState({ value: nextValue });
            }
        };
        _this.handleKeyDown = function (event) {
            var key = event.key;
            var supportedKeys = ['ArrowUp', 'ArrowDown', 'PageUp', 'PageDown', 'Home', 'End'];
            if (!supportedKeys.includes(key)) {
                return;
            }
            if (key === 'End') {
                return _this.hasMaxValue() && _this.changeValue(_this.maxValue);
            }
            else if (key === 'Home') {
                return _this.hasMinValue() && _this.changeValue(_this.minValue);
            }
            var bigStepKeys = ['PageUp', 'PageDown'];
            var step = bigStepKeys.includes(key) ? BIG_STEP : DEFAULT_STEP;
            var plusKeys = ['ArrowUp', 'PageUp'];
            var handler = plusKeys.includes(key) ? _this.handlePlusClick : _this.handleMinusClick;
            return handler(event, step);
        };
        _this.handleInputChange = function (event) {
            var _a, _b;
            var value = parseInt(event.target.value);
            var currentValue = _this.getCurrentValue();
            if (value > currentValue) {
                var newValue = Math.min(value, (_a = _this.props.maxValue) !== null && _a !== void 0 ? _a : +Infinity);
                _this.changeValue(newValue);
            }
            else if (value <= currentValue) {
                var newValue = Math.max(value, (_b = _this.props.minValue) !== null && _b !== void 0 ? _b : -Infinity);
                _this.changeValue(newValue);
            }
        };
        _this.hasMaxValue = function () {
            return _this.props.maxValue || _this.props.maxValue === 0;
        };
        _this.hasMinValue = function () {
            return _this.props.minValue || _this.props.minValue === 0;
        };
        _this.isControlled = function () {
            return _this.props.value !== undefined;
        };
        _this.state = {
            value: (_a = props.initialValue) !== null && _a !== void 0 ? _a : props.value,
        };
        _this.inputRef = React.createRef();
        _this.minValue = (_b = props.minValue) !== null && _b !== void 0 ? _b : -Infinity;
        _this.maxValue = (_c = props.maxValue) !== null && _c !== void 0 ? _c : Infinity;
        return _this;
    }
    StepperField.prototype.componentDidMount = function () {
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialValue !== undefined) {
                if (this.formFieldConsumer.value) {
                    throw new Error('Please, use either value props in <Stepper> or <Form.Field> component.');
                }
                this.formFieldConsumer.onValueChange(this.state.value, null);
            }
        }
    };
    StepperField.prototype.componentDidUpdate = function (prevProps) {
        var _a;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            (_a = this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.onValueChange(this.props.value, null);
            if (this.inputRef.current && this.props.value) {
                this.inputRef.current.value = this.props.value.toString();
            }
            this.setState({ value: this.props.value });
        }
    };
    StepperField.prototype.render = function () {
        var _this = this;
        this.formFieldConsumer = this.context;
        var val = this.getCurrentValue();
        return (React.createElement(StepperFieldStyled, { role: 'spinbutton', "aria-valuenow": val, "aria-valuemax": this.props.maxValue, "aria-valuemin": this.props.minValue, onKeyDown: this.handleKeyDown },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(StepperFieldMinusStyled, { onClick: this.handleMinusClick, disabled: val <= this.minValue })),
                React.createElement(Hbox.Item, { hAlign: 'center', vAlign: 'center' },
                    React.createElement(StepperFieldInputStyled, { type: 'number', ref: this.inputRef, defaultValue: val, onChange: this.handleInputChange, onBlur: function () { return _this.inputRef.current && (_this.inputRef.current.value = val.toString()); } })),
                React.createElement(Hbox.Item, { noGrow: true },
                    React.createElement(StepperFieldPlusStyled, { onClick: this.handlePlusClick, disabled: val >= this.maxValue })))));
    };
    StepperField.prototype.getCurrentValue = function () {
        var _a, _b;
        return (_b = (_a = this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.state.value;
    };
    return StepperField;
}(React.Component));
export { StepperField };
StepperField.contextType = FormFieldContext;
