var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { SelectIconStyled, SelectStyled, SelectWrapperStyled } from './select.component.style';
var SelectField = /** @class */ (function (_super) {
    __extends(SelectField, _super);
    function SelectField(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this.handleSelectChange = function (event) {
            var _a, _b;
            var value = event.currentTarget.value;
            (_b = (_a = _this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, value);
            if (_this.isControlled()) {
                return;
            }
            if (_this.formFieldConsumer) {
                _this.formFieldConsumer.onValueChange(value, { touched: true });
            }
            else {
                _this.setState({ value: value });
            }
        };
        _this.isControlled = function () {
            return _this.props.value !== undefined;
        };
        _this.state = { value: (_a = _this.props.initialValue) !== null && _a !== void 0 ? _a : _this.props.value };
        return _this;
    }
    SelectField.prototype.componentDidMount = function () {
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialValue !== undefined) {
                if (this.formFieldConsumer.value) {
                    throw new Error('Please, use either value props in <Select> or <Form.Field> component.');
                }
                this.formFieldConsumer.onValueChange(this.state.value, null);
            }
        }
    };
    SelectField.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b, _c;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            this.setState({ value: this.props.value });
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value);
            (_c = this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange(this.props.value, null);
        }
    };
    SelectField.prototype.render = function () {
        var _this = this;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _a = this.props, type = _a.type, onChange = _a.onChange, invalid = _a.invalid, onValueChange = _a.onValueChange, value = _a.value, other = __rest(_a, ["type", "onChange", "invalid", "onValueChange", "value"]);
        return (React.createElement(FormFieldContext.Consumer, null, function (formFieldConsumer) {
            var _a;
            _this.formFieldConsumer = formFieldConsumer;
            var val = (_a = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) !== null && _a !== void 0 ? _a : _this.state.value;
            return (React.createElement(SelectWrapperStyled, { invalid: _this.props.invalid },
                React.createElement(SelectStyled, __assign({ onChange: _this.handleSelectChange, value: val }, other), _this.props.children),
                React.createElement(SelectIconStyled, null)));
        }));
    };
    return SelectField;
}(React.Component));
export { SelectField };
