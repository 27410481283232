import * as React from 'react';
import { AppStrings } from '@app/app-strings';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Accordion } from '@atomic/mol.accordion';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { MassShareSuccessAccordionStyled, MassShareSuccessAccordionTitleStyled, } from './mass-share-success-accordion.component.style';
var strings = AppStrings.productsList.massSharePage;
export var MassShareSuccessAccordion = function (props) {
    var _a = React.useState(false), expanded = _a[0], setExpanded = _a[1];
    return (React.createElement(MassShareSuccessAccordionStyled, null,
        React.createElement(Separator, { size: Spacing.Medium }),
        React.createElement(MassShareSuccessAccordionTitleStyled, { onClick: function () { return setExpanded(!expanded); } },
            React.createElement(Body, { bold: true, variant: 'success' }, strings.sentMerchantsTitle(props.sentMerchantNames.length, props.totalMerchants)),
            expanded ? (React.createElement(FaIcon.ChevronUp, { size: 'lg', color: Color.SuccessDark })) : (React.createElement(FaIcon.ChevronDown, { size: 'lg', color: Color.SuccessDark }))),
        React.createElement(Separator, { size: Spacing.Small }),
        React.createElement(Accordion, { noTitle: true, expanded: expanded }, props.sentMerchantNames.map(function (name, index) { return (React.createElement(Hbox, { key: "".concat(name, "-").concat(index) },
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(FaIcon.Check, { color: Color.SuccessDark })),
            React.createElement(Hbox.Separator, null),
            React.createElement(Hbox.Item, null,
                React.createElement(Body, { variant: 'success' }, name)))); })),
        React.createElement(Separator, { size: Spacing.Small })));
};
