import { EventType } from '@app/data/analytics/analytics.model';
export var AppErrorAnalytics = function (params) {
    return {
        type: EventType.EVENT,
        eventName: 'app_error',
        params: {
            message: params.message,
            type: params.type,
            request: params.request,
        },
    };
};
