var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Card } from '@atomic/atm.card';
import { MediaQuery, Spacing } from '@atomic/obj.constants';
var LARGE_WIDTH = 400;
var SMALL_WIDTH = "calc(100% - 2 * ".concat(Spacing.Medium, ")");
export var LoginFormCardStyled = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n\n  ", " {\n    width: ", "px;\n  }\n"], ["\n  width: ", ";\n\n  ", " {\n    width: ", "px;\n  }\n"])), SMALL_WIDTH, MediaQuery.sm, LARGE_WIDTH);
var templateObject_1;
