import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthenticationRoutes } from '@app/modules/authentication/authentication.routes';
import LoginPage from '@app/modules/authentication/login.page';
import HomePage from '@app/modules/home/home.page';
import { HomeRoutes } from '@app/modules/home/home.routes';
import { LoginGuard } from '@app/modules/login-guard.component';
import DistributorsPage from '@app/modules/products-list/distributor.page';
import MassSharePage from '@app/modules/products-list/mass-share.page';
import MerchantsPage from '@app/modules/products-list/merchants.page';
import { PricePreviewPage } from '@app/modules/products-list/price-preview.page';
import ProductsListDetailsPage from '@app/modules/products-list/products-list-details.page';
import ProductsListEditPage from '@app/modules/products-list/products-list-edit.page';
import { ProductsListRoutes } from '@app/modules/products-list/products-list.routes';
import SharePage from '@app/modules/products-list/share.page';
export var RootRouter = function () { return (React.createElement(Suspense, { fallback: React.createElement("div", null, "Loading...") },
    React.createElement(Routes, null,
        React.createElement(Route, { path: AuthenticationRoutes.Base, element: React.createElement(LoginPage, null) }),
        React.createElement(Route, { path: HomeRoutes.Base, element: React.createElement(LoginGuard, null,
                React.createElement(HomePage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.DetailsWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(ProductsListDetailsPage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.EditWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(ProductsListEditPage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.MerchantsWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(MerchantsPage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.DistributorsWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(DistributorsPage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.ShareWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(SharePage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.MassShareWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(MassSharePage, null)) }),
        React.createElement(Route, { path: ProductsListRoutes.PricePreviewWithParam, element: React.createElement(LoginGuard, null,
                React.createElement(PricePreviewPage, null)) }),
        React.createElement(Route, { path: '*', element: React.createElement(Navigate, { to: HomeRoutes.Base }) })))); };
