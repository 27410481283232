import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppStrings } from '@app/app-strings';
import { PageBgColorWrapper } from '@app/components/atm.page-wrapper';
import { StickyCard } from '@app/components/atm.sticky-card';
import { SearchBar } from '@app/components/mol.search-bar';
import { AnalyticsService, SelectContentAnalytics } from '@app/data/analytics';
import { useDraft } from '@app/domain/draft';
import { useDetailProductList } from '@app/domain/product-list';
import { getListId } from '@app/utils';
import { H1 } from '@atomic/atm.typography';
import { SwitchCell } from '@atomic/mol.switch-cell';
import { Column, Grid, Row, VerticalSpacer } from '@atomic/obj.grid';
import { MerchantFilterSelect, MerchantSelectableList, ResponsiveButton } from './components';
import { NEW_LIST_ID, ProductsListRoutes } from './products-list.routes';
var strings = AppStrings.productsList.merchantsPage;
var MerchantsPage = function () {
    var _a = useDraft(), draftMerchants = _a.merchants, draftId = _a.id, draftTitle = _a.title, updateMerchants = _a.updateMerchants, setDraft = _a.setDraft;
    var _b = React.useState(draftMerchants), selected = _b[0], setSelected = _b[1];
    var _c = React.useState(''), search = _c[0], setSearch = _c[1];
    var _d = React.useState(undefined), filter = _d[0], setFilter = _d[1];
    var _e = React.useState(false), onlyMyBase = _e[0], setOnlyMyBase = _e[1];
    var _f = React.useState(1), page = _f[0], setPage = _f[1];
    var navigate = useNavigate();
    var listId = useParams().listId;
    var state = useLocation().state;
    React.useEffect(function () {
        var isPageNewList = listId === NEW_LIST_ID;
        var isDraftNewList = draftId === NEW_LIST_ID;
        if (isPageNewList && !isDraftNewList) {
            setDraft({ id: NEW_LIST_ID });
        }
    }, [draftId, setDraft, listId]);
    var handleDetailProductListSuccess = function (productList) {
        var id = productList.id, title = productList.title, merchants = productList.merchants, items = productList.items;
        setDraft({ id: id, title: title, merchants: merchants !== null && merchants !== void 0 ? merchants : [], items: items });
    };
    useDetailProductList({
        id: getListId(listId),
        skip: getListId(listId) === draftId,
        onSuccess: handleDetailProductListSuccess,
    });
    var handleSearch = function (value) {
        setPage(1);
        setSearch(value);
    };
    var handleSwitch = function (checked) {
        setOnlyMyBase(checked);
        setFilter(undefined);
        setPage(1);
    };
    var handleSubmit = function () {
        var _a;
        AnalyticsService.send(SelectContentAnalytics({ contentType: 'add_clients', itemId: draftId === null || draftId === void 0 ? void 0 : draftId.toString(), itemName: draftTitle }));
        navigate((_a = state === null || state === void 0 ? void 0 : state.nextRoute) !== null && _a !== void 0 ? _a : ProductsListRoutes.Edit(listId), { replace: true });
        updateMerchants(selected);
    };
    var submitButtonText = strings.submitButton(selected.length);
    return (React.createElement(PageBgColorWrapper, null,
        React.createElement(Grid, null,
            React.createElement(VerticalSpacer, null),
            React.createElement(Row, { responsive: true, noGutter: true },
                React.createElement(Column, { flex: 5 / 12 },
                    React.createElement(StickyCard, null,
                        React.createElement(H1, null, strings.title),
                        React.createElement(VerticalSpacer, null),
                        React.createElement(ResponsiveButton, { text: submitButtonText, disabled: !selected.length, onClick: handleSubmit }))),
                React.createElement(Column, { flex: 1 / 12 }),
                React.createElement(Column, { flex: 6 / 12 },
                    React.createElement(SwitchCell, { title: strings.myBaseSwitchTitle, checked: onlyMyBase, onChange: handleSwitch }),
                    React.createElement(MerchantFilterSelect, { onFilterChange: setFilter, active: onlyMyBase }),
                    React.createElement(VerticalSpacer, null),
                    React.createElement(SearchBar, { placeholder: strings.searchPlaceholder, onSearch: handleSearch }),
                    React.createElement(VerticalSpacer, null),
                    React.createElement(MerchantSelectableList, { searchTerm: search, page: page, onlyMyBase: onlyMyBase, filter: filter, selected: selected, onSelectedChange: setSelected, onNextPage: function () { return setPage(function (cur) { return cur + 1; }); } }))),
            React.createElement(VerticalSpacer, null)),
        React.createElement(ResponsiveButton, { text: submitButtonText, disabled: !selected.length, onClick: handleSubmit, isSticky: true })));
};
export default MerchantsPage;
