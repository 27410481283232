var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { InputCaption } from '@atomic/atm.typography';
import { Color, Spacing } from '@atomic/obj.constants';
export var MassShareCardGutterStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 ", ";\n"], ["\n  padding: 0 ", ";\n"])), Spacing.XLarge);
export var MassShareMessageWrapperStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n"])), Color.GrayLight, Spacing.XSmall);
export var MassShareCardMessageText = styled(InputCaption)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: pre-line;\n"], ["\n  white-space: pre-line;\n"])));
export var MassShareMerchantsWrapperStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: ", ";\n  display: flex;\n  flex-wrap: wrap;\n  gap: ", ";\n"], ["\n  background-color: ", ";\n  padding: ", ";\n  display: flex;\n  flex-wrap: wrap;\n  gap: ", ";\n"])), Color.GrayLight, Spacing.Medium, Spacing.Small);
export var MassShareCardHiddenMerchantsButtonStyled = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 0;\n  margin: 0;\n  border-width: 0;\n  background-color: transparent;\n  cursor: pointer;\n"], ["\n  padding: 0;\n  margin: 0;\n  border-width: 0;\n  background-color: transparent;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
